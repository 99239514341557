import { get } from "lodash";
import { rem } from "polished";
import styled, { css } from "styled-components";

const applyTheme = (props) => {
  if (props.hasError) {
    return get(props, "theme.color.error");
  }

  return get(props, "theme.input.borderColor");
};

const Input = styled.input`
  background-color: transparent;
  border-bottom: 2px solid ${applyTheme};
  border-left: 0;
  border-radius: 0;
  border-right: 0;
  border-top: 0;
  color: ${(props) => get(props, "theme.input.color")};
  font-size: ${rem(20)};
  line-height: 1.5;
  padding-bottom: ${rem(10)};
  padding-left: 0;
  padding-right: 0;
  padding-top: ${rem(10)};
  width: 100%;

  &:focus {
    outline: none;
  }

  ${(props) => css`
    &::placeholder {
      color: ${get(props, "theme.input.borderColor")};
    }
  `};
`;

export const IconWrapper = styled.div`
  background-color: transparent;
  border-bottom: 2px solid ${applyTheme};
  align-items: center;
  display: flex;
`;

export default Input;
