import React from "react";

export type NotificationType = "toast" | undefined;

export type ToastProps = {
  animationDuration?: number;
  duration?: number;
  icon?: string;
  onDismiss?: () => void;
  variant: "light" | "dark";
};

type BaseNotification = {
  animationDuration?: number;
  duration?: number;
  message: string;
};

type ToastNotification = BaseNotification & {
  type: "toast";
  props: ToastProps;
};

export type Notification = ToastNotification;

type NotificationConfig = {
  animationDuration: number;
  duration: number;
};

type NotificationProviderProps = {
  notificationConfig?: NotificationConfig;
  children: React.ReactChild | React.ReactNode;
};

type NotificationContextValue = {
  dequeue: () => void;
  notification: Notification | undefined;
  enqueue: (nextNotification: Notification) => void;
};

const NotificationContext = React.createContext<
  NotificationContextValue | undefined
>(undefined);

const defaultNotificationConfig: NotificationConfig = {
  animationDuration: 500,
  duration: 5000,
};

const NotificationProvider: React.FunctionComponent<
  NotificationProviderProps
> = ({ notificationConfig = defaultNotificationConfig, children }) => {
  const [notificationQueue, setNotificationQueue] = React.useState<
    Notification[]
  >([]);
  const [notification, setNotification] = React.useState<
    Notification | undefined
  >();

  const enqueue = React.useCallback(
    (nextNotification: Notification) => {
      const mergedNextNotification = {
        ...notificationConfig,
        ...nextNotification,
      };
      setNotificationQueue((previousNotificationQueue) => {
        setNotification(
          (previousNotification) =>
            previousNotification ?? mergedNextNotification
        );
        return [...previousNotificationQueue, mergedNextNotification];
      });
    },
    [notificationConfig]
  );

  const dequeue = React.useCallback(() => {
    if (notificationQueue.length === 0) {
      setNotification(undefined);
    } else {
      const updatedQueue = notificationQueue.slice(1);
      setNotificationQueue(updatedQueue);
      setNotification(updatedQueue.length > 0 ? updatedQueue[0] : undefined);
    }
  }, [notificationQueue]);

  React.useEffect(() => {
    if (!notification) {
      return;
    }

    const duration = notification.duration ?? notificationConfig.duration;
    const animationDuration =
      notification.animationDuration ?? notificationConfig.animationDuration;
    const timeout = setTimeout(() => {
      dequeue();
    }, duration + animationDuration * 2);

    return () => clearTimeout(timeout);
  }, [
    dequeue,
    notification,
    notificationConfig.animationDuration,
    notificationConfig.duration,
  ]);

  return (
    <NotificationContext.Provider value={{ dequeue, notification, enqueue }}>
      {children}
    </NotificationContext.Provider>
  );
};

const useNotificationContext = (): NotificationContextValue => {
  const context = React.useContext(NotificationContext);

  if (!context) {
    throw new Error(
      "NotificationContext must be used within NotificationContext"
    );
  }

  return context;
};

const useNotification = (type?: NotificationType) => {
  const context = useNotificationContext();

  if (context.notification?.type === type) {
    return context.notification;
  }

  return;
};

const useNotificationQueue = () => {
  const context = useNotificationContext();

  return {
    enqueue: context.enqueue,
    dequeue: context.dequeue,
  };
};

export {
  NotificationProvider,
  useNotificationContext,
  useNotification,
  useNotificationQueue,
};

export default NotificationContext;
