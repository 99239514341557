import { StyleSheet } from "react-native";

import { NotificationStyles } from "./types";

const notificationStyles = StyleSheet.create<NotificationStyles>({
  container: {
    alignItems: "center",
  },
  content: {
    alignItems: "center",
    backgroundColor: "#32c58a",
    borderRadius: 23,
    flexDirection: "row",
    height: 46,
    justifyContent: "flex-start",
    paddingHorizontal: 16,
  },
  icon: {
    marginRight: 16,
  },
  text: {
    color: "#fff",
    fontFamily: "PublicSans-Regular",
    fontSize: 20,
    fontWeight: "500",
    lineHeight: 30,
    marginTop: 2,
  },
});

export { notificationStyles };

export default notificationStyles;
