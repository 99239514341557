/* eslint-disable react/jsx-handler-names */

import PropTypes from "prop-types";
import React from "react";

import { Nav } from "./components";
import Logo from "./Logo";

/**
 * @render react
 * @name Navigation
 * @description Achievement Navigation Component
 * @example
 * <Navigation
 *   items={[
 *    {
 *      href: `#`,
 *      label: `Home`,
 *    },
 *    {
 *      href: `#`,
 *      label: `Research`,
 *    },
 *    {
 *      href: `#`,
 *      label: `About`,
 *      className: `spacer`,
 *    },
 *    {
 *      href: `#`,
 *      label: `Log In`,
 *    },
 *    {
 *      href: `#`,
 *      label: `Sign Up`,
 *      className: `callToAction`,
 *    },
 *  ]}
 * />
 */

class Navigation extends React.Component {
  state = { isActive: false };

  render() {
    const { isActive } = this.state;
    const { logo, renderItem: RenderNavItem, items } = this.props;
    const activeClassName = isActive ? `active` : ``;

    return (
      <Nav.Container>
        <Nav>
          {logo && <Nav.Logo className="logo">{logo}</Nav.Logo>}
          <Nav.NavList className={activeClassName}>
            {logo && <Nav.Item className="logo">{logo}</Nav.Item>}
            {items.map(({ className = "", ...item }) => (
              <Nav.Item className={className} key={item.label}>
                <RenderNavItem {...item} />
              </Nav.Item>
            ))}
          </Nav.NavList>

          <Nav.Toggle
            className={`expand ${activeClassName}`}
            onClick={this.toggleActive}
            type="button"
          >
            <div className="bar top" />
            <div className="bar bottom" />
          </Nav.Toggle>
        </Nav>
      </Nav.Container>
    );
  }

  toggleActive = (e) => {
    e.preventDefault();
    this.setState((state) => ({ isActive: !state.isActive }));
  };
}

Navigation.propTypes = {
  items: PropTypes.array.isRequired,
  logo: PropTypes.element.isRequired,
};

Navigation.defaultProps = {
  items: [],
  logo: <Logo hidewordmark />,
  renderItem: ({ label, ...rest }) => <a {...rest}>{label}</a>,
};

export default Navigation;
