import Card from "../Card";
import CardContent from "../Card/CardContent";
import Grid from "../Grid";

//To be used across all Card-based components

const CardErrorState = () => (
  <Card height="100%">
    <CardContent>
      <Grid.Flex mb={1} mx="auto">
        <Grid.Box flex={1}>
          <svg height="90" width="90" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
              <circle cx="45" cy="45" fill="#FE7345" r="45" />
              <path
                d="M40.398 52.937l-.716-36.062h10.023l-.716 36.062h-8.591zM45 70.313a5.625 5.625 0 1 0 0-11.25 5.625 5.625 0 0 0 0 11.25z"
                fill="#FFF"
                fillRule="nonzero"
              />
            </g>
          </svg>
        </Grid.Box>
      </Grid.Flex>
      <Grid.Flex>
        <Grid.Box flex={1}>
          <CardContent.Title>
            Oh no...
            <br />
            An error has occurred and we are working to fix the problem!
          </CardContent.Title>
        </Grid.Box>
      </Grid.Flex>
      <Grid.Flex flex={1} mr={5}>
        <CardContent.Body>
          If you need help from our customer service team, please contact us.
          You can also visit our{" "}
          <a href="https://help.myachievement.com">Help Center</a> for
          additional information. Thanks for your patience!
        </CardContent.Body>
      </Grid.Flex>
    </CardContent>
  </Card>
);

export default CardErrorState;
