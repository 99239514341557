import times from "lodash/times";
import PropTypes from "prop-types";
import React from "react";

import Indicator from "./Indicator";

const IndicatorComponent = ({ count, onClick, index }) => {
  return (
    <Indicator>
      {times(count, (key) => (
        <Indicator.Item key={key}>
          {onClick ? (
            <Indicator.Icon
              as="button"
              isActive={key === index}
              onClick={() => onClick(key)}
            >
              {key}
            </Indicator.Icon>
          ) : (
            <Indicator.Icon isActive={key === index}>{key}</Indicator.Icon>
          )}
        </Indicator.Item>
      ))}
    </Indicator>
  );
};

IndicatorComponent.propTypes = {
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  index: PropTypes.number.isRequired,
};

export { Indicator };

export default IndicatorComponent;
