import Label from "./Label";

/**
 * @render react
 * @name Label
 * @description Achievement Label Component
 * @example
 * <Label>Lorem ipsum dolar sit amet</Label>
 */

export { Label };

export default Label;
