import PropTypes from "prop-types";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const RedirectComponent = ({ to, location }) => {
  const navigate = useNavigate();
  const [search] = useSearchParams();

  let redirect = to;
  if (search) {
    redirect += search;
  }

  React.useEffect(() => {
    if (location) {
      window.location.replace(location);
    } else {
      navigate(redirect);
    }
  }, [navigate, redirect, location]);

  return null;
};

RedirectComponent.propTypes = {
  to: PropTypes.string.isRequired,
};

export default RedirectComponent;
