import { useQuery } from "react-query";

import dataService from "../../services/dataService";

const useConfig = () => {
  return useQuery("config", dataService.getConfig, {
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
};

export default useConfig;
