import { distanceInWordsStrict } from "date-fns";
import PropTypes from "prop-types";

import Button, { ButtonColor, ButtonSize } from "../Button";
import ErrorBoundary from "../ErrorBoundary";
import Grid from "../Grid";
import Panel, { PanelColor } from "../Panel";
import SkeletonContent from "../SkeletonContent";
import Space from "../Space";
import ApplicationPanel from "./ApplicationPanel";
import ApplicationPanelErrorState from "./ApplicationPanelErrorState";

const ApplicationPanelComponent = ({
  application,
  color,
  handleConnect,
  handleDisconnect,
  loading,
}) => {
  if (loading) {
    return (
      <ErrorBoundary component={<ApplicationPanelErrorState />}>
        <Panel p={4} style={{ height: "100%" }}>
          <Grid.Flex>
            <Grid.Box mr={3} width={48}>
              <SkeletonContent.Avatar size={48} />
            </Grid.Box>
            <Grid.Box flex={1}>
              <Grid.Flex>
                <Grid.Box flex={1}>
                  <Space mb={2}>
                    <SkeletonContent.Block height={24} />
                  </Space>
                  <SkeletonContent.Block height={18} />
                </Grid.Box>
              </Grid.Flex>
            </Grid.Box>
          </Grid.Flex>
        </Panel>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary component={<ApplicationPanelErrorState />}>
      <Panel color={color} p={4} style={{ height: "100%" }}>
        <Grid.Flex>
          <Grid.Box mr={3} width={48}>
            {application.icon && (
              <ApplicationPanel.Icon alt="" src={application.icon} />
            )}
          </Grid.Box>
          <Grid.Box flex={1}>
            <Grid.Flex>
              <Grid.Box flex={1}>
                <ApplicationPanel.Name>
                  {application.name}
                </ApplicationPanel.Name>
                <ApplicationPanel.Description>
                  {application.description}
                </ApplicationPanel.Description>
              </Grid.Box>
              {(handleConnect || handleDisconnect) && (
                <Grid.Box flex="0 0 auto" ml={3}>
                  {handleConnect && (
                    <Button
                      color={ButtonColor.LIGHT}
                      data-testid="applications--connectButton"
                      onClick={handleConnect}
                      size={ButtonSize.SMALL}
                      uppercase
                    >
                      Connect
                    </Button>
                  )}
                  {handleDisconnect && (
                    <Button
                      color={ButtonColor.DARK}
                      data-testid="applications--disconnectButton"
                      onClick={handleDisconnect}
                      size={ButtonSize.SMALL}
                      uppercase
                    >
                      Disconnect
                    </Button>
                  )}
                </Grid.Box>
              )}
            </Grid.Flex>
            {application.status === "Online" && (
              <ApplicationPanel.Status>
                {application.status}
                {application.updatedAt &&
                  ` • synced ${distanceInWordsStrict(
                    new Date(),
                    application.updatedAt,
                    {
                      addSuffix: true,
                    }
                  )}`}
              </ApplicationPanel.Status>
            )}
          </Grid.Box>
        </Grid.Flex>
      </Panel>
    </ErrorBoundary>
  );
};

ApplicationPanelComponent.propTypes = {
  application: PropTypes.shape({
    description: PropTypes.string,
    icon: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.oneOf(["Offline", "Online"]),
    updatedAt: PropTypes.instanceOf(Date),
  }),
  color: PropTypes.oneOf(Object.values(PanelColor)),
  handleConnect: PropTypes.func,
  handleDisconnect: PropTypes.func,
  loading: PropTypes.bool,
};

ApplicationPanelComponent.defaultProps = {
  color: PanelColor.LIGHT,
  loading: false,
};

export { ApplicationPanel };

export default ApplicationPanelComponent;
