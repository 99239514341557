import { rem } from "polished";
import styled, { css } from "styled-components";

const Skeleton = styled.div.attrs({
  "data-testid": "skeleton",
})`
  animation: pulse 1.8s ease-in-out infinite;
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  display: inline-block;

  &::before {
    content: "\00a0";
  }

  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

const SkeletonContent = {
  Avatar: styled(Skeleton).attrs({
    "data-testid": "skeleton-avatar",
  })`
    border-radius: 50%;
    height: ${(props) => (props.size ? rem(props.size) : "100%")};
    width: ${(props) => (props.size ? rem(props.size) : "100%")};
  `,
  Block: styled(Skeleton).attrs({
    "data-testid": "skeleton-block",
  })`
    border-radius: ${rem(4)};
    height: ${(props) => (props.height ? rem(props.height) : "100%")};
    ${(props) =>
      props.height &&
      css`
        min-height: ${rem(props.height)};
      `};
    width: ${(props) => (props.width ? rem(props.width) : "100%")};
  `,
  Line: styled(Skeleton).attrs({
    "data-testid": "skeleton-line",
  })`
    border-radius: ${rem(4)};
    width: 100%;
  `,
};

export default SkeletonContent;
