import { Link } from "react-router-dom";

import { AuthState, useAuthState } from "@achievement/portal-client";

import trackingService, { Events } from "../../services/trackingService";
import { Footer } from "../ReactComponents/Components";
import FooterLogo from "./FooterLogo";

const onClick = (event) => {
  if (event && event.currentTarget && event.currentTarget.text) {
    trackingService.trackEvent(Events.FOOTER_CLICK, {
      footer_text: event.currentTarget.text,
    });
  }
};

const getNavigation = (isAuthenticated) => ({
  About: [
    {
      children: "Home",
      href: window.env.WEBSITE_URL,
      onClick,
      target: "_blank",
    },
    {
      children: "About",
      href: `${window.env.WEBSITE_URL}/about-us`,
      onClick,
      target: "_blank",
    },
    {
      children: "Research",
      href: `${window.env.WEBSITE_URL}/blog-category/research`,
      target: "_blank",
    },
    {
      children: "Blog",
      href: `${window.env.WEBSITE_URL}/blog`,
      onClick,
      target: "_blank",
    },
  ],
  Support: [
    {
      children: "Help Center",
      href: "https://help.evidation.com",
      onClick,
      target: "_blank",
    },
    {
      children: "Submit a Request",
      href: "https://help.evidation.com/hc/en-us/requests/new",
      onClick,
      target: "_blank",
    },
    {
      children: "Terms of Use",
      href: `${window.env.WEBSITE_URL}${
        isAuthenticated ? "/members" : ""
      }/terms`,
      onClick,
      target: "_blank",
    },
    {
      children: "Privacy Notice",
      href: `${window.env.WEBSITE_URL}${
        isAuthenticated ? "/members" : ""
      }/privacy`,
      onClick,
      target: "_blank",
    },
    {
      children: "Consumer Health Data Privacy Notice",
      href: "https://evidation.com/consumer-health-data-privacy-notice",
      onClick,
      target: "_blank",
    },
  ],
  Social: [
    {
      children: "Facebook",
      href: "https://www.facebook.com/myEvidation",
      onClick,
      target: "_blank",
    },
    {
      children: "Instagram",
      href: "https://www.instagram.com/my_evidation",
      onClick,
      target: "_blank",
    },
    {
      children: "Twitter",
      href: "https://twitter.com/myEvidation",
      onClick,
      target: "_blank",
    },
    {
      children: "Pinterest",
      href: "https://www.pinterest.com/myEvidation",
      onClick,
      target: "_blank",
    },
  ],
});

const renderItem = (item) =>
  item.to ? (
    <Link data-testid={`footer--${item.to}`} {...item} />
  ) : (
    <a
      data-testid={`footer--${item.children
        .toLowerCase()
        .replaceAll(" ", "_")}`}
      {...item}
    >
      {item.children}
    </a>
  );

const FooterComponent = () => {
  const { authState } = useAuthState();
  const isAuthenticated = authState === AuthState.AUTHENTICATED;
  const navigation = getNavigation(isAuthenticated);

  return (
    <Footer
      copyright={`© ${new Date().getFullYear()} Evidation Health, all rights reserved.`}
      description={null}
      logo={<FooterLogo />}
      navigation={navigation}
      renderItem={renderItem}
    />
  );
};

export default FooterComponent;
