import React from "react";
import { Link } from "react-router-dom";

import { Menu } from "../ReactComponents/Components";
import EventTracker from "../Tracker/EventTracker";
import { ReactComponent as AccountIcon } from "./Account.svg";
import { ReactComponent as ActivityIcon } from "./Activity.svg";
import { ReactComponent as ApplicationIcon } from "./Application.svg";
import { ReactComponent as FAQIcon } from "./FAQ.svg";
import HeaderMenuButton from "./HeaderMenuButton";
import HeaderMenuList from "./HeaderMenuList";
import { ReactComponent as LogoutIcon } from "./Logout.svg";
import { ReactComponent as PrivacyIcon } from "./Privacy.svg";
import { ReactComponent as TermsIcon } from "./Terms.svg";

const AuthenticatedHeaderMenu = () => {
  const queryParams = encodeURI(window.location.search);

  return (
    <Menu>
      <HeaderMenuButton data-testid="hamburger-menu--button" />
      <HeaderMenuList>
        <EventTracker action="Hamburger menu" category="Header" />
        <Menu.Link
          as={Link}
          data-testid="hamburger-menu--activity"
          key="activity"
          to="/member/activity"
        >
          <ActivityIcon />
          Activity
        </Menu.Link>
        <Menu.Link
          as={Link}
          data-testid="hamburger-menu--apps"
          key="applications"
          to="/member/applications"
        >
          <ApplicationIcon />
          Apps
        </Menu.Link>
        <Menu.Link
          as={Link}
          data-testid="hamburger-menu--account"
          key="account"
          to="/member/account"
        >
          <AccountIcon />
          Account
        </Menu.Link>
        <hr key="hr" />
        <Menu.Link
          as={Link}
          data-testid="hamburger-menu--logout"
          key="logout"
          to="/logout"
        >
          <LogoutIcon />
          Logout
        </Menu.Link>
        <hr />
        <Menu.Link
          as="a"
          data-testid="hamburger-menu--faq"
          href="https://help.evidation.com"
          target="_blank"
        >
          <FAQIcon />
          Help Center
        </Menu.Link>
        <Menu.Link
          as="a"
          data-testid="hamburger-menu--tos"
          href={`${window.env.WEBSITE_URL}/members/terms${queryParams}`}
        >
          <TermsIcon />
          Terms of Use
        </Menu.Link>
        <Menu.Link
          as="a"
          data-testid="hamburger-menu--privacy"
          href={`${window.env.WEBSITE_URL}/members/privacy${queryParams}`}
        >
          <PrivacyIcon />
          Privacy Notice
        </Menu.Link>
        <Menu.Link
          as="a"
          data-testid="hamburger-menu--privacy"
          href="https://evidation.com/consumer-health-data-privacy-notice"
        >
          <PrivacyIcon />
          Consumer Health Data Privacy Notice
        </Menu.Link>
      </HeaderMenuList>
    </Menu>
  );
};

export default AuthenticatedHeaderMenu;
