import { rem } from "polished";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Redeem = styled(Link)`
  background-color: ${(props) => props.theme.palette.secondary.medium};
  border-radius: ${rem(8)};
  color: ${(props) => props.theme.palette.base.lightest};
  display: block;
  font-size: ${rem(8)};
  letter-spacing: 0.2px;
  line-height: ${11 / 8};
  padding: ${rem(4)} ${rem(6)} ${rem(2)};
`;

export default Redeem;
