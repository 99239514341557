import Axios from "axios";
import React from "react";

import {
  Grid,
  Notification,
  NotificationStatus,
} from "../ReactComponents/Components";

const MaintenanceBanner = () => {
  const [banner, setBanner] = React.useState();

  React.useEffect(() => {
    const handleFetch = async () => {
      const response = await Axios.get(
        window.env.REACT_APP_MAINTENANCE_BANNER_URL
      );

      if (response.status === 200) {
        setBanner(response.data);
      }
    };

    handleFetch();
  }, []);

  if (!banner || !banner.title || !banner.body) {
    return null;
  }

  return (
    <Notification status={NotificationStatus.NOTICE}>
      <Grid px={3}>
        <h5
          style={{
            fontSize: "115%",
            marginBottom: 4,
            marginTop: 4,
          }}
        >
          {banner.title}
        </h5>
        <p style={{ marginBottom: 0 }}>{banner.body}</p>
      </Grid>
    </Notification>
  );
};

export default MaintenanceBanner;
