import React from "react";

import useConfig from "../modules/config";

type FeatureToggleProviderProps = {
  children: React.ReactChild;
};

export enum FeatureToggleKey {
  SharedAuthCookie = "shared_auth_cookie_enabled",
  ProfileObfuscationEnabled = 'pii_data_obfuscation_is_enabled',
  OnboardingRaceEnabled = 'onboarding_race_enabled'
}

type FeatureToggles = { [key: string]: boolean };

type FeatureToggleContext = {
  featureToggles: FeatureToggles;
  setFeatureToggles: (featureToggles: FeatureToggles) => void;
};

const Context = React.createContext<FeatureToggleContext | undefined>(
  undefined
);

const FeatureToggleProvider: React.FunctionComponent<
  FeatureToggleProviderProps
> = ({ children }) => {
  const [featureToggles, setFeatureToggles] = React.useState<FeatureToggles>(
    {}
  );
  const { data: config } = useConfig();

  React.useEffect(() => {
    if (config && config.data) {
      const featureToggles = config.data.settings.toggles ?? {};
      setFeatureToggles(featureToggles);
    }
  }, [config]);

  return (
    <Context.Provider value={{ featureToggles, setFeatureToggles }}>
      {children}
    </Context.Provider>
  );
};

const useFeatureToggleContext = (): FeatureToggleContext => {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error(
      "FeatureToggleContext must be used within FeatureToggleProvider"
    );
  }

  return context;
};

const useFeatureToggle = (toggleName: string): boolean => {
  const { featureToggles } = useFeatureToggleContext();
  return featureToggles[toggleName] ?? false;
};

export { FeatureToggleProvider, useFeatureToggleContext, useFeatureToggle };

export default Context;
