export const Colors = {
  BLACK: "#000000",
  BLUE_DARK: "#0F5FBC",
  GRAY_DARK: "#2A3F53",
  GRAY_ALPHA: "rgba(91, 107, 125, 0.34)",
  WHITE: "#FFFFFF",
};

export const ZIndex = {
  TOOLTIP_HIDDEN: -100,
  TOOLTIP_CONTAINER: 999,
  TOOLTIP_ARROW_BACKGROUND: 1000,
  TOOLTIP_ARROW: 1001,
};
