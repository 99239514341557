import styled from "styled-components";

const RadialProgressBar = styled.div`
  position: relative;
`;

RadialProgressBar.Circle = styled.svg`
  transform: rotate(-90deg);
`;

RadialProgressBar.Progress = styled.circle``;

export default RadialProgressBar;
