import { StyleSheet } from "react-native";
import { MixedStyleDeclaration } from "react-native-render-html";

import { QuickTipStyles } from "./types";

const quickTipStyles = StyleSheet.create<QuickTipStyles>({
  container: {
    flexDirection: "column",
  },
  title: {
    color: "#2a3f53",
    fontFamily: "PublicSans-Regular",
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 21,
  },
});

const quickTipBaseStyle: MixedStyleDeclaration = {
  color: "#2a3f53",
  fontFamily: "PublicSans-Regular",
  fontWeight: "400",
  fontSize: 16,
  lineHeight: 24,
};

export { quickTipBaseStyle };

export default quickTipStyles;
