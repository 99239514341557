import PropTypes from "prop-types";
import React from "react";

import { RadialProgressBar, Typography } from "../ReactComponents/Components";
import { getStylesByCategory } from "./categoryStyles";
import PointHubStatistic from "./PointHubStatistic";

const PointHubStatisticComponent = ({
  category,
  onClick,
  percentage,
  title,
}) => {
  const { color, icon } = getStylesByCategory(category);

  return (
    <React.Fragment>
      <PointHubStatistic>
        <PointHubStatistic.Button
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        >
          <RadialProgressBar color={color} strokeWidth={2} value={percentage} />
          <PointHubStatistic.Content>{icon}</PointHubStatistic.Content>
        </PointHubStatistic.Button>
        <PointHubStatistic.Tooltip>{percentage}%</PointHubStatistic.Tooltip>
      </PointHubStatistic>
      <Typography mb={0} mt={1} textAlign="center" truncate>
        {title}
      </Typography>
    </React.Fragment>
  );
};

PointHubStatisticComponent.propTypes = {
  category: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  percentage: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default PointHubStatisticComponent;
