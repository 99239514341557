import { rem } from "polished";
import styled from "styled-components";

const FixedHeader = styled.div`
  height: ${(props) => (props.offsetHeight ? rem(108) : rem(60))};
`;

FixedHeader.Content = styled.div`
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
`;

FixedHeader.Notification = styled.div`
  position: absolute;
  bottom: -2rem;
  left: 50%;
  transform: translate(-50%, 50%);
`;

export default FixedHeader;
