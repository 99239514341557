import { ReactComponent as AccountIcon } from "../HeaderMenu/Account.svg";
import { ReactComponent as ActivityIcon } from "../HeaderMenu/Activity.svg";
import { ReactComponent as ApplicationIcon } from "../HeaderMenu/Application.svg";
import Match from "../Match";
import ReachLink from "../ReachLink";
import { NavigationBar } from "../ReactComponents/Components";

const UnauthenticatedNavigationBar = () => {
  return (
    <NavigationBar>
      <Match path="/member/activity/*">
        {({ match }) => (
          <NavigationBar.Link
            active={match ? "true" : undefined}
            as={ReachLink}
            data-testid="navigation-bar--activity"
            to="/member/activity"
          >
            <ActivityIcon />
            Activity
          </NavigationBar.Link>
        )}
      </Match>
      <Match path="/member/applications/*">
        {({ match }) => (
          <NavigationBar.Link
            active={match ? "true" : undefined}
            as={ReachLink}
            data-testid="navigation-bar--apps"
            to="/member/applications"
          >
            <ApplicationIcon />
            Apps
          </NavigationBar.Link>
        )}
      </Match>
      <Match path="/member/account/*">
        {({ match }) => (
          <NavigationBar.Link
            active={match ? "true" : undefined}
            as={ReachLink}
            data-testid="navigation-bar--account"
            to="/member/account"
          >
            <AccountIcon />
            Account
          </NavigationBar.Link>
        )}
      </Match>
    </NavigationBar>
  );
};

export default UnauthenticatedNavigationBar;
