import { rem } from "polished";
import styled from "styled-components";

import { MenuButton } from "./menu-button/src";

const StyledMenuButton = styled(MenuButton)`
  align-items: flex-end;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  height: ${rem(44)};
  justify-content: center;
  padding: 0;
  width: ${rem(44)};

  &:focus {
    outline: none;
  }
`;

export default StyledMenuButton;
