import { get } from "lodash";
import { rem } from "polished";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root {
    --reach-menu-button: 1;
    font-size: 100%;
  }

  [data-reach-menu] {
    font-family: sans-serif;
    display: block;
    position: absolute;
  }

  [data-reach-menu-list] {
    display: block;
    white-space: nowrap;
    border: solid 1px hsla(0, 0%, 0%, 0.25);
    background: hsla(0, 100%, 100%, 0.99);
    outline: none;
    padding: 1rem 0;
    font-size: 85%;
  }

  [data-reach-menu-item] {
    display: block;
  }

  [data-reach-menu-item] {
    /* reach-menu-item */
    cursor: pointer;

    /* a */
    display: block;
    color: inherit;
    font: inherit;
    text-decoration: initial;

    /* both */
    padding: 5px 20px;
  }

  /* pseudo pseudo selector */
  [data-reach-menu-item][data-selected] {
    background: hsl(211, 81%, 36%);
    color: white;
    outline: none;
  }

  * {
    box-sizing: border-box;
  }

  body {
    background-color: #f8f8f8;
    color: #5b6b7d;
    font-family: PublicSans-Regular, Helvetica, Arial, Roboto, sans-serif;
    line-height: 1.5;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.15;
    margin-top: 0;
  }

  h1,
  h2 {
    font-family: SourceSerifPro-Regular, Georgia, Times, serif;
    font-weight: 400;
  }

  h3,
  h5 {
    font-family: PublicSans-Regular, Helvetica, Arial, Roboto, sans-serif;
    font-weight: 400;
  }

  h1 {
    color: #2a3f53;
    font-size:  ${rem(30)};
    margin-bottom: ${rem(16)};
    line-height: 1.3;
  }

  h2 {
    color: #2a3f53;
    font-size: ${rem(20)};
    margin-bottom: ${rem(16)};
  }

  h3 {
    color: #2a3f53;
    font-size: ${rem(20)};
    margin-bottom: ${rem(16)};
  }

  h4 {
    color: #061c31;
    font-size: ${rem(16)};
    font-family: PublicSans-SemiBold, Helvetica, Arial, Roboto, sans-serif;
    margin-bottom: ${rem(8)};
    font-weight: 500;
  }

  h5 {
    font-size: ${rem(16)};
    margin-bottom: ${rem(8)};
  }

  h6 {
    font-size: ${rem(14)};
    margin-bottom: ${rem(8)};
  }

  ol,
  p,
  ul {
    margin-top: 0;
  }

  a {
    color: ${(props) => get(props, "theme.palette.primary.dark")};
    text-decoration: none;
  }
  
  button,
  input {
    font-family: PublicSans-Regular, Helvetica, Arial, Roboto, sans-serif;
  }

  button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    -webkit-appearance: none;
  }

  select {
    -webkit-appearance: none;

    &::-ms-expand {
      display: none;
    }
  }

  img,
  svg {
    display: block;
  }

  img {
    max-width: 100%;
  }
`;

export default GlobalStyle;
