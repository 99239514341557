import PropTypes from "prop-types";
import React from "react";

import { getPoints } from "../../../utils/user";
import { Breakpoint, Grid, MediaQuery } from "../../ReactComponents/Components";
import AccountMenuButton from "./AccountMenuButton";
import Redeem from "./Redeem";

const AccountMenuButtonComponent = ({ user }) => {
  if (!user) {
    return false;
  }

  return (
    <React.Fragment>
      <MediaQuery maxWidth={Breakpoint.SMALL}>
        <AccountMenuButton data-testid="points--menuButton">{`My Points: ${getPoints(
          user
        )}`}</AccountMenuButton>
      </MediaQuery>
      <MediaQuery minWidth={Breakpoint.SMALL}>
        <Grid.Flex alignItems="center">
          <Grid.Box>
            <AccountMenuButton data-testid="points--menuButton">
              {`My Points: ${getPoints(user)}`}
            </AccountMenuButton>
          </Grid.Box>
          <Grid.Box flex="0 0 auto" ml={1}>
            <Redeem data-testid="redeem--headerButton" to="/member/reward">
              Redeem
            </Redeem>
          </Grid.Box>
        </Grid.Flex>
      </MediaQuery>
    </React.Fragment>
  );
};

AccountMenuButtonComponent.propTypes = {
  user: PropTypes.shape({
    goal: PropTypes.number.isRequired,
    points: PropTypes.number.isRequired,
  }).isRequired,
};

export default AccountMenuButtonComponent;
