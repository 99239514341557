import styled from "styled-components";

export const NAVBAR_HEIGHT = 40;

export const Nav = styled.nav`
  margin: 0 auto;
  padding: 0;
  max-width: ${(props) => props.theme.breakpoint.medium}px;
  display: flex;
  align-items: flex-end;
  height: ${NAVBAR_HEIGHT + 10}px;

  @media screen and (max-width: ${(props) => props.theme.breakpoint.small}px) {
    position: relative;
    height: ${NAVBAR_HEIGHT + 15}px;

    .logo,
    .expand {
      position: absolute;
    }

    .logo {
      top: 10px;
      left: 20px;
    }

    .expand {
      top: 20px;
      right: 20px;
    }
  }
`;

Nav.Container = styled.div`
  z-index: 10;
  width: 100vw;
  border-bottom: 1px solid #cdcdcd;
  background: rgba(255, 255, 255, 0.95);
  position: sticky;
  top: 0;
`;

Nav.Logo = styled.button.attrs({ type: `button` })`
  display: block;
  padding: 0;
  margin-bottom: 15px;
  background: transparent;
  border: none;
  height: 18px;
  width: 130px;

  svg {
    display: block;
    max-width: 100%;
  }
`;

Nav.Item = styled.li`
  text-transform: uppercase;
  font-family: ${(props) => props.theme.font.primarySans}px;
  font-size: 0.75rem;
  font-family: PublicSans-Bold;
  letter-spacing: 0.3px;
  line-height: 1.125rem;
  text-align: center;

  a {
    color: #5b6b7d;
    text-decoration: none;
    display: block;
    padding: 10px 30px 15px;
    border-bottom: 2px solid transparent;

    &.active {
      color: ${(props) => props.theme.brand.primary};
      border-bottom-color: ${(props) => props.theme.brand.primary};
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoint.small}px) {
    &.callToAction {
      padding: 10px 0 10px 30px;

      a {
        color: #fff;
        background: ${(props) => props.theme.brand.primary};
        padding: 5px 8px;
        border-radius: 4px;
      }

      &.active {
        border-bottom-color: transparent;
      }
    }
  }
`;

Nav.Toggle = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;

  @media screen and (min-width: ${(props) => props.theme.breakpoint.small}px) {
    display: none;
  }

  display: block;
  cursor: pointer;
  display: inline-block;
  width: auto;
  transition: all 0.7s ease;

  .bar {
    display: block;
    border-radius: 2px;
    height: 4px;
    width: 30px;
    background: ${(props) => props.theme.brand.primary};
    margin: 0 auto 12px;
    transition: all 0.25s ease;

    &.bottom {
      margin-bottom: 0;
    }
  }

  &.active {
    .bar {
      background-color: #fff;
      &.top {
        transform: translateY(8px) rotateZ(45deg);
      }

      &.bottom {
        transform: translateY(-8px) rotateZ(-45deg);
      }
    }
  }
`;

Nav.NavList = styled.ul`
  height: initial;
  width: initial;
  max-width: initial;
  position: initial;
  left: initial;
  top: initial;
  bottom: initial;
  background: #fff;
  align-items: flex-end;

  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1;

  .spacer {
    margin-right: auto;
  }

  > li:first-child {
    // logo, hiding for non mobile
    display: none;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoint.small}px) {
    > li:last-child {
      // final item has a bit of right padding(otherwise it runs into the browser edge)
      margin-right: 3px;
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoint.small}px) {
    display: block;
    opacity: 0;
    height: 100vh;
    width: 100%;
    position: fixed;
    left: -100vw;
    top: 0;
    bottom: 0;
    right: 0;
    background: ${(props) => props.theme.brand.primary};
    box-shadow: 20px 0px 120px 62px rgba(255, 255, 255, 1);
    transition: all 0.2s ease-in-out;
    overflow: scroll;
    padding-top: 80px;
    padding-left: 30px;

    > li {
      width: 50vw;
      text-align: left;
      padding-left: 0;

      a {
        color: #87afdd;
        padding-left: 0;

        &.active {
          color: #fff;
        }
      }

      &.callToAction {
        padding: 0;

        a {
          padding-left: 0;
        }
      }

      &:first-child {
        display: block;
        margin-bottom: 30px;
      }
    }

    &.active {
      left: 0;
      opacity: 1;
    }

    .spacer {
      margin-right: inherit;
    }

    ${Nav} {
      height: 40px;
    }

    svg {
      g,
      path {
        fill: #fff;
      }
    }
  }
`;
