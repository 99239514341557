import PropTypes from "prop-types";
import { useEffect } from "react";

import trackingService from "../../services/trackingService";

const EventTracker = (props) => {
  useEffect(() => {
    trackingService.trackEvent(
      Object.keys(props).reduce((accumulator, key) => {
        if (props[key]) {
          return {
            ...accumulator,
            [key]: props[key],
          };
        }

        return accumulator;
      }, {})
    );
  }, []);

  return null;
};

EventTracker.propTypes = {
  action: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.number,
};

export default EventTracker;
