import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectEvents } from "./selectors";
import { reducer, thunks } from "./slice";

export const useEvents = ({ name } = { name: "feed" }) => {
  const events = useSelector((state) => selectEvents(state, name));

  const dispatch = useDispatch();
  const fetchEvents = useCallback(
    (params) => dispatch(thunks.fetchPaginated(name, params)),
    [dispatch]
  );

  return [events, fetchEvents];
};

export default reducer;
