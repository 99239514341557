import { isNull, times } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import StarRating from "./StarRating";

const StarRatingComponent = ({ disabled, name, onClick, stars, value }) => (
  <StarRating disabled={!isNull(value)}>
    {times(stars, (index) => (
      <React.Fragment key={index}>
        <StarRating.Input
          checked={value && value === stars - index ? true : false}
          data-testid={name}
          disabled={disabled}
          id={`${name}-${index}`}
          name={name}
          onClick={() => onClick && onClick(stars - index)}
          type="radio"
        />
        <StarRating.Label htmlFor={`${name}-${index}`}>
          <StarRating.Value>
            {index + 1}/{stars} stars
          </StarRating.Value>
        </StarRating.Label>
      </React.Fragment>
    ))}
  </StarRating>
);

StarRatingComponent.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  stars: PropTypes.number,
  value: PropTypes.number,
};

StarRatingComponent.defaultProps = {
  disabled: false,
  name: "star-rating",
  stars: 5,
  value: null,
};

export { StarRating };

export default StarRatingComponent;
