import { get, isInteger } from "lodash";
import { rem } from "polished";
import styled from "styled-components";

const Card = styled.div`
  background-color: ${(props) => get(props, "theme.palette.base.lightest")};
  border-radius: ${rem(4)};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: ${(props) =>
    isInteger(props.height) ? rem(props.height) : props.height};
  max-width: ${(props) => rem(props.width)};
  min-height: ${(props) =>
    isInteger(props.height) ? rem(props.height) : props.height};
  position: relative;
  width: 100%;
`;

export default Card;
