import { get } from "lodash";
import { rem } from "polished";
import styled from "styled-components";

export const CardActionBar = styled.div`
  border-top: 1px solid ${(props) => get(props, "theme.palette.base.light")};
  display: flex;
`;

CardActionBar.Action = styled.button.attrs({
  type: "button",
})`
  align-items: center;
  color: ${(props) => get(props, "theme.palette.base.medium")};
  display: flex;
  flex: 1;
  font-size: ${rem(18)};
  font-family: PublicSans-SemiBold;
  height: ${rem(72)};
  justify-content: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  &:focus {
    color: ${(props) => get(props, "theme.palette.primary.dark")};
    outline: none;
  }

  & + & {
    border-left: 1px solid ${(props) => get(props, "theme.palette.base.light")};
  }
`;

export default CardActionBar;
