import { get } from "lodash";
import { rem } from "polished";
import styled from "styled-components";

const CardMediaBadge = styled.div`
  background-color: ${(props) => get(props, "theme.palette.base.lightest")};
  border: 1px solid ${(props) => get(props, "theme.palette.base.light")};
  border-radius: ${rem(3)};
  display: inline-flex;
  font-size: ${rem(10)};
  letter-spacing: 0.3px;
  line-height: 1;
  padding: ${rem(6)} ${rem(10)} ${rem(5)};
  text-transform: uppercase;
  z-index: 10;
`;

export default CardMediaBadge;
