import { createSlice } from "@reduxjs/toolkit";
import { uniq } from "lodash";

import dataService from "../../services/dataService";
import { defaultActions, getInitialPaginatedState, getView } from "../utils";

const { actions, reducer } = createSlice({
  name: "events",
  initialState: {
    entities: {},
    views: {},
  },
  reducers: {
    fetchRequest: (state, action) => {
      const viewState = getView(state, action, getInitialPaginatedState);
      defaultActions.fetchRequest(viewState);
      state.views[action.payload.name] = viewState;
    },
    fetchResponse: (state, action) => {
      const viewState = getView(state, action);
      defaultActions.fetchResponse(viewState);
      viewState.keys = uniq(viewState.keys.concat(action.payload.keys));
      viewState.pageInfo.page = action.payload.page;
      viewState.pageInfo.hasNextPage = action.payload.keys.length >= 20;
      const newEntities = Object.assign(
        state.entities,
        action.payload.entities
      );
      state.entities = viewState.keys.reduce((accumulator, key) => {
        return {
          ...accumulator,
          [key]: newEntities[key],
        };
      }, {});
    },
    fetchError: (state, action) => {
      const viewState = getView(state, action);
      defaultActions.fetchError(viewState, action);
    },
  },
});

const thunks = {
  fetchPaginated: (name, params) => async (dispatch) => {
    try {
      dispatch(actions.fetchRequest({ name }));
      const entities = await dataService.getEvents({ page: params.page });
      dispatch(
        actions.fetchResponse({
          entities,
          keys: Object.keys(entities),
          name,
          page: params.page,
        })
      );
    } catch (error) {
      dispatch(actions.fetchError({ error: error.toString(), name }));
    }
  },
};

export { actions, reducer, thunks };
