import { rem } from "polished";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const ActivityIndicator = styled.div`
  animation: 0.67s ${rotate} linear infinite;
  height: ${(props) => rem(props.size)};
  width: ${(props) => rem(props.size)};
`;

export default ActivityIndicator;
