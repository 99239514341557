import styled from "styled-components";

const PromotionContainer = styled.div`
  background-color: ${(props) => props.color};
`;

const PromotionTitle = styled.h2`
  color: #2a3f53;
  line-height: 1.4;
`;

const PromotionDescription = styled.p`
  font-size: 92.5%;

  > a {
    text-decoration: underline;
  }
`;

export const PromotionStyles = {
  Container: PromotionContainer,
  Description: PromotionDescription,
  Title: PromotionTitle,
};
