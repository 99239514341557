const Color = {
  base: {
    darker: "#061c31",
    dark: "#2a3f53",
    medium: "#5b6b7d",
    light: "#e3e3e3",
    lighter: "#f8f8f8",
    lightest: "#fff",
  },
  primary: {
    dark: "#0f5fbc",
    medium: "#20ceff",
    light: "#92ccee",
    lighter: "#d8ecf7",
  },
  secondary: {
    medium: "#37d596",
    lighter: "#c4f0df",
  },
  tertiary: {
    dark: "#ed7b52",
    medium: "#f5ba48",
  },
  error: "#eb3f54",
  success: "#45c071",
  announcement: "#0F5FBC",
};

export default Color;
