import { clamp } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import PointBarComponent from "./PointBar.js";

/**
 * @render react
 * @name PointBar
 * @description Points Bar
 * @example
 * <PointBar max={10000} points={8000} />
 */

export const getPercentage = (value, max) =>
  clamp(Math.floor((parseInt(value) / parseInt(max)) * 100), 0, 100);

const PointBar = ({ max, value }) => (
  <PointBarComponent>
    <PointBarComponent.Value>{value.toLocaleString()}</PointBarComponent.Value>
    <PointBarComponent.Maximum>
      {max.toLocaleString()}
    </PointBarComponent.Maximum>
    <PointBarComponent.Bar width={getPercentage(value, max)} />
  </PointBarComponent>
);

PointBar.propTypes = {
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

PointBar.defaultProps = {
  max: 10000,
};

export default PointBar;
