import {
  differenceInDays,
  distanceInWordsStrict,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  getDaysInMonth as getDaysInMonthFNS,
  isFuture,
  isToday,
  isYesterday,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";

export const distanceInWords = (date: Date | string) => {
  if (isToday(date)) {
    return "Today";
  } else if (isYesterday(date)) {
    return "Yesterday";
  }

  if (differenceInDays(new Date(), date) >= 30) {
    return format(date, "MMM D, YYYY");
  }

  return distanceInWordsStrict(endOfDay(Date.now()), endOfDay(date), {
    addSuffix: true,
  });
};

export const birthDate = (date: Date | string) => format(date, "MMMM D, YYYY");

export const getDayRange = (date: Date | string) => ({
  endDate: endOfDay(date),
  period: "day",
  startDate: startOfDay(date),
});

export const getMonthRange = (date: Date) => {
  const endDate = endOfMonth(date);
  return {
    endDate: isFuture(endDate) ? endOfDay(new Date()) : endDate,
    period: "month",
    startDate: startOfMonth(date),
  };
};

export const getWeekRange = (date: Date | string) => {
  const endDate = endOfWeek(date);
  return {
    endDate: isFuture(endDate) ? endOfDay(new Date()) : endDate,
    period: "week",
    startDate: startOfWeek(date),
  };
};

export const formatExpiryDateUTC = (date: Date | string) => {
  const utcDate = new Date(date);
  return distanceInWordsStrict(
    new Date(utcDate.valueOf() + utcDate.getTimezoneOffset() * 60 * 1000),
    new Date()
  );
};

export const formatDateWithTimezone = (
  date: Date | string,
  dateFormat = "MMMM Do, YYYY"
) => {
  const dateValue = new Date(date);
  const dateTimezoneOffset = new Date(
    dateValue.getTime() + Math.abs(dateValue.getTimezoneOffset() * 60000)
  );
  return format(dateTimezoneOffset, dateFormat);
};

export const formatDayOfWeek = (date: Date | string) => {
  const utcDate = new Date(date);
  return format(
    new Date(utcDate.valueOf() + utcDate.getTimezoneOffset() * 60 * 1000),
    "dddd"
  );
};

export const getDaysInMonth = (
  year?: number,
  month?: number,
  day?: number
): number => {
  const currentDate = new Date();
  return (
    getDaysInMonthFNS(
      new Date(
        year || currentDate.getFullYear(),
        month || currentDate.getMonth(),
        day || 1
      )
    ) || 31
  );
};
