export const toCentemeters = (feet, inches) =>
  (parseInt(feet, 10) * 12 + parseInt(inches, 10)) * 2.54;

const toFeet = (value) => Math.floor(value * 0.03280841666);
const toInches = (value) => (Math.round(value * 0.393701 * 2) / 2) % 12;

export const getHeight = (centimeter) => {
  if (centimeter === "") {
    return { feet: "", inches: "" };
  }

  return {
    feet: toFeet(centimeter),
    inches: toInches(centimeter),
  };
};
