import {
  ArrowLeftOutlined,
  Box,
  Button as EveButton,
} from "@evidation-shared/eve";
import { isNull } from "lodash";
import React, { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { AuthState, useAuthState } from "@achievement/portal-client";

import { useNotification } from "../../context/EveNotificationContext";
import UserContext from "../../context/UserContext";
import trackingService, { Events } from "../../services/trackingService";
import AccountMenu from "../AccountMenu";
import HeaderMenu from "../HeaderMenu";
import Match from "../Match";
import {
  Breakpoint,
  Button,
  ButtonColor,
  ButtonGroup,
  ButtonSize,
  Grid,
  Header,
  MediaQuery,
} from "../ReactComponents/Components";
import { EvidationLogo, Notification } from "../ReactComponents/Core";
import AuthenticatedNavigationBar from "./AuthenticatedNavigationBar";
import FixedHeader from "./FixedHeader";
import UnauthenticatedNavigationBar from "./UnauthenticatedNavigationBar";

const FixedHeaderComponent = () => {
  const { authState } = useAuthState();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const notification = useNotification();

  const isAuthenticated = authState === AuthState.AUTHENTICATED;

  const handleClickBack = () => {
    if (location.pathname === "/login") {
      window.location.href = window.env.WEBSITE_URL;
    } else {
      navigate("/login");
    }
  };

  return (
    <Match path="/member/*">
      {({ match }) => (
        <FixedHeader offsetHeight={isAuthenticated && !isNull(match)}>
          <FixedHeader.Content>
            {notification && (
              <FixedHeader.Notification>
                <Notification
                  animationDuration={notification.animationDuration}
                  duration={notification.duration}
                  icon={notification.icon}
                  key={notification.message}
                >
                  {notification.message}
                </Notification>
              </FixedHeader.Notification>
            )}
            <Header data-testid="header">
              <Grid style={{ height: "100%" }}>
                <Grid.Flex
                  alignItems="center"
                  mx={[2, 3, 3, 0]}
                  style={{ height: "100%" }}
                >
                  <Grid.Box flex={1}>
                    {isAuthenticated && match ? (
                      <MediaQuery minWidth={Breakpoint.SMALL}>
                        <AccountMenu user={user} />
                      </MediaQuery>
                    ) : (
                      <Match path="/onboarding/*">
                        {({ match }) =>
                          !match ? (
                            <Box
                              alignContent="center"
                              alignItems="center"
                              flexDirection="row"
                            >
                              <ArrowLeftOutlined size="md" />
                              <EveButton
                                onPress={handleClickBack}
                                size="lg"
                                testID="header--backButton"
                                variant="link"
                              >
                                Back
                              </EveButton>
                            </Box>
                          ) : null
                        }
                      </Match>
                    )}
                  </Grid.Box>
                  <Grid.Box flex="0 0 auto" mx={3}>
                    <Link
                      onClick={() =>
                        trackingService.trackEvent(
                          Events.ACHIEVEMENT_ICON_CLICK
                        )
                      }
                      to={
                        match
                          ? "/member/activity"
                          : `/${window.location.search}`
                      }
                    >
                      <EvidationLogo height={30} width={152} />
                    </Link>
                  </Grid.Box>
                  <Grid.Box flex={1}>
                    <Grid.Flex justifyContent="flex-end">
                      {isAuthenticated ? (
                        match ? (
                          <div>
                            <HeaderMenu isAuthenticated />
                          </div>
                        ) : undefined
                      ) : (
                        <React.Fragment>
                          <MediaQuery maxWidth={Breakpoint.LARGE}>
                            <HeaderMenu />
                          </MediaQuery>
                          <MediaQuery minWidth={Breakpoint.LARGE}>
                            <ButtonGroup>
                              <Button
                                data-testid="button--login"
                                onClick={() => navigate("/login")}
                                size={ButtonSize.MEDIUM}
                                uppercase
                              >
                                Log in
                              </Button>
                              <Button
                                color={ButtonColor.PRIMARY}
                                data-testid="button--signup"
                                onClick={() => {
                                  navigate(`/signup${window.location.search}`, {
                                    replace: true,
                                  });
                                  trackingService.trackEvent(
                                    Events.SIGNUP_START,
                                    {
                                      signup_source: "header",
                                    }
                                  );
                                }}
                                size={ButtonSize.MEDIUM}
                                uppercase
                              >
                                Sign up
                              </Button>
                            </ButtonGroup>
                          </MediaQuery>
                        </React.Fragment>
                      )}
                    </Grid.Flex>
                  </Grid.Box>
                </Grid.Flex>
              </Grid>
            </Header>
            {isAuthenticated && match && (
              <React.Fragment>
                <MediaQuery minWidth={Breakpoint.SMALL}>
                  {isAuthenticated ? (
                    <AuthenticatedNavigationBar />
                  ) : (
                    <UnauthenticatedNavigationBar />
                  )}
                </MediaQuery>
                <MediaQuery maxWidth={Breakpoint.SMALL}>
                  <AccountMenu user={user} />
                </MediaQuery>
              </React.Fragment>
            )}
          </FixedHeader.Content>
        </FixedHeader>
      )}
    </Match>
  );
};

FixedHeaderComponent.defaultProps = {};

FixedHeaderComponent.propTypes = {};

export default FixedHeaderComponent;
