import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import {
  AuthState,
  UserProfile,
  useAuthState,
  useProfile,
  useServer,
} from "@achievement/portal-client";
import { LoadingSpinner } from "@evidation-shared/eve";

import { useRedirect } from "../../utils/hooks";

enum MemberGateState {
  AUTHENTICATED = "authenticated",
  IDLE = "idle",
  INVALIDATED = "invalidated",
  ONBOARDED = "onboarded",
  ONBOARDING = "onboarding",
  UNAUTHENTICATED = "unauthenticated",
  UNKNOWN = "unknown",
}

const isOnboarded = (user: UserProfile) => {
  return !!user["accepted_tos"] && user["first_name"];
};

const MemberGate: React.FunctionComponent<any> = ({ children }) => {
  const location = useLocation();
  const { resetRedirect } = useRedirect();
  const server = useServer();
  const { authState } = useAuthState();

  React.useEffect(() => {
    if (authState === AuthState.UNAUTHENTICATED) {
      setGateState(MemberGateState.UNAUTHENTICATED);
    } else if (authState === AuthState.AUTHENTICATED) {
      setGateState(MemberGateState.AUTHENTICATED);
    }
  }, [authState]);

  const [gateState, setGateState] = React.useState(MemberGateState.IDLE);

  // hooks used over onError and onSuccess to avoid duplicate request
  const {
    data: profile,
    error,
    isSuccess,
    refetch,
  } = useProfile({
    enabled: gateState === MemberGateState.AUTHENTICATED,
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    // User's token is no longer valid
    if (error?.getError()?.status === "401") {
      setGateState(MemberGateState.INVALIDATED);
    } else if (error) {
      setGateState(MemberGateState.UNKNOWN);
    }
  }, [error, setGateState]);

  React.useEffect(() => {
    if (isSuccess) {
      setGateState(
        isOnboarded(profile.data)
          ? MemberGateState.ONBOARDED
          : MemberGateState.ONBOARDING
      );
      //@ts-ignore
      localStorage.setItem("csrf", profile.meta?.csrf);
      //@ts-ignore
      server.defaults.headers.common["X-CSRF-Token"] = profile.meta?.csrf;
    }
  }, [isSuccess, profile, setGateState]);

  React.useEffect(() => {
    if (gateState === MemberGateState.ONBOARDED) {
      resetRedirect();
    }
  }, [gateState, resetRedirect]);

  // TODO: replace with error state
  if (gateState === MemberGateState.UNKNOWN) {
    return <button onClick={() => refetch()}>Retry</button>;
  }

  if (gateState === MemberGateState.INVALIDATED) {
    return (
      <Navigate
        replace
        state={{
          redirect: `${location.pathname}${location.search}`,
          message: "Please log in again",
        }}
        to="/logout"
      />
    );
  }

  if (gateState === MemberGateState.UNAUTHENTICATED) {
    console.log(location);
    return (
      <Navigate
        replace
        state={{ redirect: `${location.pathname}${location.search}` }}
        to="/login"
      />
    );
  } else if (gateState === MemberGateState.ONBOARDING) {
    return (
      <Navigate
        replace
        state={{ redirect: `${location.pathname}${location.search}` }}
        to="/onboarding"
      />
    );
  }

  if (gateState === MemberGateState.ONBOARDED) {
    return children;
  }

  return <LoadingSpinner marginY={4} size="lg" />;
};

export default MemberGate;
