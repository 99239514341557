import "@reach/dialog/styles.css";

import { LoadingSpinner } from "@evidation-shared/eve";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import {
  AuthState,
  ServerProvider,
  getAccessToken,
  useAuthContext,
} from "@achievement/portal-client";

import AuthenticatedGate from "./components/AuthenticatedGate";
import DoctorBanner from "./components/DoctorBanner";
import ErrorBoundary from "./components/ErrorBoundary";
import FibroidBanner from "./components/FibroidBanner";
import FixedHeader from "./components/FixedHeader";
import Footer from "./components/Footer";
import MaintenanceBanner from "./components/MaintenanceBanner";
import MemberGate from "./components/MemberGate";
import { GlobalStyle, theme } from "./components/ReactComponents/Components";
import Redirect from "./components/Redirect";
import PageTracker from "./components/Tracker/PageTracker";
import UnauthenticatedGate from "./components/UnauthenticatedGate";
import { NotificationProvider as EveNotificationProvider } from "./context/EveNotificationContext";
import { FeatureToggleProvider } from "./context/FeatureToggleContext";
import { NotificationProvider } from "./context/NotificationContext";
import { UserProvider } from "./context/UserContext";
import sessionStorageService from "./services/sessionStorageService";

const Error = React.lazy(() => import("./pages/Error"));
const Refer = React.lazy(() => import("./pages/Refer"));
const Login = React.lazy(() => import("./pages/Login"));
const Logout = React.lazy(() => import("./pages/Logout"));
const Onboarding = React.lazy(() => import("./pages/Onboarding"));
const OnboardingApplications = React.lazy(() =>
  import("./pages/Onboarding/OnboardingApplications")
);
const OnboardingApplicationConnect = React.lazy(() =>
  import("./pages/Onboarding/OnboardingApplicationConnect")
);
const OnboardingComplete = React.lazy(() =>
  import("./pages/Onboarding/OnboardingComplete")
);
const OnboardingSignup = React.lazy(() =>
  import("./pages/Onboarding/OnboardingSignup")
);
const OnboardingSuccess = React.lazy(() =>
  import("./pages/Onboarding/OnboardingSuccess")
);
const OnboardingProgramCelebration = React.lazy(() =>
  import("./pages/Onboarding/OnboardingProgramCelebration")
);
const OnboardingProgramSummary = React.lazy(() =>
  import("./pages/Onboarding/OnboardingProgramSummary")
);
const OnboardingProgramTerms = React.lazy(() =>
  import("./pages/Onboarding/OnboardingProgramTerms")
);
const Maintenance = React.lazy(() => import("./pages/Maintenance"));
const Member = React.lazy(() => import("./pages/Member"));
const Subscription = React.lazy(() => import("./pages/Subscription"));
const Offers = React.lazy(() => import("./pages/Offers"));
const Password = React.lazy(() => import("./pages/Password"));
const Edit = React.lazy(() => import("./pages/Password/Edit"));
const Set = React.lazy(() => import("./pages/Password/Set"));
const Signup = React.lazy(() => import("./pages/Signup"));

const queryClient = new QueryClient();

const SEARCH_PARAM_INVITE_CODE = "invite_code";
const SEARCH_PARAM_REDIRECT = "redirect";

const App = () => {
  const { authState, initializeAuth } = useAuthContext();
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    // store invite code to support third party sign in program enrollment
    const inviteCode = searchParams.get(SEARCH_PARAM_INVITE_CODE);
    if (inviteCode) {
      sessionStorageService.setInviteCode(inviteCode);
    }

    // store redirect to support third party sign in redirect
    const redirect = searchParams.get(SEARCH_PARAM_REDIRECT);
    if (redirect) {
      sessionStorageService.setRedirect(redirect);
    }
  }, [searchParams]);

  React.useEffect(() => {
    if (authState === AuthState.IDLE) {
      initializeAuth();
    }
  }, [authState, initializeAuth]);

  if (authState === AuthState.IDLE) {
    return <LoadingSpinner marginY={4} size="lg" />;
  }

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ServerProvider
          appAgent={`Achievemint/${window.env.REACT_APP_ACHIEVEMENT_VERSION} (portal-web)`}
          environment={window.env.REACT_APP_ENVIRONMENT}
          getAccessToken={getAccessToken}
        >
          <FeatureToggleProvider>
            <UserProvider>
              <EveNotificationProvider>
                <GlobalStyle />
                <FixedHeader />
                <ErrorBoundary>
                  <React.Fragment>
                    <PageTracker />
                    <FibroidBanner />
                    <DoctorBanner />
                    <MaintenanceBanner />
                  </React.Fragment>
                  <NotificationProvider>
                    <React.Suspense
                      fallback={<LoadingSpinner marginY={4} size="lg" />}
                    >
                      {window.env.REACT_APP_MAINTENANCE_MODE === "true" ? (
                        <Routes>
                          <Route
                            element={<Redirect location="/login" />}
                            path="/"
                          />
                          <Route
                            element={
                              <Redirect location={window.env.WEBSITE_URL} />
                            }
                            path="/growth"
                          />
                          <Route
                            element={
                              <Redirect location={window.env.WEBSITE_URL} />
                            }
                            path="/growth-2"
                          />
                          <Route
                            element={
                              <Redirect location={window.env.WEBSITE_URL} />
                            }
                            path="/growth-3"
                          />
                          <Route
                            element={
                              <Redirect location={window.env.WEBSITE_URL} />
                            }
                            path="/growth-4"
                          />
                          <Route
                            element={
                              <Redirect
                                location={`${window.env.WEBSITE_URL}/about-us`}
                              />
                            }
                            path="about"
                          />
                          <Route
                            element={
                              <Redirect
                                location={`${window.env.WEBSITE_URL}/blog-category/research`}
                              />
                            }
                            path="research"
                          />
                          <Route
                            element={
                              <Redirect
                                location={`${window.env.WEBSITE_URL}/members/terms`}
                              />
                            }
                            path="terms"
                          />
                          <Route
                            element={
                              <Redirect
                                location={`${window.env.WEBSITE_URL}/members/privacy`}
                              />
                            }
                            path="privacy"
                          />
                          <Route
                            element={<Subscription />}
                            path="email_subscription"
                          />
                          <Route element={<Maintenance />} path="*" />
                        </Routes>
                      ) : (
                        <Routes>
                          <Route
                            element={<Redirect location="/login" />}
                            path="/"
                          />
                          <Route
                            element={
                              <Redirect
                                location={`${window.env.WEBSITE_URL}/about-us`}
                              />
                            }
                            path="about"
                          />
                          <Route
                            element={
                              <UnauthenticatedGate>
                                <Login />
                              </UnauthenticatedGate>
                            }
                            path="login"
                          />
                          <Route element={<Logout />} path="logout" />
                          <Route
                            element={
                              <Redirect location="/member/programs/heart_health" />
                            }
                            path="/member/programs"
                          />
                          <Route
                            element={
                              <Redirect location="/member/programs/heart_health?action=diary" />
                            }
                            path="/member/programs/heart_health/diary"
                          />
                          <Route
                            element={
                              <Redirect location={window.env.WEBSITE_URL} />
                            }
                            path="/growth"
                          />
                          <Route
                            element={
                              <Redirect location={window.env.WEBSITE_URL} />
                            }
                            path="/growth-2"
                          />
                          <Route
                            element={
                              <Redirect location={window.env.WEBSITE_URL} />
                            }
                            path="/growth-3"
                          />
                          <Route
                            element={
                              <Redirect location={window.env.WEBSITE_URL} />
                            }
                            path="/growth-4"
                          />
                          <Route element={<Refer />} path="refer/*" />
                          <Route
                            element={
                              <MemberGate>
                                <Member />
                              </MemberGate>
                            }
                            path="member/*"
                          />
                          <Route
                            element={
                              <AuthenticatedGate>
                                <OnboardingComplete />
                              </AuthenticatedGate>
                            }
                            path="onboarding/complete"
                          />
                          <Route
                            element={
                              <AuthenticatedGate>
                                <Onboarding />
                              </AuthenticatedGate>
                            }
                            path="onboarding"
                          >
                            <Route
                              element={<OnboardingApplications />}
                              path="applications"
                            />
                            <Route
                              element={<OnboardingApplicationConnect />}
                              path="applications/:applicationIdentifier/connect"
                            />
                            <Route
                              element={<OnboardingSignup />}
                              path="signup"
                            />
                            <Route
                              element={<OnboardingSuccess />}
                              path="success"
                            />
                            <Route
                              element={<OnboardingProgramCelebration />}
                              path="programs/:programIdentifier/celebration"
                            />
                            <Route
                              element={<OnboardingProgramSummary />}
                              path="programs/:programIdentifier/summary"
                            />
                            <Route
                              element={<OnboardingProgramTerms />}
                              path="programs/:programIdentifier/terms"
                            />
                          </Route>
                          <Route element={<Offers />} path="offers/*" />
                          <Route
                            element={<Subscription />}
                            path="email_subscription"
                          />
                          <Route element={<Password />} path="password" />
                          <Route element={<Edit />} path="password/edit" />
                          <Route element={<Set />} path="password/set" />
                          <Route
                            element={
                              <Redirect
                                location={`${window.env.WEBSITE_URL}/members/privacy`}
                              />
                            }
                            path="privacy"
                          />
                          <Route
                            element={<Redirect to="privacy" />}
                            path="privacy/embedded"
                          />
                          <Route
                            element={
                              <Redirect
                                location={`${window.env.WEBSITE_URL}/blog-category/research`}
                              />
                            }
                            path="research"
                          />
                          <Route
                            element={
                              <UnauthenticatedGate>
                                <Signup />
                              </UnauthenticatedGate>
                            }
                            path="signup"
                          />
                          <Route
                            element={
                              <Redirect
                                location={`${window.env.WEBSITE_URL}/members/terms`}
                              />
                            }
                            path="terms"
                          />
                          <Route
                            element={
                              <Redirect location={window.env.WEBSITE_URL} />
                            }
                            path="welcome/*"
                          />
                          <Route
                            element={<Redirect to="terms" />}
                            path="terms/embedded"
                          />
                          <Route
                            element={<Redirect to="member/activity" />}
                            path="admin_authenticate"
                          />
                          <Route element={<Error />} path="*" />
                          <Route
                            element={
                              <Redirect location={window.env.WEBSITE_URL} />
                            }
                            path="/sweepstakes-rules"
                          />
                        </Routes>
                      )}
                    </React.Suspense>
                  </NotificationProvider>
                </ErrorBoundary>
                <Footer />
              </EveNotificationProvider>
            </UserProvider>
          </FeatureToggleProvider>
        </ServerProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
