import { format } from "date-fns";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import eventAggregateActions from "./actions";
import eventAggregateSelectors from "./selectors";

export const useEventAggregate = (params) => {
  const dispatch = useDispatch();

  const eventAggregate = useSelector(
    eventAggregateSelectors.selectEventAggregate({
      endDate: params.endDate,
      period: params.period || "day",
    })
  );

  const fetchEventAggregate = useCallback(
    (params) => dispatch(eventAggregateActions.fetch(params)),
    [dispatch]
  );

  useEffect(() => {
    fetchEventAggregate({
      endDate: params.endDate,
      period: params.period,
      timezone: format(params.endDate, "Z"),
    });
  }, [params.endDate, params.period]);

  return [eventAggregate, fetchEventAggregate];
};
