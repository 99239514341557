import PropTypes from "prop-types";

import ActivityIndicator from "./ActivityIndicator";

const ActivityIndicatorComponent = ({ backgroundColor, color, size }) => (
  <ActivityIndicator size={size}>
    <svg height="100%" viewBox="0 0 32 32" width="100%">
      <circle
        cx="16"
        cy="16"
        fill="none"
        r="14"
        stroke={backgroundColor}
        strokeWidth="4"
      />
      <circle
        cx="16"
        cy="16"
        fill="none"
        r="14"
        stroke={color}
        strokeDasharray="80"
        strokeDashoffset="60"
        strokeWidth="4"
      />
    </svg>
  </ActivityIndicator>
);

ActivityIndicatorComponent.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};

ActivityIndicatorComponent.defaultProps = {
  backgroundColor: "rgba(255, 255, 255, 0.125)",
  color: "rgba(255, 255, 255, 0.375)",
  size: 32,
};

export { ActivityIndicator };

export default ActivityIndicatorComponent;
