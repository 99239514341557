import queryString from "query-string";

const UTM_PARAMS = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
];

/**
 * Returns a properly decoded string value
 * @param {string} value an encoded string value
 * @return {string}
 */
export const decode = (param) => decodeURIComponent(param.replace(/\+/g, " "));

/**
 * Returns a parsed version of the location.search value
 * @param {search} search the location.search value
 * @return {object}
 */
export const decodeSearch = (search, decodeFunc = decode) => {
  const params = queryString.parse(search);
  return Object.keys(params).reduce(
    (accumulator, key) => ({
      ...accumulator,
      [key]: decodeFunc(params[key]),
    }),
    {}
  );
};

/**
 * Returns a path to redirect to
 * @param {string} search the location.search value
 * @param {string} defaultRedirect a path to redirect to if no redirect query param is found
 */
export const getRedirect = (search, defaultRedirect) => {
  const { redirect } = queryString.parse(search);
  if (redirect) {
    return redirect;
  }

  return defaultRedirect;
};

/**
 * Returns an object of UTM params
 * @param {search} search the location.search value
 * @return {object}
 */
export const getUTMParams = (search) => {
  const params = decodeSearch(search, (param) => param);

  if (!params || params.length === 0) {
    return {};
  }

  return Object.keys(params).reduce((accumulator, key) => {
    if (UTM_PARAMS.includes(key)) {
      return { ...accumulator, [key]: params[key] };
    }

    return accumulator;
  }, {});
};
