import { get } from "lodash";
import { rem } from "polished";
import styled, { css } from "styled-components";

import CardMediaBadge from "./CardMediaBadge";

const CardMedia = styled.div`
  background-color: ${(props) => get(props, "theme.palette.base.lighter")};
  background-position: center center;
  background-size: cover;
  height: ${(props) => rem(props.height)};
  min-height: ${(props) => rem(props.height)};
  position: relative;

  ${(props) =>
    props.image &&
    css`
      background-image: url("${props.image}");
    `};

  ${CardMediaBadge} {
    bottom: 0;
    right: ${rem(24)};
    position: absolute;
    transform: translateY(50%);
  }
`;

export default CardMedia;
