import { get, isEmpty } from "lodash";
import { useState } from "react";

import PointHubStatistic from "../../components/PointHubStatistic";
import {
  ActivityIndicator,
  Grid,
  Space,
} from "../../components/ReactComponents/Components";
import { useEventAggregate } from "../../modules/eventAggregate/hooks";
import { getDefaultCards } from "../../modules/eventAggregate/utils";
import trackingService, { Events } from "../../services/trackingService";
import DailyPointSlider from "./DailyPointSlider";
import MonthlyPointSlider from "./MonthlyPointSlider";
import WeeklyPointSlider from "./WeeklyPointSlider";

const cardCount = 6;
const filteredCardCategories = ["event_summary", "redemption_summary"];

const PointHubComponent = () => {
  const [activeDateRange, setActiveDateRange] = useState({
    endDate: new Date(),
    startDate: new Date(),
  });

  const [eventAggregate] = useEventAggregate({
    endDate: activeDateRange.endDate,
    period: activeDateRange.period,
  });

  let cards = get(eventAggregate, "cards", []);
  if (cards.length < cardCount) {
    cards = [...cards, ...getDefaultCards(cards, cardCount)];
  }
  cards = cards.filter(card => !filteredCardCategories.includes(card.category));

  if (isEmpty(eventAggregate)) {
    return (
      <Grid.Flex justifyContent="center">
        <Grid.Box>
          <Space component="div" my={10}>
            <ActivityIndicator
              backgroundColor="transparent"
              color="#061c31"
              size={60}
            />
          </Space>
        </Grid.Box>
      </Grid.Flex>
    );
  }

  return (
    <Grid.Flex mt={3}>
      <Grid.Box flex={[6, 5]}>
        <Grid.Flex flexDirection="column" style={{ height: "100%" }}>
          <Grid.Box flex={1} mb={2}>
            <DailyPointSlider
              activeDateRange={activeDateRange}
              setActiveDateRange={setActiveDateRange}
            />
          </Grid.Box>
          <Grid.Box flex={1} my={1}>
            <WeeklyPointSlider
              activeDateRange={activeDateRange}
              setActiveDateRange={setActiveDateRange}
            />
          </Grid.Box>
          <Grid.Box flex={1} mt={2}>
            <MonthlyPointSlider
              activeDateRange={activeDateRange}
              setActiveDateRange={setActiveDateRange}
            />
          </Grid.Box>
        </Grid.Flex>
      </Grid.Box>
      <Grid.Box
        mx={[3, 4]}
        style={{ backgroundColor: "#0f5fbc" }}
        width="2px"
      />
      <Grid.Box flex={4}>
        <Grid.Flex flexWrap="wrap" m={-2}>
          {cards.slice(0, cardCount).map((card) => (
            <Grid.Box key={card.category} p={2} width={1 / 2}>
              <PointHubStatistic
                category={card.category}
                onClick={() => {
                  trackingService.trackEvent(Events.POINTS_CATEGORY, {
                    points_category: card.category,
                  });
                }}
                percentage={Math.round(card.percent * 100)}
                title={card.title}
              />
            </Grid.Box>
          ))}
        </Grid.Flex>
      </Grid.Box>
    </Grid.Flex>
  );
};

PointHubComponent.propTypes = {};

export default PointHubComponent;
