import {
  defaultSystemFonts,
  MixedStyleDeclaration,
} from "react-native-render-html";

const systemFonts = [
  ...defaultSystemFonts,
  "SourceSerifPro-Regular",
  "PublicSans-Regular",
];

const htmlBaseStyle: MixedStyleDeclaration = {
  color: "#2A3F53",
  fontFamily: "PublicSans-Regular",
};

const htmlTagStyles: Readonly<Record<string, MixedStyleDeclaration>> = {
  h1: {
    fontFamily: "SourceSerifPro-Regular",
    fontSize: 30,
    lineHeight: 39,
  },
  h2: {
    fontFamily: "SourceSerifPro-Regular",
    fontSize: 20,
  },
  h3: {
    fontSize: 20,
  },
  h4: {
    fontWeight: "400",
    fontSize: 16,
  },
  h5: {
    fontSize: 16,
  },
  h6: {
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 18,
  },
  p: {
    fontSize: 16,
    lineHeight: 22,
  },
};

export { htmlBaseStyle, htmlTagStyles, systemFonts };
