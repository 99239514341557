import React from "react";
import { useLocation } from "react-router-dom";

import { getUTMParams } from "../../utils/queryString";
import PromotionBanner from "../PromotionBanner";
import { ReactComponent as Logo } from "./bdo.svg";
import { ReactComponent as Image } from "./image.svg";

const utmParams = {
  utm_source: "bdo",
  utm_campaign: "bdo",
};
const paths = ["/", "/about", "/research"];

const DoctorBanner = () => {
  const location = useLocation();
  const searchParams = getUTMParams(location.search);
  const showBanner = Object.keys(utmParams).every(
    (key) => searchParams[key] === utmParams[key]
  );

  if (showBanner && paths.includes(location.pathname)) {
    return (
      <PromotionBanner
        color="#c4f0df"
        description={
          <React.Fragment>
            After signing up through this page, you’ll receive opportunities to
            participate in projects between Evidation and{" "}
            <a
              href="https://www.blackdoctor.org"
              rel="noopener noreferrer"
              target="_blank"
            >
              BlackDoctor.org
            </a>
            , research, and other relevant programs related to your
            health.&nbsp;
          </React.Fragment>
        }
        location={location}
        logo={<Image />}
        partnerLogo={<Logo />}
        title={
          <React.Fragment>
            Evidation Collaborates with{" "}
            <a
              href="https://www.blackdoctor.org"
              rel="noopener noreferrer"
              target="_blank"
            >
              BlackDoctor.org
            </a>
          </React.Fragment>
        }
      />
    );
  }
  return null;
};

export default DoctorBanner;
