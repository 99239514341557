import { get } from "lodash";
import { rem } from "polished";
import styled, { css } from "styled-components";
import { display, space, textAlign } from "styled-system";

const color = (props) => {
  if (!props.color) {
    return css`
      color: inherit;
    `;
  }

  return css`
    color: ${get(props, `theme.palette.${props.color}`, props.color)};
  `;
};

const variant = (props) => {
  const variant = props.variant || props.as;

  switch (variant) {
    case "h1":
      return css`
        font-family: SourceSerifPro-Regular, Georgia, Times, serif;
        font-size: ${rem(30)};
        line-height: 1.3;
        font-weight: 400;
      `;
    case "h2":
      return css`
        font-family: SourceSerifPro-Regular, Georgia, Times, serif;
        font-size: ${rem(20)};
        font-weight: 400;
      `;
    case "h3":
      return css`
        font-family: PublicSans-Regular, Helvetica, Arial, Roboto, sans-serif;
        font-size: ${rem(20)};
        font-weight: 400;
      `;
    case "h4":
      return css`
        font-family: PublicSans-SemiBold, Helvetica, Arial, Roboto, sans-serif;
        font-size: ${rem(16)};
        font-weight: 500;
      `;
    case "h5":
      return css`
        font-family: PublicSans-Regular, Helvetica, Arial, Roboto, sans-serif;
        font-size: ${rem(16)};
        font-weight: 400;
      `;
    case "h6":
      return css`
        font-family: PublicSans-Regular, Helvetica, Arial, Roboto, sans-serif;
        font-size: ${rem(14)};
        font-weight: 400;
      `;
    case "caption":
      return css`
        font-size: ${rem(14)};
        line-height: ${18 / 14};
      `;
    case "link":
      return css`
        color: ${color("primary.dark")};
        font-family: PublicSans-SemiBold;
        font-size: ${rem(14)};
        font-weight: 500;
      `;
    case "subtitle":
      return css`
        font-size: ${rem(16)};
        font-family: PublicSans-Regular;
        letter-spacing: 1.2px;
        line-height: ${rem(20)};
        font-weight: 400;
      `;
    case "title":
      return css`
        font-size: ${rem(45)};
        font-family: PublicSans-Regular;
        line-height: ${rem(58)};
        font-weight: 400;
      `;
    default:
      return;
  }
};

const truncate = (props) => {
  if (props.truncate) {
    return css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `;
  }
};

const Typography = styled.p`
  ${color};
  ${display};
  ${space};
  ${textAlign};
  ${truncate};
  ${variant};
`;

export default Typography;
