import { rem } from "polished";
import styled from "styled-components";

const Footer = styled.footer`
  background-color: ${(props) => props.theme.palette.base.lightest};
  padding-bottom: ${rem(48)};
  padding-top: ${rem(48)};
`;

Footer.Description = styled.div`
  font-size: ${rem(14)};
  line-height: ${20 / 12};
`;

export default Footer;
