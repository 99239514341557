import { get } from "lodash";
import { rem } from "polished";
import styled from "styled-components";

const BORDER_WIDTH = 1;
const PADDING = 0;
const HEIGHT = 24;
const WIDTH = 54;
const PILL_WIDTH = 36;

const Toggle = styled.div`
  height: ${rem(HEIGHT)};
  width: ${rem(WIDTH)};
`;

Toggle.Label = styled.label`
  background-color: ${(props) => get(props, "theme.palette.base.light")};
  border-radius: ${rem(HEIGHT / 2)};
  border: ${BORDER_WIDTH}px solid
    ${(props) => get(props, "theme.palette.base.light")};
  cursor: pointer;
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-indent: -9999px;
  transition: background-color 0.35s ease, color 0.35s ease;
  width: 100%;

  &::before {
    align-items: center;
    background-color: ${(props) => get(props, "theme.palette.base.lightest")};
    border-radius: ${rem((HEIGHT - 2 * PADDING) / 2)};
    bottom: ${PADDING};
    content: "OFF";
    display: flex;
    font-size: ${rem(12)};
    font-family: PublicSans-SemiBold;
    justify-content: center;
    left: ${rem(PADDING)};
    position: absolute;
    text-indent: 0;
    transition: transform 0.35s ease;
    top: ${PADDING};
    width: ${rem(PILL_WIDTH)};
  }
`;

Toggle.Input = styled.input`
  display: none;

  &:checked {
    + ${Toggle.Label} {
      background-color: #37d596;

      &::before {
        content: "ON";
        transform: translateX(${rem(WIDTH - PILL_WIDTH - 2 * BORDER_WIDTH)});
      }
    }
  }

  &:disabled {
    + ${Toggle.Label} {
      background-color: ${(props) => get(props, "theme.palette.base.medium")};
      cursor: not-allowed;
    }
  }
`;

export default Toggle;
