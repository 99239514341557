import { get } from "lodash";
import { rem } from "polished";
import styled from "styled-components";

import {
  Breakpoint,
  Menu,
  getBreakpoint,
} from "../../ReactComponents/Components";

const AccountMenuButton = styled(Menu.Button)`
  align-items: center;
  display: flex;
  font-size: ${rem(16)};
  letter-spacing: 0.3px;
  width: 100%;

  @media screen and (max-width: ${getBreakpoint(Breakpoint.SMALL)}) {
    background-color: ${(props) => get(props, "theme.palette.base.lightest")};
    color: ${(props) => get(props, "theme.palette.base.dark")};
    height: ${rem(48)};
  }

  @media screen and (min-width: ${getBreakpoint(Breakpoint.SMALL)}) {
    display: inline-flex;
    font-size: ${rem(12)};
    width: auto;
  }

  &:focus {
    outline: 0;
  }

  &::after {
    border-bottom: 0;
    border-left: ${rem(4)} solid transparent;
    border-right: ${rem(4)} solid transparent;
    border-top: ${rem(4)} solid
      ${(props) => get(props, "theme.palette.base.dark")};
    content: "";
    display: block;
    height: 0;
    margin-left: ${rem(4)};
    margin-right: ${rem(2)};
    width: 0;
  }
`;

export default AccountMenuButton;
