const Logo = () => (
  <svg
    height="30"
    viewBox="0 0 160 30"
    width="160"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd" id="Symbols">
      <g id="Header-/-Landing-page-/-Home" transform="translate(-102 -21)">
        <g id="Header-/-States-/-Authenticated-Copy">
          <g id="AM_Logo" transform="translate(102 21)">
            <g
              className="wordmark"
              fill="#000"
              id="Logo-/-Wordmark-281*37"
              transform="translate(38 14)"
            >
              <g fillRule="nonzero" id="Letter-Paths">
                <path
                  d="M13.988789,15.1544896 C14.018867,15.2250905 14.011097,15.3060048 13.9681252,15.3696772 C13.9251533,15.4333496 13.8527734,15.471195 13.7756344,15.4703253 L11.5472008,15.4703253 C11.3408251,15.4695563 11.1554539,15.344707 11.0782608,15.1544896 L9.6753165,11.7111108 L4.71850491,11.7111108 L3.33493827,15.1544896 C3.25774524,15.344707 3.07237396,15.4695563 2.86599832,15.4703253 L0.656942332,15.4703253 C0.57980338,15.471195 0.507423505,15.4333496 0.464451627,15.3696772 C0.421479749,15.3060048 0.413709791,15.2250905 0.443787807,15.1544896 L6.69502869,0.807077957 C6.7676494,0.613241692 6.95590519,0.486449579 7.16396864,0.491242318 L7.28411028,0.491242318 C7.49048593,0.492011246 7.67585721,0.616860613 7.75305024,0.807077957 L13.988789,15.1544896 Z M5.60987838,9.53492623 L8.83432501,9.53492623 L7.24147938,5.56001916 L5.60987838,9.53492623 Z"
                  id="A"
                />
                <path
                  d="M24.1620731,13.3788771 C24.2310411,13.439496 24.2724178,13.5252527 24.2767972,13.6166521 C24.2811765,13.7080515 24.2481863,13.7973277 24.1853263,13.8641855 C23.1798726,14.9958871 21.7279885,15.635786 20.2090256,15.6166881 C16.1280853,15.6166881 14.8491581,12.4467768 14.8491581,10.2243723 C14.8491581,7.87871495 15.9343085,4.72806187 20.1663947,4.72806187 C21.639408,4.72784788 23.0450984,5.34114315 24.0419315,6.41893803 C24.1689947,6.55606335 24.1689947,6.76712114 24.0419315,6.90424646 L23.0652962,8.00196789 C23.0084247,8.07043724 22.9251821,8.11195489 22.8359568,8.11635202 C22.7467315,8.12074915 22.6597644,8.08761964 22.5963562,8.02507781 C21.9350289,7.41315456 21.0767342,7.05420983 20.1741457,7.01209277 C18.3681456,7.01209277 17.3682571,8.59512262 17.3682571,10.2243723 C17.3682571,12.2118259 18.7285705,13.2479208 20.197399,13.2479208 C21.0427001,13.2174257 21.8493408,12.8882903 22.4723391,12.3196722 C22.618643,12.1919337 22.837606,12.1919337 22.9839099,12.3196722 L24.1620731,13.3788771 Z"
                  id="c"
                />
                <path
                  d="M26.7393051,15.4703253 C26.6485361,15.4713682 26.5611783,15.435993 26.4969906,15.3722009 C26.432803,15.3084088 26.3972084,15.2215893 26.3982579,15.1313797 L26.3982579,1.00351232 C26.3972084,0.913302667 26.432803,0.826483212 26.4969906,0.762691093 C26.5611783,0.698898974 26.6485361,0.663523763 26.7393051,0.664566754 L28.5685585,0.664566754 C28.6593275,0.663523763 28.7466853,0.698898974 28.8108729,0.762691093 C28.8750606,0.826483212 28.9106552,0.913302667 28.9096057,1.00351232 L28.9096057,5.52535428 C29.5918276,5.00091777 30.4305795,4.71763505 31.2930609,4.72035856 C35.3546234,4.72035856 35.8003102,7.48969789 35.8003102,10.004828 L35.8003102,15.1198247 C35.8013596,15.2100344 35.7657651,15.2968538 35.7015774,15.360646 C35.6373897,15.4244381 35.5500319,15.4598133 35.4592629,15.4587703 L33.6377606,15.4587703 C33.5469916,15.4598133 33.4596338,15.4244381 33.3954462,15.360646 C33.3312585,15.2968538 33.2956639,15.2100344 33.2967134,15.1198247 L33.2967134,10.0202347 C33.2967134,8.51808954 33.0835589,6.86958157 30.9132582,6.91194976 C30.1237008,6.94046109 29.389108,7.3210575 28.9134812,7.94804473 L28.9134812,15.1313797 C28.9145307,15.2215893 28.8789361,15.3084088 28.8147485,15.3722009 C28.7505608,15.435993 28.663203,15.4713682 28.572434,15.4703253 L26.7393051,15.4703253 Z"
                  id="h"
                />
                <path
                  d="M39.5905852,0.148445099 C39.9749263,0.145335262 40.3444349,0.295699122 40.6162141,0.56580352 C40.8879933,0.835907919 41.0392895,1.20313945 41.0361604,1.58511209 C41.0361604,2.37856136 40.3889543,3.02177909 39.5905852,3.02177909 C38.792216,3.02177909 38.1450099,2.37856136 38.1450099,1.58511209 C38.1450099,0.791662822 38.792216,0.148445099 39.5905852,0.148445099 Z M40.8462591,15.1313797 C40.8473086,15.2215893 40.811714,15.3084088 40.7475263,15.3722009 C40.6833387,15.435993 40.5959809,15.4713682 40.5052119,15.4703253 L38.6565808,15.4703253 C38.5658118,15.4713682 38.478454,15.435993 38.4142663,15.3722009 C38.3500787,15.3084088 38.3144841,15.2215893 38.3155336,15.1313797 L38.3155336,5.24033187 C38.3144841,5.15012222 38.3500787,5.06330276 38.4142663,4.99951064 C38.478454,4.93571852 38.5658118,4.90034331 38.6565808,4.9013863 L40.5052119,4.9013863 C40.5959809,4.90034331 40.6833387,4.93571852 40.7475263,4.99951064 C40.811714,5.06330276 40.8473086,5.15012222 40.8462591,5.24033187 L40.8462591,15.1313797 Z"
                  id="i"
                />
                <path
                  d="M51.9496721,13.8449272 C52.0606779,13.9977204 52.0335718,14.2098655 51.8876635,14.3302356 C50.8553788,15.1658777 49.5604358,15.6144061 48.2291567,15.5974298 C44.1482165,15.5974298 42.9119202,12.4506284 42.9119202,10.1665975 C42.9119202,7.94804473 44.1443409,4.77428172 48.2291567,4.77428172 C51.0156677,4.77428172 52.5658824,6.54989428 52.5658824,9.2768654 C52.5675575,9.71551306 52.5325572,10.1535407 52.4612429,10.5864278 C52.4375931,10.7809229 52.270809,10.9267884 52.0736893,10.9253734 L45.4852767,10.9253734 C45.6558003,11.9614683 46.260384,13.3981353 48.2291567,13.3981353 C49.0944891,13.4020092 49.9429287,13.1601372 50.6746205,12.7009859 C50.8300982,12.6078222 51.0310302,12.6441301 51.1435604,12.7857223 L51.9496721,13.8449272 Z M45.5899162,8.92251322 L50.1630496,8.92251322 C50.2056805,8.03663277 49.7367406,6.99668616 48.2252812,6.91580142 C46.5045428,6.93505969 45.8650793,7.90567653 45.5899162,8.91866157 L45.5899162,8.92251322 Z"
                  id="e"
                />
                <path
                  d="M63.0918404,4.90523796 C63.1656283,4.90998456 63.2323479,4.95049317 63.2702771,5.01357524 C63.3082062,5.07665731 63.312493,5.15424344 63.2817417,5.2210736 L58.6892306,15.3855889 C58.6218894,15.5389486 58.4700368,15.6385537 58.3016769,15.639798 C58.1327043,15.6405767 57.9798337,15.5403038 57.9141232,15.3855889 L53.3797452,5.24033187 C53.3496671,5.16973094 53.3574371,5.08881664 53.400409,5.02514429 C53.4433809,4.96147193 53.5157607,4.9236265 53.5928997,4.92449623 L55.6779385,4.92449623 C55.8843141,4.92526516 56.0696854,5.05011453 56.1468784,5.24033187 L58.35981,10.6095377 L60.5456127,5.24033187 C60.6228058,5.05011453 60.808177,4.92526516 61.0145527,4.92449623 L63.0918404,4.90523796 Z"
                  id="v"
                />
                <path
                  d="M72.9357039,13.8449272 C73.0467098,13.9977204 73.0196037,14.2098655 72.8736953,14.3302356 C71.8414107,15.1658777 70.5464677,15.6144061 69.2151886,15.5974298 C65.1342483,15.5974298 63.8979521,12.4506284 63.8979521,10.1665975 C63.8979521,7.94804473 65.1303728,4.77428172 69.2151886,4.77428172 C72.0016996,4.77428172 73.5519143,6.54989428 73.5519143,9.2768654 C73.5535893,9.71551306 73.5185891,10.1535407 73.4472748,10.5864278 C73.4236249,10.7809229 73.2568409,10.9267884 73.0597211,10.9253734 L66.4713085,10.9253734 C66.6418321,11.9614683 67.2464159,13.3981353 69.2151886,13.3981353 C70.080521,13.4020092 70.9289605,13.1601372 71.6606523,12.7009859 C71.8161301,12.6078222 72.0170621,12.6441301 72.1295923,12.7857223 L72.9357039,13.8449272 Z M66.5798235,8.91866157 L71.152957,8.91866157 C71.1955879,8.03278112 70.7266479,6.9928345 69.2151886,6.91194976 C67.4944502,6.93505969 66.8549867,7.90567653 66.5798235,8.91866157 Z"
                  id="e"
                />
                <path
                  d="M78.2490649,15.1313797 C78.2501144,15.2215893 78.2145198,15.3084088 78.1503321,15.3722009 C78.0861445,15.435993 77.9987867,15.4713682 77.9080177,15.4703253 L76.0787643,15.4703253 C75.9879953,15.4713682 75.9006375,15.435993 75.8364498,15.3722009 C75.7722621,15.3084088 75.7366676,15.2215893 75.737717,15.1313797 L75.737717,5.24033187 C75.7366676,5.15012222 75.7722621,5.06330276 75.8364498,4.99951064 C75.9006375,4.93571852 75.9879953,4.90034331 76.0787643,4.9013863 L77.0437729,4.9013863 C77.2459351,4.89818922 77.4266202,5.02623156 77.4894597,5.21722194 L77.679361,5.72564029 C78.5197359,5.08094696 79.5515259,4.73146035 80.6131424,4.73191352 C82.2486189,4.73191352 83.2291297,5.19796367 83.8453401,5.91437134 L84.0778723,5.72564029 C84.9182472,5.08094696 85.9500372,4.73146035 87.0116536,4.73191352 C91.0112076,4.73191352 91.158478,7.54362104 91.158478,10.016383 L91.158478,15.1313797 C91.1595275,15.2215893 91.1239329,15.3084088 91.0597453,15.3722009 C90.9955576,15.435993 90.9081998,15.4713682 90.8174308,15.4703253 L88.9455465,15.4703253 C88.8547775,15.4713682 88.7674197,15.435993 88.703232,15.3722009 C88.6390444,15.3084088 88.6034498,15.2215893 88.6044993,15.1313797 L88.6044993,10.0202347 C88.6044993,8.71067225 88.7750229,6.91194976 86.9031386,6.91194976 C86.0133984,6.91000117 85.1792514,7.34177545 84.6708294,8.06744601 C84.7421635,8.71969069 84.7706445,9.37586282 84.7560912,10.0317896 L84.7560912,15.1467863 C84.7571407,15.236996 84.7215461,15.3238154 84.6573584,15.3876075 C84.5931708,15.4513997 84.505813,15.4867749 84.415044,15.4857319 L82.5431597,15.4857319 C82.4523907,15.4867749 82.3650329,15.4513997 82.3008452,15.3876075 C82.2366576,15.3238154 82.201063,15.236996 82.2021124,15.1467863 L82.2021124,10.0202347 C82.2021124,8.71067225 82.3726361,6.91194976 80.5007518,6.91194976 C79.6028089,6.90236774 78.7582774,7.33501061 78.2451894,8.06744601 L78.2490649,15.1313797 Z"
                  id="m"
                />
                <path
                  d="M102.091367,13.8449272 C102.202373,13.9977204 102.175267,14.2098655 102.029359,14.3302356 C100.997074,15.1658777 99.7021312,15.6144061 98.370852,15.5974298 C94.2899118,15.5974298 93.0536155,12.4506284 93.0536155,10.1665975 C93.0536155,7.94804473 94.2860362,4.77428172 98.370852,4.77428172 C101.157363,4.77428172 102.707578,6.54989428 102.707578,9.2768654 C102.709253,9.71551306 102.674253,10.1535407 102.602938,10.5864278 C102.579288,10.7809229 102.412504,10.9267884 102.215385,10.9253734 L95.6463497,10.9253734 C95.8168733,11.9614683 96.421457,13.3981353 98.3902297,13.3981353 C99.2555621,13.4020092 100.104002,13.1601372 100.835693,12.7009859 C100.991171,12.6078222 101.192103,12.6441301 101.304633,12.7857223 L102.091367,13.8449272 Z M95.7316115,8.92251322 L100.296994,8.92251322 C100.339625,8.03663277 99.8706848,6.99668616 98.3592254,6.91580142 C96.6501137,6.93505969 96.0106501,7.90567653 95.735487,8.91866157 L95.7316115,8.92251322 Z"
                  id="e"
                />
                <path
                  d="M107.404728,15.1313797 C107.405778,15.2215893 107.370183,15.3084088 107.305996,15.3722009 C107.241808,15.435993 107.15445,15.4713682 107.063681,15.4703253 L105.234428,15.4703253 C105.143659,15.4713682 105.056301,15.435993 104.992113,15.3722009 C104.927926,15.3084088 104.892331,15.2215893 104.89338,15.1313797 L104.89338,5.24033187 C104.892331,5.15012222 104.927926,5.06330276 104.992113,4.99951064 C105.056301,4.93571852 105.143659,4.90034331 105.234428,4.9013863 L106.191685,4.9013863 C106.393848,4.89818922 106.574533,5.02623156 106.637372,5.21722194 L106.827273,5.72564029 C107.667648,5.08094696 108.699438,4.73146035 109.761055,4.73191352 C113.760609,4.73191352 114.291557,7.54362104 114.291557,10.016383 L114.291557,15.1313797 C114.292607,15.2215893 114.257012,15.3084088 114.192825,15.3722009 C114.128637,15.435993 114.041279,15.4713682 113.95051,15.4703253 L112.078626,15.4703253 C111.987857,15.4713682 111.900499,15.435993 111.836311,15.3722009 C111.772124,15.3084088 111.736529,15.2215893 111.737579,15.1313797 L111.737579,10.0202347 C111.737579,8.71067225 111.524424,6.91194976 109.65254,6.91194976 C108.754597,6.90236774 107.910065,7.33501061 107.396977,8.06744601 L107.404728,15.1313797 Z"
                  id="n"
                />
                <path
                  d="M115.876652,7.05831262 C115.785883,7.05935561 115.698525,7.0239804 115.634337,6.96018828 C115.57015,6.89639616 115.534555,6.80957671 115.535605,6.71936706 L115.535605,5.24033187 C115.534555,5.15012222 115.57015,5.06330276 115.634337,4.99951064 C115.698525,4.93571852 115.785883,4.90034331 115.876652,4.9013863 L116.682763,4.9013863 L116.853287,3.14888367 C116.876937,2.95438859 117.043721,2.80852306 117.240841,2.80993811 L118.876317,2.80993811 C118.967086,2.80889512 119.054444,2.84427033 119.118632,2.90806245 C119.182819,2.97185457 119.218414,3.05867402 119.217365,3.14888367 L119.217365,4.9013863 L120.961356,4.9013863 C121.052125,4.90034331 121.139483,4.93571852 121.203671,4.99951064 C121.267858,5.06330276 121.303453,5.15012222 121.302403,5.24033187 L121.302403,6.71936706 C121.303453,6.80957671 121.267858,6.89639616 121.203671,6.96018828 C121.139483,7.0239804 121.052125,7.05935561 120.961356,7.05831262 L119.217365,7.05831262 L119.217365,12.5122549 C119.217365,13.0399315 119.279373,13.5252399 119.992472,13.4828717 C120.182373,13.4636135 120.566051,13.3788771 121.155133,13.2517725 C121.240518,13.2336259 121.329659,13.2503289 121.402534,13.2981296 C121.475408,13.3459304 121.525907,13.4208216 121.542687,13.5059816 L121.81785,14.7962858 C121.858632,14.9886238 121.735839,15.1776932 121.542687,15.2199677 C120.741211,15.4534003 119.912729,15.5828621 119.077845,15.6051332 C116.888167,15.6051332 116.675012,13.5753114 116.675012,12.8589038 L116.675012,7.08142255 L115.876652,7.05831262 Z"
                  id="t"
                />
              </g>
            </g>
            <path
              d="M29.9723071,29.6234342 L15.2584596,0.161606662 C15.2097745,0.0626209009 15.1096238,-1.65170626e-16 14.9999993,0 C14.8903748,1.65170626e-16 14.7902241,0.0626209009 14.7415389,0.161606662 L0.0276914761,29.6234342 C-0.0127056269,29.7039732 -0.00868297365,29.7998796 0.0383141493,29.8766956 C0.0853112722,29.9535116 0.168533187,30.000205 0.258079351,29.9999993 L29.7438553,29.9999993 C29.8330567,29.9995288 29.9156621,29.9525646 29.9621928,29.8758664 C30.0087235,29.7991683 30.0125501,29.7036636 29.9723071,29.6234342 Z M18.8390495,23 L11.1668003,23 C11.1088885,22.9995972 11.0552678,22.9691704 11.0249157,22.9194882 C10.9945635,22.869806 10.9917619,22.8078778 11.0175025,22.7556209 L14.8557007,15.092143 C14.883845,15.0356574 14.9412189,15 15.0039617,15 C15.0667046,15 15.1240785,15.0356574 15.1522228,15.092143 L18.9862738,22.7639757 C19.0081619,22.8151108 19.0036681,22.8738207 18.9742569,22.9209716 C18.9448456,22.9681226 18.8942837,22.9976759 18.8390495,23 L18.8390495,23 Z"
              fill="#2A3F53"
              fillRule="nonzero"
              id="Symbol"
              transform="matrix(-1 0 0 1 30 0)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Logo.defaultProps = {};

export default Logo;
