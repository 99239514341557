import trackingService, { Events } from "../../services/trackingService";
import { EvidationLogo } from "../ReactComponents/Core";

const FooterLogo = () => (
  <a
    href="https://www.evidation.com"
    onClick={() =>
      trackingService.trackEvent(Events.FOOTER_CLICK, {
        footer_text: "Evidation",
      })
    }
  >
    <EvidationLogo height={30} width={152} />
  </a>
);

export default FooterLogo;
