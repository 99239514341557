import PropTypes from "prop-types";
import React from "react";

import PointsBadge from "./PointsBadge";

const PointsBadgeComponent = ({ color, children }) => (
  <PointsBadge>
    <PointsBadge.Content color={color}>
      <PointsBadge.Text>{children}</PointsBadge.Text>
    </PointsBadge.Content>
  </PointsBadge>
);

PointsBadgeComponent.propTypes = {
  color: PropTypes.string,
};

PointsBadgeComponent.defaultProps = {
  color: "#32C58A",
};

export { PointsBadge };

export default PointsBadgeComponent;
