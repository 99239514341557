import { addMonths, format, isToday } from "date-fns";
import { get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Controls from "../../components/Controls";
import PointHubButton from "../../components/PointHubButton";
import { selectEventAggregate } from "../../modules/eventAggregate/selectors";
import trackingService, { Events } from "../../services/trackingService";
import { getMonthRange } from "../../utils/date";

const formatMonth = (eventAggregate) => {
  if (!eventAggregate || !eventAggregate.end_date) {
    return "";
  }

  return format(eventAggregate.end_date, "MMM YYYY");
};

const MonthlyPointSlider = ({ activeDateRange, setActiveDateRange }) => {
  const [dateRange, setDateRange] = useState(getMonthRange(new Date()));
  const eventAggregate = useSelector(
    selectEventAggregate({
      period: dateRange.period,
    })
  );

  const updateDateRange = (offset) => {
    const updatedDateRange = getMonthRange(
      addMonths(dateRange.startDate, offset)
    );
    setDateRange(updatedDateRange);
    setActiveDateRange(updatedDateRange);
  };

  useEffect(() => {
    if (dateRange.period === activeDateRange.period) {
      setActiveDateRange(dateRange);
    }
  }, [dateRange]);

  return (
    <Controls
      disableNext={isToday(dateRange.endDate)}
      onNavigateNext={() => {
        updateDateRange(1);
        trackingService.trackEvent(Events.POINTS_NEXT, {
          points_period: "month",
        });
      }}
      onNavigatePrevious={() => {
        updateDateRange(-1);
        trackingService.trackEvent(Events.POINTS_PREVIOUS, {
          points_period: "month",
        });
      }}
    >
      <PointHubButton
        active={dateRange.period === activeDateRange.period}
        date={formatMonth(eventAggregate)}
        label="Month"
        loading={isEmpty(eventAggregate)}
        onClick={() => {
          setActiveDateRange(dateRange);
          trackingService.trackEvent(Events.POINTS_CLICK, {
            points_period: "month",
          });
        }}
        value={get(eventAggregate, "points")}
      />
    </Controls>
  );
};

MonthlyPointSlider.propTypes = {
  activeDateRange: PropTypes.shape({
    endDate: PropTypes.instanceOf(Date),
    period: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
  }),
  setActiveDateRange: PropTypes.func,
};

export default MonthlyPointSlider;
