/**
 * Returns a phone number containing only numbers
 * @param {string} value A field value provided by Formik
 * @return {number}
 */
export const toNumericPhoneNumber = (value) =>
  value ? value.replace(/\D/g, "").slice(0, 10) : "";

/**
 * Returns a formatted seven digit phone number
 * @param {number} phoneNumber A numeric phone number
 * @return {string}
 */
const toSevenDigitPhoneNumber = (phoneNumber) =>
  phoneNumber.replace(/(\d{3})(\d{1,4})/, "$1-$2");

/**
 * Returns a formatted ten digit phone number
 * @param {number} phoneNumber A numeric phone number
 * @return {string}
 */
const toTenDigitPhoneNumber = (phoneNumber) =>
  phoneNumber.replace(/(\d{3})(\d{3})(\d{1,4})/, "($1) $2-$3");

/**
 * Returns a formatted phone number based on the number of digits entered
 * @param {string} value A field value provided by Formik
 * @return {string}
 */
export const toPhoneNumber = (value) => {
  const phoneNumber = toNumericPhoneNumber(value);
  if (phoneNumber.length <= 7) {
    return toSevenDigitPhoneNumber(phoneNumber);
  }
  return toTenDigitPhoneNumber(phoneNumber);
};
