import { useNavigate } from "react-router-dom";

import { Button, ButtonColor, Grid } from "../ReactComponents/Components";
import Image from "./Image.svg";

const Error = () => {
  const navigate = useNavigate();
  return (
    <Grid px={[3, 3, 3, 0]} py={[5, 5, 5, 6]}>
      <Grid.Flex>
        <Grid.Box flex={[1, 1, 2 / 3]}>
          <h1>
            Oh no...
            <br />
            An error has occurred and we are working to fix the problem!
          </h1>
          <p>
            If you need help from our customer service team, please contact us.
            You can also visit our{" "}
            <a href="https://help.evidation.com">Help Center</a> for additional
            information or click the button below to return to the home page.
            Thanks for your patience!
          </p>
          <Button
            color={ButtonColor.PRIMARY}
            onClick={() => navigate("/")}
            uppercase
          >
            Back to home
          </Button>
        </Grid.Box>
      </Grid.Flex>
      <Grid.Flex>
        <img alt="" src={Image} />
      </Grid.Flex>
    </Grid>
  );
};

export default Error;
