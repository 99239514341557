import PropTypes from "prop-types";

import Card, { CardContent, CardFooter } from "../Card";
import CardActionBar from "../CardActionBar";
import CardMedia from "../CardMedia";
import HeaderImage from "./HeaderImage.svg";

const ActionCardComponent = ({
  actions,
  body,
  footer,
  image,
  title,
  statBlock,
}) => (
  <Card height="100%">
    <CardMedia image={image} />
    <CardContent>
      <CardContent.Title>{title}</CardContent.Title>
      <CardContent.Body>{body}</CardContent.Body>
    </CardContent>
    {statBlock && <CardFooter>{statBlock}</CardFooter>}
    {footer && <CardFooter>{footer}</CardFooter>}
    {actions && <CardActionBar actions={actions} />}
  </Card>
);

ActionCardComponent.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      children: PropTypes.string,
      onClick: PropTypes.func,
      href: PropTypes.string,
    })
  ),
  body: PropTypes.node.isRequired,
  image: PropTypes.string,
  footer: PropTypes.node,
  title: PropTypes.string.isRequired,
  statBlock: PropTypes.node,
};

ActionCardComponent.defaultProps = {
  image: HeaderImage,
};

export default ActionCardComponent;
