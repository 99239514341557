import styled from "styled-components";

const PieChart = styled.svg`
  border-radius: 50%;
  transform: rotate(-90deg);
`;

PieChart.Slice = styled.circle``;

export default PieChart;
