import { rem } from "polished";
import styled from "styled-components";

import { Menu } from "../ReactComponents/Components";
import Icon from "./Icon.svg";

const HeaderMenuButton = styled(Menu.Button)`
  background-image: url(${Icon});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: ${rem(24)};
  height: ${rem(44)};
  width: ${rem(44)};
`;

export default HeaderMenuButton;
