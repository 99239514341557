import PropTypes from "prop-types";
import React from "react";

import Left from "./assets/Left.svg";
import Right from "./assets/Right.svg";
import Controls from "./Controls";

const ControlsComponent = ({
  children,
  disableNext,
  disablePrevious,
  onNavigateNext,
  onNavigatePrevious,
  ...props
}) => {
  return (
    <Controls {...props}>
      <Controls.Control
        disabled={disablePrevious}
        icon={Left}
        onClick={onNavigatePrevious}
      ></Controls.Control>
      <Controls.Content>{children}</Controls.Content>
      <Controls.Control
        disabled={disableNext}
        icon={Right}
        onClick={onNavigateNext}
      ></Controls.Control>
    </Controls>
  );
};

ControlsComponent.propTypes = {
  children: PropTypes.node.isRequired,
  disableNext: PropTypes.bool,
  disablePrevious: PropTypes.bool,
  onNavigateNext: PropTypes.func.isRequired,
  onNavigatePrevious: PropTypes.func.isRequired,
};

ControlsComponent.defaultProps = {
  disableNext: false,
  disablePrevious: false,
};

export { Controls };

export default ControlsComponent;
