import { rem } from "polished";
import styled from "styled-components";

const PointsBadgeContainer = styled.div`
  top: 0;
  left: ${rem(24)};
  position: absolute;
  transform: translateY(-50%);
`;

export { PointsBadgeContainer };
