import { get } from "lodash";
import { rem } from "polished";
import styled, { css } from "styled-components";

import Icon from "./Icon.svg";

const Search = styled.input`
  background-color: #fff;
  background-image: url("${Icon}");
  background-position: ${rem(20)} center;
  background-repeat: no-repeat;
  background-size: ${rem(24)};
  border: 0;
  border-radius: ${rem(4)};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  color: ${(props) => get(props, "theme.palette.base.medium")};
  flex: 1;
  font-size: ${rem(16)};
  line-height: ${24 / 16};
  padding: ${rem(18)} ${rem(56)} ${rem(16)};
  width: 100%;

  ${(props) => css`
    &::placeholder {
      color: ${get(props, "theme.palette.base.light")};
    }
  `};
`;

export default Search;
