import React from "react";
import { Navigate } from "react-router-dom";

import {
  AuthState,
  getAccessToken,
  useAuthState,
} from "@achievement/portal-client";

const AuthenticatedGate: React.FunctionComponent<any> = ({ children }) => {
  const { authState, setAuthState } = useAuthState();

  React.useEffect(() => {
    const getAuthState = async () => {
      const accessToken = await getAccessToken();
      setAuthState(
        !!accessToken ? AuthState.AUTHENTICATED : AuthState.UNAUTHENTICATED
      );
    };
    if (authState === AuthState.IDLE) {
      getAuthState();
    }
  }, [authState, setAuthState]);

  if (authState === AuthState.UNAUTHENTICATED) {
    return <Navigate replace to="/login" />;
  }

  if (authState === AuthState.AUTHENTICATED) {
    return children;
  }

  return null;
};

export default AuthenticatedGate;
