import PropTypes from "prop-types";
import React from "react";
import { ThemeProvider } from "styled-components";
import { space } from "styled-system";

import Panel from "./Panel";
import PanelColor, { panelTheme } from "./PanelColor";

/**
 * @render react
 * @name Panel
 * @description Achievement Panel Component
 * @example
 * <Panel>
 *   <h4>Lorem ipsum</h4>
 *   <p>Lorem ipsum dolar sit amet</p>
 * </Panel>
 */

const PanelComponent = ({ children, color, ...props }) => (
  <ThemeProvider theme={{ panel: panelTheme[color] }}>
    <Panel {...props}>{children}</Panel>
  </ThemeProvider>
);

PanelComponent.propTypes = {
  color: PropTypes.string,
  ...space.propTypes,
};

PanelComponent.defaultProps = {
  color: PanelColor.LIGHT,
};

export { Panel, PanelColor };

export default PanelComponent;
