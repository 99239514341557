import React from "react";
import { Linking, useWindowDimensions } from "react-native";
import RNRH, {
  RenderersProps,
  RenderHTMLProps,
} from "react-native-render-html";

import { htmlBaseStyle, htmlTagStyles, systemFonts } from "./styles";

const renderersProps: Partial<RenderersProps> = {
  a: {
    onPress: (_event, href) => {
      Linking.openURL(href);
    },
  },
};

const HTML: React.FunctionComponent<RenderHTMLProps> = ({
  tagsStyles,
  ...props
}) => {
  const { width } = useWindowDimensions();
  return (
    <RNRH
      baseStyle={htmlBaseStyle}
      contentWidth={width}
      renderersProps={renderersProps}
      systemFonts={systemFonts}
      tagsStyles={{ ...htmlTagStyles, ...tagsStyles }}
      {...props}
    />
  );
};

export default HTML;
