import { createSelector } from "@reduxjs/toolkit";
import { isBefore } from "date-fns";

import { getInitialPaginatedState } from "../utils";

const selectViews = (state) => state.events.views;
const selectKey = (_, key) => key || "feed";
const selectEntities = (state) => state.events.entities;

const selectView = createSelector(
  selectViews,
  selectKey,
  (views, key) => views[key] || getInitialPaginatedState()
);

const mapEntities = (keys, entities) => {
  return keys
    .map((key) => entities[key])
    .sort((a, b) => (isBefore(b.createdAt, a.createdAt) ? -1 : 1));
};

export const selectEvents = createSelector(
  selectView,
  selectEntities,
  (view, entities) => ({
    entities: mapEntities(view.keys, entities),
    error: view.error,
    pageInfo: view.pageInfo,
    status: view.status,
    updatedAt: view.updatedAt,
  })
);
