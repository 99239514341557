import get from "lodash/get";
import { rem } from "polished";
import styled, { css } from "styled-components";

const getColor = (color) => (props) =>
  get(props, `theme.palette.${color}`, "#eb3f54");

const theme = {
  height: rem(48),
};

const NavigationBar = styled.nav`
  background-color: ${getColor("base.lightest")};
  font-size: ${rem(12)};
  font-family: PublicSans-SemiBold;
  letter-spacing: 0.3px;
  text-align: center;
  text-transform: uppercase;
`;

NavigationBar.List = styled.ul`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
`;

NavigationBar.ListItem = styled.li`
  & + & {
    margin-left: ${rem(32)};
  }
`;

NavigationBar.Link = styled.a`
  align-items: center;
  border-bottom: 2px solid transparent;
  color: ${getColor("base.medium")};
  display: flex;
  height: ${theme.height};
  padding-top: 2px;
  text-decoration: none;
  transition: 0.35s ease color;

  svg {
    fill: ${getColor("base.medium")};
    max-height: ${rem(20)};
    margin-right: ${rem(8)};
    stroke: ${getColor("base.medium")};
    width: ${rem(20)};
  }

  &:hover {
    color: ${getColor("primary.dark")};

    svg {
      fill: ${getColor("primary.dark")};
      stroke: ${getColor("primary.dark")};
    }
  }

  ${(props) =>
    props.active &&
    css`
      border-bottom-color: ${getColor("primary.dark")};
      color: ${getColor("primary.dark")};

      svg {
        fill: ${getColor("primary.dark")};
        stroke: ${getColor("primary.dark")};
      }
    `}
`;

export default NavigationBar;
