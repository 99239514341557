import { StyleSheet } from "react-native";

import { ButtonSizeStyles, ButtonStyles, ButtonVariantStyles } from "./types";

const buttonStyles = StyleSheet.create<ButtonStyles>({
  container: {
    alignItems: "center",
    borderRadius: 45,
    justifyContent: "center",
    flexDirection: "row",
    width: "100%",
  },
  text: {
    fontFamily: "PublicSans-Regular",
    textAlign: "center",
    fontWeight: "500",
  },
  uppercase: {
    textTransform: "uppercase",
  },
});

const buttonSizeStyles: ButtonSizeStyles = {
  s: {
    container: {
      padding: 12,
    },
    text: {
      fontSize: 14,
    },
  },
  m: {
    container: {
      paddingHorizontal: 18,
      paddingVertical: 15,
    },
    text: {
      fontSize: 16,
    },
  },
  l: {
    container: {
      paddingHorizontal: 18,
      paddingVertical: 15,
    },
    text: {
      fontSize: 18,
    },
  },
};

const buttonVariantStyles: ButtonVariantStyles = {
  "primary-rounded": {
    container: {
      backgroundColor: "#0F5FBC",
      borderColor: "#0F5FBC",
      borderRadius: 45,
      borderWidth: 2,
    },
    text: {
      color: "#FFFFFF",
      fontSize: 18,
    },
  },
  "primary": {
    container: {
      backgroundColor: "#0F5FBC",
      borderColor: "#0F5FBC",
      borderRadius: 0,
      borderWidth: 2,
    },
    text: {
      color: "#FFFFFF",
      fontSize: 18,
    },
  },
  "secondary-rounded": {
    container: {
      backgroundColor: "#FFFFFF",
      borderColor: "#0F5FBC",
      borderRadius: 45,
      borderWidth: 2,
    },
    text: {
      color: "#0F5FBC",
      fontSize: 18,
    },
  },
  "secondary": {
    container: {
      backgroundColor: "#FFFFFF",
      borderColor: "#0F5FBC",
      borderRadius: 0,
      borderWidth: 2,
    },
    text: {
      color: "#0F5FBC",
      fontSize: 18,
    },
  },
  "text": {
    container: {
      backgroundColor: "none",
      paddingHorizontal: 0,
      paddingVertical: 0,
    },
    text: {
      color: "#0F5FBC",
    },
  },
};

const disabledButtonStyle = StyleSheet.create<ButtonStyles>({
  container: {
    backgroundColor: "#E4EAEE",
    borderColor: "#E4EAEE",
  },
  text: {
    color: "#2A3F53",
  },
});

export {
  buttonStyles,
  buttonSizeStyles,
  buttonVariantStyles,
  disabledButtonStyle,
};

export default buttonStyles;
