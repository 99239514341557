import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import FieldError from "../FieldError";
import Grid from "../Grid";
import Label from "../Label";
import { Select } from "../Select";
import { getHeight, toCentemeters } from "./utils";

class Height extends React.PureComponent {
  handleChangeFeet = (event) => {
    const value = this.props.field.value === "" ? 0 : this.props.field.value;
    const { inches } = getHeight(value);

    this.props.form.setFieldValue(
      this.props.field.name,
      toCentemeters(event.target.value, inches)
    );
  };

  handleChangeInches = (event) => {
    const value = this.props.field.value === "" ? 0 : this.props.field.value;
    const { feet } = getHeight(value);

    this.props.form.setFieldValue(
      this.props.field.name,
      toCentemeters(feet, event.target.value)
    );
  };

  render() {
    const {
      field: { name, value, ...field },
      form: { errors = {}, touched = {} },
      label,
      ...props
    } = this.props;

    const hasError = touched[name] && !isEmpty(errors[name]);

    const { feet, inches } = getHeight(value);

    return (
      <React.Fragment>
        {hasError && <FieldError>{errors[name]}</FieldError>}
        <Grid.Flex>
          <Grid.Box flex={1} mr={2}>
            <Select
              {...field}
              {...props}
              data-testid={`${name}.feet`}
              hasError={hasError}
              name={`${name}.feet`}
              onChange={this.handleChangeFeet}
              value={feet}
            >
              <option disabled />
              <option value={3}>3&#39;</option>
              <option value={4}>4&#39;</option>
              <option value={5}>5&#39;</option>
              <option value={6}>6&#39;</option>
              <option value={7}>7&#39;</option>
            </Select>
          </Grid.Box>
          <Grid.Box flex={1} ml={2}>
            <Select
              {...field}
              {...props}
              data-testid={`${name}.inches`}
              hasError={hasError}
              name={`${name}.inches`}
              onChange={this.handleChangeInches}
              value={inches}
            >
              <option disabled />
              <option value={0}>0&#34;</option>
              <option value={1}>1&#34;</option>
              <option value={2}>2&#34;</option>
              <option value={3}>3&#34;</option>
              <option value={4}>4&#34;</option>
              <option value={5}>5&#34;</option>
              <option value={6}>6&#34;</option>
              <option value={7}>7&#34;</option>
              <option value={8}>8&#34;</option>
              <option value={9}>9&#34;</option>
              <option value={10}>10&#34;</option>
              <option value={11}>11&#34;</option>
            </Select>
          </Grid.Box>
        </Grid.Flex>
        <Label hasError={hasError}>{label}</Label>
      </React.Fragment>
    );
  }
}

Height.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
};

export default Height;
