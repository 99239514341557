import { get } from "lodash";
import { rem } from "polished";
import styled from "styled-components";

const PointHubStatistic = styled.div`
  position: relative;
`;

PointHubStatistic.Tooltip = styled.div`
  background-color: ${(props) => get(props, "theme.palette.base.medium")};
  border-radius: ${rem(4)};
  color: ${(props) => get(props, "theme.palette.base.lightest")};
  display: none;
  left: 50%;
  line-height: 1;
  padding: ${rem(5)} ${rem(8)} ${rem(4)};
  position: absolute;
  top: ${rem(-4)};
  transform: translateX(-50%) translateY(-100%);

  &::before {
    border-left: ${rem(4)} solid transparent;
    border-right: ${rem(4)} solid transparent;
    border-top: ${rem(4)} solid
      ${(props) => get(props, "theme.palette.base.medium")};
    bottom: 0;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%) translateY(100%);
    width: 0;
  }
`;

PointHubStatistic.Button = styled.button`
  display: block;

  &:focus {
    outline: 0;
  }

  &:focus,
  &:hover {
    + ${PointHubStatistic.Tooltip} {
      display: block;
    }
  }
`;

PointHubStatistic.Content = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export default PointHubStatistic;
