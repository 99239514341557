import PropTypes from "prop-types";

import PieChart from "../PieChart";
import Space from "../Space";
import CardActionResult from "./CardActionResult";

const CardActionResultComponent = ({ result }) => {
  const [first, second] = result;

  return (
    <CardActionResult>
      <CardActionResult.Statistic>
        <Space component="h3" mb={1}>
          {first.value}%
        </Space>
        {first.children}
      </CardActionResult.Statistic>
      <CardActionResult.Chart>
        <PieChart value={second.value} />
      </CardActionResult.Chart>
      <CardActionResult.Statistic>
        <Space component="h3" mb={1}>
          {second.value}%
        </Space>
        {second.children}
      </CardActionResult.Statistic>
    </CardActionResult>
  );
};

CardActionResultComponent.propTypes = {
  result: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.any,
      value: PropTypes.number,
    })
  ),
};

export { CardActionResult };

export default CardActionResultComponent;
