import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const ReachLink = ({ children, to, ...props }) => (
  <Link
    className={({ isActive }) => (isActive ? "active" : "")}
    to={to}
    {...props}
  >
    {children}
  </Link>
);

ReachLink.propTypes = {
  children: PropTypes.any,
  to: PropTypes.string,
};

export default ReachLink;
