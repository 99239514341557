import { rem } from "polished";
import styled from "styled-components";

const Indicator = styled.ol`
  display: inline-flex;
  list-style: none;
  padding-left: 0;
`;

Indicator.Item = styled.li`
  & + & {
    margin-left: ${rem(6)};
  }
`;

Indicator.Icon = styled.div`
  background-color: ${(props) =>
    props.isActive
      ? props.theme.palette.primary.medium
      : props.theme.palette.base.light};
  border-radius: 50%;
  height: ${rem(6)};
  overflow: hidden;
  text-indent: -9999px;
  width: ${rem(6)};

  &:focus {
    outline: 0;
  }
`;

export default Indicator;
