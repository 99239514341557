import React from "react";
import Svg, { Circle, Path } from "react-native-svg";

const DismissIcon: React.FunctionComponent = () => {
  return (
    <Svg fill="none" height={24} viewBox="0 0 24 24" width={24}>
      <Circle cx={12} cy={12} fill="#fff" r={12} />
      <Path
        d="M7.5 7.5l8.485 8.485M15.985 7.5L7.5 15.985"
        stroke="#0F5FBC"
        strokeLinecap="round"
        strokeWidth={2}
      />
    </Svg>
  );
};

export default DismissIcon;
