import { get } from "lodash";
import { rem } from "polished";
import styled from "styled-components";

const CardFooter = styled.div`
  border-top: 1px solid ${(props) => get(props, "theme.palette.base.light")};
  font-size: ${rem(12)};
  line-height: ${16 / 12};
  padding: ${rem(24)};
`;

export default CardFooter;
