import PropTypes from "prop-types";
import React from "react";

import CardActionBar from "./CardActionBar";

const CardActionBarComponent = ({ actions }) => (
  <CardActionBar>
    {actions.map((action) => (
      <CardActionBar.Action key={action.children} {...action}>
        {action.children}
      </CardActionBar.Action>
    ))}
  </CardActionBar>
);

CardActionBarComponent.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      children: PropTypes.string,
      onClick: PropTypes.func,
      to: PropTypes.string,
    })
  ).isRequired,
};

export { CardActionBar };

export default CardActionBarComponent;
