import { distanceInWordsStrict } from "date-fns";
import PropTypes from "prop-types";
import React from "react";

import Button, { ButtonColor } from "../Button";
import Card, { CardContent, CardMeta } from "../Card";
import CardMedia from "../CardMedia";
import Grid from "../Grid";
import PointsBadge from "../PointsBadge";
import SkeletonContent from "../SkeletonContent";
import Space from "../Space";
import Icon from "./Icon";
import { PointsBadgeContainer } from "./OfferCard";

/**
 * @render react
 * @name OfferCard
 * @description Achievement Offer Card
 * @example
 * <OfferCardComponent />
 */

const pluralize = (value, unit) => `${value} ${unit}${value === 1 ? "" : "s"}`;

const OfferCardComponent = ({ action, dropdownMenu, loading, offer }) => {
  if (loading) {
    return (
      <Card height="100%">
        <SkeletonContent.Block height={140} />
        <CardContent>
          <Grid.Flex>
            <Grid.Box flex={1} mb={3}>
              <SkeletonContent.Block height={30} />
            </Grid.Box>
            <Grid.Box flex="0 0 auto" ml={4} />
          </Grid.Flex>
          <Grid.Flex flex={1} mr={5}>
            <SkeletonContent.Block height={72} />
          </Grid.Flex>
          <Grid.Flex mt={5}>
            <Grid.Box flex={1} />
            <Grid.Box flex="0 0 auto">
              <SkeletonContent.Block height={39} width={164} />
            </Grid.Box>
          </Grid.Flex>
        </CardContent>
      </Card>
    );
  }
  return (
    <Card height="100%">
      {offer.image && (
        <CardMedia badge={offer.sponsored && "Sponsored"} image={offer.image} />
      )}
      <CardContent>
        {offer.compensation && (
          <PointsBadgeContainer>
            <PointsBadge>{offer.compensation}</PointsBadge>
          </PointsBadgeContainer>
        )}
        <Grid.Flex>
          <Grid.Box flex={1}>
            <CardContent.Title>{offer.title}</CardContent.Title>
          </Grid.Box>
          {dropdownMenu && (
            <Grid.Box flex="0 0 auto" ml={4}>
              {dropdownMenu}
            </Grid.Box>
          )}
        </Grid.Flex>
        <Grid.Flex flex={1} mr={5}>
          <CardContent.Body>{offer.body}</CardContent.Body>
        </Grid.Flex>
        <Grid.Flex mt={5}>
          <Grid.Box flex={1}>
            <CardMeta>
              {offer.duration && pluralize(offer.duration, "min")}
              {offer.expiryDate && (
                <React.Fragment>
                  <br />
                  {`Expires in ${distanceInWordsStrict(
                    offer.expiryDate,
                    new Date()
                  )}`}
                </React.Fragment>
              )}
            </CardMeta>
          </Grid.Box>
          <Grid.Box flex="0 0 auto">
            <Button {...action} color={ButtonColor.DARK} uppercase>
              {action.children}
              <Space ml={2}>
                <Icon.ArrowRight color="#92ccee" height={10} />
              </Space>
            </Button>
          </Grid.Box>
        </Grid.Flex>
      </CardContent>
    </Card>
  );
};

OfferCardComponent.propTypes = {
  action: PropTypes.shape({
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.string,
    onClick: PropTypes.func,
    to: PropTypes.string,
  }),
  offer: PropTypes.shape({
    body: PropTypes.string.isRequired,
    compensation: PropTypes.string,
    duration: PropTypes.number,
    expiryDate: PropTypes.string,
    image: PropTypes.string,
    sponsored: PropTypes.bool,
    title: PropTypes.string.isRequired,
  }),
  loading: PropTypes.bool,
  dropdownMenu: PropTypes.node,
};

OfferCardComponent.defaultProps = {
  loading: false,
};

export default OfferCardComponent;
