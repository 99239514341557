type PhoneNumberOptions = {
  obfuscated: boolean;
};

/**
 * Format the value by stripping non-numeric characters and then ensuring an integer value.
 * @param value The raw string value from the Input.
 * @returns The stripped value parsed as an integer.
 */
export const formatNumericValue = (value: string): number | undefined => {
  const strippedValue = stripNonNumericChars(value);
  return strippedValue ? parseInt(strippedValue) : undefined;
};

/**
 * Remove all non-numeric characters from a string.
 *  Example: "abc123.a5" --> "123.5"
 * @param value The value to remove non-numeric characters from.
 * @returns A parsed integer or undefined if the value is empty.
 */
export const stripNonNumericChars = (value: string): string | undefined => {
  const stripped = value.replace(/[^\d.]+/g, "");
  return Number.isNaN(stripped) ? undefined : stripped;
};

/**
 * Removes invalid characters from a phone number
 * @param {string} text A field value provided by Formik
 * @param {PhoneNumberOptions} options Formatting options
 * @return {string}
 */
export const normalizePhoneNumber = (
  text: string,
  options?: PhoneNumberOptions
): string =>
  text
    ? text.replace(options?.obfuscated ? /[^\d|*]/g : /\D/g, "").slice(0, 10)
    : "";

/**
 * Returns a formatted seven digit phone number
 * @param {string} text A numeric phone number
 * @return {string}
 */
const toSevenDigitPhoneNumber = (text: string): string =>
  text.replace(/([\d|*]{3})([\d|*]{1,4})/, "$1-$2");

/**
 * Returns a formatted ten digit phone number
 * @param {string} text A numeric phone number
 * @return {string}
 */
const toTenDigitPhoneNumber = (text: string): string =>
  text.replace(/([\d|*]{3})([\d|*]{3})([\d|*]{1,4})/, "($1) $2-$3");

/**
 * Returns a formatted phone number based on the number of digits entered
 * @param {string} text A field value provided by Formik
 * @param {PhoneNumberOptions} options Formatting options
 * @return {string | undefined}
 */
export const toPhoneNumber = (
  text?: string,
  options?: PhoneNumberOptions
): string | undefined => {
  if (!text) {
    return text;
  }

  const phoneNumber = normalizePhoneNumber(text, options);
  if (phoneNumber.length <= 7) {
    return toSevenDigitPhoneNumber(phoneNumber);
  }

  return toTenDigitPhoneNumber(phoneNumber);
};
