import axios from "axios";

import { getAccessToken } from "@achievement/portal-client";

const PORTAL = {
  baseURL: `${window.env.REACT_APP_PORTAL_URL}/api/`,
  headers: {
    app_agent: `Achievemint/${window.env.REACT_APP_ACHIEVEMENT_VERSION} (portal-web)`,
  },
  responseType: "json",
  withCredentials: true,
};

const portalService = axios.create(PORTAL);

portalService.interceptors.request.use(async (config) => {
  const cognitoAccessToken = await getAccessToken();
  if (!cognitoAccessToken) {
    throw new axios.Cancel("We were unable to find an access token.");
  }

  config.headers.Authorization = `Bearer ${cognitoAccessToken}`;

  const csrfToken = localStorage.getItem("csrf");
  if (csrfToken) {
    config.headers["X-CSRF-Token"] = csrfToken;
  }

  return config;
});

portalService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      // fail silently if on a page that does not require auth
      if (window.location.pathname.indexOf("/member") === -1) {
        return error;
      }

      // log out if on a page that does require auth
      // TODO: Handle unauthenticated logic.
      /*navigate("/logout", {
        state: {
          message: "Sorry, your user session was not validated.",
          redirect: encodeURIComponent(
            window.location.pathname + window.location.search
          ),
        },
      });*/
    }

    return error;
  }
);

const unauthenticatedPortalService = axios.create(PORTAL);

unauthenticatedPortalService.interceptors.request.use((config) => {
  if (!config.params) {
    config.params = {};
  }

  config.params.client_id = window.env.REACT_APP_PORTAL_CLIENT_ID;

  return config;
});

unauthenticatedPortalService.interceptors.response.use(
  (response) => response,
  (error) => error.response
);

const setStudyCookies = (actionUrl, sharedAuthCookie, userEmail) => {
  const url = new URL(actionUrl);
  const studyUrl = `${url.protocol}//${url.hostname}/api/v3/set_acm_cookies`;
  axios.get(studyUrl, {
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "ACHIEVEMENT-ACCESS-TOKEN": encodeURIComponent(sharedAuthCookie),
      "ACHIEVEMENT-PORTAL-EMAIL": userEmail,
    },
    redirect: "follow",
    credentials: "include",
  });
};

export { portalService, unauthenticatedPortalService, setStudyCookies };

export default portalService;
