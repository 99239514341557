import PropTypes from "prop-types";
import React from "react";

import ActivityIndicator from "../ActivityIndicator";
import { ButtonColor, ButtonSize } from "../Button";
import SubmitButton from "./SubmitButton";

const renderText = ({
  children,
  hasError,
  isComplete,
  renderComplete,
  renderError,
}) => {
  if (hasError) {
    return renderError();
  } else if (isComplete) {
    return renderComplete();
  }

  return children;
};

const SubmitButtonComponent = ({ children, isSubmitting, ...props }) => (
  <SubmitButton {...props} isSubmitting={isSubmitting}>
    {isSubmitting && <ActivityIndicator />}
    <SubmitButton.Text isSubmitting={isSubmitting}>
      {renderText({ children, ...props })}
    </SubmitButton.Text>
  </SubmitButton>
);

SubmitButtonComponent.propTypes = {
  hasError: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  renderComplete: PropTypes.func,
  renderError: PropTypes.func,
};

SubmitButtonComponent.defaultProps = {
  color: ButtonColor.PRIMARY,
  hasError: false,
  isSubmitting: false,
  renderComplete: () => "Complete",
  renderError: () => "Error",
  size: ButtonSize.LARGE,
};

export { SubmitButton };

export default SubmitButtonComponent;
