import {
  configureStore,
  createAction,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";

import eventAggregateReducer from "./eventAggregate/reducers";
import events from "./events";

const RESET_ACTION = "reset";

export const reset = createAction(RESET_ACTION);

const appReducer = combineReducers({
  eventAggregate: eventAggregateReducer,
  events,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_ACTION) {
    state = undefined;
  }

  return appReducer(state, action);
};

const configureReduxStore = (preloadedState) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk, ...getDefaultMiddleware()],
    preloadedState,
  });

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept(rootReducer, () => store.replaceReducer(rootReducer));
  }

  return store;
};
export default configureReduxStore;
