import PropTypes from "prop-types";
import React from "react";
import Copy from "react-copy-to-clipboard";

import Button, { ButtonColor, ButtonSize } from "../Button";
import CopyLink from "./CopyLink";

class CopyLinkComponent extends React.PureComponent {
  state = {
    isCopied: false,
  };

  handleCopy = () => this.setState({ isCopied: true });

  render() {
    return (
      <CopyLink>
        <CopyLink.Value>{this.props.value}</CopyLink.Value>
        <Copy onCopy={this.handleCopy} text={this.props.value}>
          <Button
            color={ButtonColor.LIGHT}
            size={ButtonSize.SMALLER}
            uppercase
            value={this.props.value}
          >
            {this.state.isCopied ? "Copied" : "Copy"}
          </Button>
        </Copy>
      </CopyLink>
    );
  }
}

CopyLinkComponent.propTypes = {
  value: PropTypes.string.isRequired,
};

export { CopyLink };

export default CopyLinkComponent;
