import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";

import Button from "./Button";
import ButtonColor, { buttonTheme } from "./ButtonColor";
import ButtonSize from "./ButtonSize";

/**
 * @render react
 * @name Button
 * @description Achievement Button
 * @example
 * <Button size={ButtonSize.MEDIUM}>Click Me</Button>
 */

const ButtonComponent = ({ color, ...props }) => (
  <ThemeProvider theme={{ button: buttonTheme[color] }}>
    <Button {...props} />
  </ThemeProvider>
);

ButtonComponent.propTypes = {
  color: PropTypes.oneOf(Object.values(ButtonColor)),
  size: PropTypes.oneOf(Object.values(ButtonSize)),
  uppercase: PropTypes.bool,
};

ButtonComponent.defaultProps = {
  color: ButtonColor.NONE,
  size: ButtonSize.MEDIUM,
  uppercase: false,
};

export { Button, ButtonColor, ButtonSize };

export default ButtonComponent;
