import PropTypes from "prop-types";
import Space from "styled-space";

const SpaceComponent = ({ children, component, ...props }) => {
  if (!component) {
    return <Space {...props}>{children}</Space>;
  }

  const Component = component;
  return (
    <Space {...props}>
      <Component>{children}</Component>
    </Space>
  );
};

SpaceComponent.propTypes = {
  component: PropTypes.string,
};

export default SpaceComponent;
