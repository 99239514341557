import Grid from "../Grid";
import Panel, { PanelColor } from "../Panel";
import EventPanel from "./EventPanel";

const EventPanelErrorState = () => (
  <Panel color={PanelColor.WHITE} p={4}>
    <Grid.Flex alignItems="center">
      <Grid.Box flex="0 0 auto" mr={2}>
        <svg
          height="40"
          viewBox="0 0 90 90"
          width="40"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <circle cx="45" cy="45" fill="#FE7345" r="45" />
            <path
              d="M40.398 52.937l-.716-36.062h10.023l-.716 36.062h-8.591zM45 70.313a5.625 5.625 0 1 0 0-11.25 5.625 5.625 0 0 0 0 11.25z"
              fill="#FFF"
              fillRule="nonzero"
            />
          </g>
        </svg>
      </Grid.Box>
      <Grid.Box pr={2} width={1}>
        <EventPanel.Description>Oh no...</EventPanel.Description>
        <EventPanel.Meta>
          An error has occurred and we are working to fix the problem!
        </EventPanel.Meta>
      </Grid.Box>
    </Grid.Flex>
  </Panel>
);

export default EventPanelErrorState;
