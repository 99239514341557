import React from "react";

import FieldError from "./FieldError";

/**
 * @render react
 * @name FieldError
 * @description Achievement FieldError Component
 * @example
 * <FieldError>Lorem ipsum dolar sit amet</FieldError>
 */

const FieldErrorComponent = ({ children, ...props }) => {
  return <FieldError {...props}>{children}</FieldError>;
};

export { FieldError };

export default FieldErrorComponent;
