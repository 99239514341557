import { rem } from "polished";
import styled from "styled-components";

import EmptyStar from "./EmptyStar.svg";
import Star from "./Star.svg";

const StarRating = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin: ${rem(-8)} ${rem(-7)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

StarRating.Label = styled.label`
  align-items: center;
  background-image: url("${EmptyStar}");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: ${rem(30)} ${rem(28)};
  border: 0;
  cursor: pointer;
  display: flex;
  height: ${rem(44)};
  justify-content: center;
  overflow: hidden;
  padding: 0;
  text-indent: -9999px;
  width: ${rem(44)};
  -webkit-appearance: none;
`;

StarRating.Input = styled.input`
  display: none;

  &:checked {
    ~ ${StarRating.Label} {
      background-image: url("${Star}");
    }
  }

  &:disabled {
    + ${StarRating.Label} {
      cursor: not-allowed;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    & + ${StarRating.Label}:hover {
      &,
      ~ ${StarRating.Label} {
        background-image: url("${Star}");
      }
    }
  }
`;

StarRating.Value = styled.span`
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
`;

export default StarRating;
