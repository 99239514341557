import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useLocation } from "react-router-dom";
import { animateScroll } from "react-scroll";

import {
  Grid,
  Notification,
  NotificationStatus,
} from "../components/ReactComponents/Components";
import { decodeSearch } from "../utils/queryString";

const NotificationContext = React.createContext();
const NotificationConsumer = NotificationContext.Consumer;

const IGNORED_PATHS = ["/member/account/email/confirm"];

const NotificationProvider = ({ children }) => {
  const { pathname, state } = useLocation();
  const [notification, setNotification] = React.useState();
  const location = useLocation();

  React.useEffect(() => {
    if (IGNORED_PATHS.includes(pathname)) {
      return;
    }
    const { message = "", success } = decodeSearch(location.search);

    if (success === "true" && !isEmpty(message)) {
      setNotification({
        message: message,
        status: NotificationStatus.SUCCESS,
      });
    } else if (success === "false") {
      setNotification({
        message: message || "An unknown error has occurred.",
        status: NotificationStatus.ERROR,
      });
    } else if (state?.notification) {
      setNotification(state.notification);
    } else {
      setNotification();
    }
  }, [location, state, setNotification, pathname]);

  return (
    <NotificationContext.Provider
      value={{
        notification,
        setNotification: (value) => {
          animateScroll.scrollToTop({ duration: 0 });
          setNotification(value);
        },
      }}
    >
      {notification && (
        <Notification status={notification.status}>
          <Grid>
            <Grid.Flex
              data-testid="notification"
              justifyContent="center"
              px={3}
            >
              {notification.message}
            </Grid.Flex>
          </Grid>
        </Notification>
      )}
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node,
};

export { NotificationConsumer, NotificationProvider };

export default NotificationContext;
