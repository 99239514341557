import PropTypes from "prop-types";
import React from "react";

const ArrowRight = ({ color, height, width, ...props }) => (
  <svg
    height={height}
    viewBox="0 0 9 15"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeWidth="2"
    >
      <path d="M7.5 7.5l-6 6M7.5 7.5l-6-6" />
    </g>
  </svg>
);

ArrowRight.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

ArrowRight.defaultProps = {
  color: "#2A3F53",
  height: 15,
  width: 9,
};

export default ArrowRight;
