import GA4React from "ga-4-react";

export const Events = {
  ACHIEVEMENT_ICON_CLICK: "achievement_icon_click",

  ACTIVITY_FEED_CLICK: "activity_feed_click",
  ACTIVITY_FEED_LOAD: "activity_feed_load",
  ACTIVITY_FEED_LOAD_MORE: "activity_feed_load_more",

  APPLICATION: "application",
  APPLICATION_CONNECT: "application_connect",
  APPLICATION_CONNECT_SUCCESS: "application_connect_success",
  APPLICATION_DISCONNECT: "application_disconnect",
  APPLICATION_DISCONNECT_SUCCESS: "application_disconnect_success",
  APPLICATION_SEARCH: "application_search",

  CCPA_DELETE: "ccpa_delete",
  CCPA_DELETE_SUBMIT: "ccpa_delete_submit",
  CCPA_DELETE_SUBMIT_ERROR: "ccpa_delete_submit_error",
  CCPA_DELETE_SUBMIT_SUCCESS: "ccpa_delete_submit_success",

  CCPA_EXPORT: "ccpa_export",
  CCPA_EXPORT_SUBMIT: "ccpa_export_submit",
  CCPA_EXPORT_SUBMIT_ERROR: "ccpa_export_submit_error",
  CCPA_EXPORT_SUBMIT_SUCCESS: "ccpa_export_submit_success",

  DATA_SUBJECT_REQUEST: "data_subject_request",

  DEACTIVATE: "deactivate",
  DEACTIVATE_SUBMIT: "deactivate_submit",
  DEACTIVATE_SUBMIT_ERROR: "deactivate_submit_error",
  DEACTIVATE_SUBMIT_SUCCESS: "deactivate_submit_success",

  DOWNLOAD_APP: "download_app",

  EDIT_EMAIL: "edit_email",
  EDIT_EMAIL_SUBMIT: "edit_email_submit",
  EDIT_EMAIL_SUBMIT_ERROR: "edit_email_submit_error",
  EDIT_EMAIL_SUBMIT_SUCCESS: "edit_email_submit_success",

  EDIT_PASSWORD: "edit_password",
  EDIT_PASSWORD_SUBMIT: "edit_password_submit",
  EDIT_PASSWORD_SUBMIT_ERROR: "edit_password_submit_error",
  EDIT_PASSWORD_SUBMIT_SUCCESS: "edit_password_submit_success",

  EDIT_PROFILE: "edit_profile",
  EDIT_PROFILE_SUBMIT: "edit_profile_submit",
  EDIT_PROFILE_SUBMIT_ERROR: "edit_profile_submit_error",
  EDIT_PROFILE_SUBMIT_SUCCESS: "edit_profile_submit_success",

  EXPORT: "export",
  EXPORT_SUBMIT: "export_submit",
  EXPORT_SUBMIT_ERROR: "export_submit_error",
  EXPORT_SUBMIT_SUCCESS: "export_submit_success",

  FORM_FIELD_FOCUS: "form_field_focus",

  FOOTER_CLICK: "footer_click",

  LOGOUT: "logout",
  LOGOUT_CANCEL: "logout_cancel",
  LOGOUT_CONFIRM: "logout_confirm",

  NATIVE_APPLICATION_CONNECT_ERROR: "native_application_connect_error",

  OFFER_CLICK: "offer_click",
  OFFER_COMPLETE: "offer_complete",
  OFFER_DISMISS: "offer_dismiss",
  OFFER_DISMISS_CANCEL: "offer_dismiss_cancel",
  OFFER_DISMISS_CONFIRM: "offer_dismiss_confirm",
  OFFER_DISMISS_COMPLETE: "offer_dismiss_complete",
  OFFER_FOCUS: "offer_focus",
  OFFER_RATE: "offer_rate",
  OFFERS_REFRESH: "offers_refresh",

  ONBOARDING_SKIP_CONNECT: "onboarding_skip_connect",

  POINTS_OPEN: "points_open",
  POINTS_CATEGORY: "points_category",
  POINTS_CLICK: "points_click",
  POINTS_NEXT: "points_next",
  POINTS_PREVIOUS: "points_previous",

  REDEEM: "redeem",
  REDEEM_EDIT_ADDRESS: "redeem_edit_address",
  REDEEM_SUBMIT: "redeem_submit",
  REDEEM_SUBMIT_SUCCESS: "redeem_submit_success",
  REDEEM_SUBMIT_ERROR: "redeem_submit_error",

  REFER_FRIENDS: "refer_friends",

  RESET_PASSWORD: "reset_password",
  RESET_PASSWORD_ERROR: "reset_password_error",
  RESET_PASSWORD_SUCCESS: "reset_password_success",

  SEND_INVITES: "send_invites",

  SHARE_LINK: "share_link",

  SIGNUP_START: "signup_start",
  SIGNUP_SUBMIT_SUCCESS: "signup_submit_success",
  SIGNUP_SUBMIT_ERROR: "signup_submit_error",

  USER_PREFERENCE_CHANGE: "notification_update",

  SIGNIN_SUCCESS: "signin_success",
  SIGNIN_ERROR: "signin_error",

  VERIFY_OTP_REQUEST_CODE_SUCCESS: "verify_otp_request_code_success",
  VERIFY_OTP_REQUEST_CODE_ERROR: "verify_otp_request_code_error",
  VERIFY_OTP_VERIFY_CODE_SUCCESS: "verify_otp_verify_code_success",
  VERIFY_OTP_VERIFY_CODE_ERROR: "verify_otp_verify_code_error",
  VERIFY_OTP_REFRESH_CODE: "verify_otp_refresh_code",
};

let ga4react = undefined;

const trackingService = {
  set: (value) => {
    if (ga4react) ga4react.gtag("set", value);
    else console.log("Set:", value);
  },

  setConfig: (value) => {
    if (ga4react) {
      ga4react.gtag("config", window.env.REACT_APP_GOOGLE_ANALYTICS_ID, value);
    }
  },

  setUserProperties: (userProperties) => {
    if (ga4react) {
      ga4react.gtag("set", "user_properties", userProperties);
    }
  },

  trackEvent: (name, value) => {
    if (ga4react) ga4react.gtag("event", name, value);
    else
      console.log(
        "Event:",
        `Name: ${JSON.stringify(name)} Value: ${JSON.stringify(value)}`
      );
  },

  trackModal: (name) => {
    if (ga4react) ga4react.pageview(name);
    else console.log(`Modal: ${name}`);
  },

  trackPage: (path) => {
    if (ga4react) ga4react.pageview(path);
    else console.log(`Page: ${path}`);
  },
};

export const initializeTracking = async () => {
  if (process.env.NODE_ENV !== "development") {
    ga4react = new GA4React(window.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    await ga4react.initialize();
  }
};

export default trackingService;
