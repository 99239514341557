import React from "react";
import styled, { css, keyframes } from "styled-components";

import { ActivityIndicator } from "../ActivityIndicator";
import Button from "../Button";

const rotate = keyframes`
  0% {
    transform: rotate(0deg) translateX(-50%) translateY(-50%);
  }
  100% {
    transform: rotate(360deg) translateX(-50%) translateY(-50%);
  }
`;

const applyColor = (props) => {
  if (props.isSubmitting) {
    return "#5b6b7d";
  } else if (props.hasError) {
    return "#eb3f54";
  } else if (props.isComplete) {
    return "#60D394";
  }
};

const SubmitButton = styled(
  ({
    hasError,
    isComplete,
    isSubmitting,
    renderComplete,
    renderError,
    ...props
  }) => <Button {...props} type="submit" />
)`
  background-color: ${applyColor};
  pointer-events: ${(props) =>
    props.isComplete && !props.hasError ? "none" : "auto"};
  position: relative;
  transition: 0.25s background-color ease;

  ${ActivityIndicator} {
    animation: 0.67s ${rotate} linear infinite;
    height: rem(32);
    left: 50%;
    position: absolute;
    transform-origin: 0 0 0;
    top: 50%;
    width: rem(32);
  }
`;

SubmitButton.Text = styled.div`
  transition: 0.25s color ease, 0.25s transform ease;

  ${(props) =>
    props.isSubmitting
      ? css`
          color: transparent;
          transform: translateY(-100%);
        `
      : css`
          color: inherit;
          transform: translateY(0);
        `}
`;

export default SubmitButton;
