import React from "react";

// prettier-ignore
const categoryStyles = Object.freeze([
  {
    category: 'offers',
    color: '#37d596',
    icon: <svg height="22" width="22" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M0 0h22v22H0z"/><path d="M13.443 16.091H8.55a.108.108 0 0 1-.096-.157l2.45-4.9a.105.105 0 0 1 .19 0l2.448 4.9a.11.11 0 0 1-.046.146.097.097 0 0 1-.05.012h-.004zm7.805 4.918L11.176.843a.198.198 0 0 0-.354 0L.752 21.009a.18.18 0 0 0 .079.239c.024.012.05.019.078.019h20.18a.178.178 0 0 0 .159-.258z" fill="#5B6B7D"/></g></svg>,
  },
  {
    category: 'walking',
    color: '#0f5fbc',
    icon: <svg height="22" width="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M-3 0h22v22H-3z"/><g fill="#5B6B7D"><path d="M2.4 16.902a.26.26 0 0 1-.236-.146c-.71-.92-1.468-2.568-1.8-3.925C-.44 9.633.127 6.823 1.69 6.387c.142-.049.284-.049.426-.049 1.468 0 3.078 2.084 3.836 4.943.331 1.356.473 3.198.331 4.36 0 .146-.094.243-.237.243l-3.551 1.018H2.4zM4.916 21.127c-1.663 0-2.263-2.265-2.263-2.357-.047-.185.046-.324.23-.37l3.465-.97c.093 0 .185 0 .231.046.093.046.139.092.139.185.277.97.323 2.958-1.294 3.42-.184.046-.323.046-.508.046zM13.454 10.035h-.094l-3.553-.96c-.142-.048-.19-.144-.237-.24-.142-1.104-.048-2.833.331-4.13C10.66 1.97 12.271 0 13.74 0c.142 0 .284 0 .426.048.474.144.853.48 1.137.96.663 1.153.71 3.121.19 5.138-.332 1.296-1.09 2.881-1.8 3.745a.26.26 0 0 1-.238.144zM10.92 14.26c-.187 0-.327 0-.514-.047-.512-.144-.886-.43-1.119-.862-.56-1.005-.186-2.488-.186-2.536.046-.191.233-.287.373-.24l3.497.958c.093 0 .14.096.187.143.046.096.046.144.046.24 0 .095-.606 2.345-2.285 2.345z"/></g></g></svg>,
  },
  {
    category: 'running',
    color: '#ffb820',
    icon: <svg height="9" width="22" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M0-7h22v22H0z"/><g fill="#5B6B7D" fillRule="nonzero"><path d="M.458 2.75c1.613 0 2.937 1.2 3.162 2.75h18.35c-.07-.5-.398-.934-.883-1.107l-7.49-2.675-1.356 1.357a.46.46 0 0 1-.649 0 .459.459 0 0 1 0-.649l1.05-1.048-.736-.263-1.5 1.501a.457.457 0 0 1-.647 0 .458.458 0 0 1 0-.647L10.95.774 8.862.027a.46.46 0 0 0-.612.431c0 .37-.874.917-2.75.917-1.561 0-2.75-.61-2.75-.917A.459.459 0 0 0 2.292 0h-.917C.617 0 0 .617 0 1.375V2.75h.458z"/><path d="M.458 3.667H0V5.5h2.703A2.294 2.294 0 0 0 .458 3.667zM0 6.417v1.375c0 .253.205.458.458.458h21.084A.459.459 0 0 0 22 7.792V6.417H0z"/></g></g></svg>,
  },
  {
    category: 'biking',
    color: '#20ceff',
    icon: <svg height="16" width="22" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M0-3h22v22H0z"/><g stroke="#5B6B7D" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" transform="translate(.733 1.4)"><ellipse cx="3.592" cy="9.261" rx="3.551" ry="3.625"/><ellipse cx="16.908" cy="9.261" rx="3.551" ry="3.625"/><path d="M9.362 9.261l5.06-6.937-6.835 2.372L3.592 9.26h5.77l-2.885-7.25"/><path d="M16.908 9.261L13.801.623l1.776-.448M5.367 2.01h2.664"/></g></g></svg>,
  },
  {
    category: 'swimming',
    color: '#92ccee',
    icon: <svg height="19" width="18" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><g stroke="#5B6B7D" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"><path d="M17.089 16.308c0-1.43-1.605-1.43-1.605 0 0 1.475-1.821 1.475-1.821 0 0-1.43-1.821-1.43-1.821 0 0 1.475-1.822 1.475-1.822 0 0-1.43-1.821-1.43-1.821 0 0 1.475-1.822 1.475-1.822 0 0-1.43-1.821-1.43-1.821 0 0 1.475-1.821 1.475-1.821 0 0-1.43-1.648-1.43-1.648 0M17.089 12.665c0-1.43-1.605-1.43-1.605 0 0 1.475-1.821 1.475-1.821 0 0-1.43-1.821-1.43-1.821 0 0 1.475-1.822 1.475-1.822 0 0-1.43-1.821-1.43-1.821 0 0 1.475-1.822 1.475-1.822 0 0-1.43-1.821-1.43-1.821 0 0 1.475-1.821 1.475-1.821 0 0-1.43-1.648-1.43-1.648 0"/><path d="M8.763 11.711v-8.76c0-.997-.177-1.82.82-1.82.998 0 1.822.823 1.822 1.82M13.836 13.36V2.95c0-.997-.287-1.82.822-1.82s1.821.823 1.821 1.82M8.763 7.635h5.073M8.763 5.467h5.073M8.763 9.803h5.073"/></g><path d="M-2-2h22v22H-2z"/></g></svg>,
  },
  {
    category: 'sleeping',
    color: '#fe7345',
    icon: <svg height="18" width="18" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M-2-2h22v22H-2z"/><path d="M17.823 11.633C11.626 11.633 7.259 5.788 9.03 0 4.013.058 0 4.051 0 8.971 0 13.95 4.131 18 9.207 18c4.13 0 7.613-2.662 8.793-6.367h-.177z" fill="#5B6B7D"/></g></svg>,
  },
  {
    category: 'blood_glucose',
    color: '#0f5fbc',
    icon: <svg height="19" width="13" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M6.663.171c-.146-.222-.513-.222-.66 0C5.758.542 0 9.3 0 12.661a6.34 6.34 0 0 0 6.333 6.333 6.34 6.34 0 0 0 6.334-6.334C12.667 9.3 6.909.542 6.663.171zM5.136 7.698c-1.714 3.261-1.97 4.509-1.97 4.962 0 .901.385 1.761 1.058 2.362a.395.395 0 1 1-.529.59 3.963 3.963 0 0 1-1.32-2.952c0-.935.693-2.728 2.06-5.33a.4.4 0 0 1 .535-.167.397.397 0 0 1 .166.535z" fill="#5B6B7D" fillRule="nonzero"/><path d="M-5-2h22v22H-5z"/></g></svg>,
  },
  {
    category: 'study_contributions',
    color: '#20ceff',
    icon: <svg height="18" width="14" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M-4-2h22v22H-4z"/><path d="M13.498 0h-1.73v2.967h-1.385V0H9v2.967H7.614V0H6.23v2.967H4.846V0H3.46v2.967H2.077V0H.347C.154 0 0 .166 0 .37v17.06c0 .204.155.37.346.37h13.152c.191 0 .346-.166.346-.37V.37c0-.204-.155-.37-.346-.37zM10.73 12.383H3.115c-.191 0-.346-.166-.346-.37 0-.205.155-.371.346-.371h7.614c.191 0 .347.166.347.37 0 .205-.156.371-.347.371zm0-2.225H3.115c-.191 0-.346-.166-.346-.37 0-.205.155-.371.346-.371h7.614c.191 0 .347.166.347.37 0 .205-.156.371-.347.371zm0-2.225H3.115c-.191 0-.346-.166-.346-.37 0-.205.155-.371.346-.371h7.614c.191 0 .347.166.347.37 0 .205-.156.371-.347.371z" fill="#5B6B7D" fillRule="nonzero"/></g></svg>,
  },
  {
    category: 'meditation',
    color: '#37d596',
    icon: <svg height="18" width="20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><g fill="#5B6B7D" fillRule="nonzero"><path d="M17.987 10.31c-.431.7-.973 1.365-1.653 1.893-1.393 1.08-3.033 1.356-4.345 1.356a9.686 9.686 0 0 1-2.034-.216 9.75 9.75 0 0 1-2.034.216c-1.313 0-2.953-.275-4.345-1.356-.722-.562-1.285-1.276-1.729-2.024-.927.74-1.432 1.614-1.432 2.524 0 2.704 4.011 4.743 9.332 4.743 1.746 0 4.012-.268 4.966-.782a.418.418 0 0 0 .219-.367v-2.195h4.044a.414.414 0 0 0 .413-.384c.099-1.326-.39-2.486-1.402-3.408z"/><path d="M4.21 8.809c-.594-1.287-.753-2.69-.73-3.927a8.357 8.357 0 0 0-2.04.267.414.414 0 0 0-.295.447c.02.165.512 4.066 2.939 5.951 1.218.945 2.664 1.188 3.829 1.188.125 0 .236-.009.353-.014-1.261-.617-3.089-1.82-4.056-3.912zM18.467 5.149a8.433 8.433 0 0 0-2.039-.267c.024 1.238-.135 2.641-.73 3.927-.966 2.091-2.794 3.295-4.056 3.912.118.005.228.014.353.014 1.166 0 2.612-.243 3.83-1.188 2.427-1.884 2.919-5.786 2.938-5.95a.413.413 0 0 0-.296-.448z"/><path d="M6.178 6.239c0-1.516.47-2.893 1.03-4.002-1.007-.597-1.847-.845-1.896-.857a.414.414 0 0 0-.508.272c-.05.156-1.205 3.857.16 6.81.732 1.585 2.025 2.602 3.097 3.215-.833-1.206-1.883-3.17-1.883-5.438zM15.105 1.652a.414.414 0 0 0-.508-.273c-.048.013-.888.258-1.897.856.56 1.11 1.031 2.487 1.031 4.003 0 2.267-1.05 4.233-1.883 5.438 1.071-.613 2.364-1.63 3.097-3.215 1.365-2.952.21-6.653.16-6.81z"/><path d="M10.252.126a.428.428 0 0 0-.596 0c-.108.111-2.649 2.767-2.649 6.112 0 3.347 2.541 6.002 2.649 6.114a.416.416 0 0 0 .595 0c.108-.112 2.65-2.767 2.65-6.114 0-3.345-2.54-6-2.65-6.112z"/></g><path d="M-1-2h22v22H-1z"/></g></svg>,
  },
  {
    category: 'food_and_water',
    color: '#ffb820',
    icon: <svg height="11" width="21" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M-1-6h22v22H-1z"/><path d="M20.281 9.494h-1.299c0-4.76-3.885-8.63-8.66-8.63V.431a.432.432 0 0 0-.867 0v.475c-4.37.435-7.795 4.12-7.795 8.587H.433a.432.432 0 1 0 0 .863h19.848a.432.432 0 1 0 0-.863zm-9.874-5.707c-2.567 0-4.815 1.534-5.596 3.816a.44.44 0 0 1-.422.288.473.473 0 0 1-.135-.019c-.233-.07-.362-.302-.287-.52.898-2.628 3.486-4.393 6.44-4.393.245 0 .443.186.443.414 0 .23-.198.414-.443.414z" fill="#5B6B7D" fillRule="nonzero"/></g></svg>,
  },
  {
    category: 'health_metrics',
    color: '#92ccee',
    icon: <svg height="20" width="17" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M14.875 7a1.876 1.876 0 1 0-.375 3.712v4.913c0 .62-.505 1.125-1.125 1.125s-1.125-.505-1.125-1.125v-2.25c0-1.24-1.01-2.25-2.25-2.25s-2.25 1.01-2.25 2.25v3.75c0 .62-.505 1.125-1.125 1.125S5.5 17.745 5.5 17.125v-5.269A4.13 4.13 0 0 0 9.25 7.75V4c0-.906-.645-1.663-1.5-1.837v-.788A.376.376 0 0 0 7.375 1c-.603 0-1.5.4-1.5 1.5S6.772 4 7.375 4a.376.376 0 0 0 .375-.375v-.681c.435.155.75.567.75 1.056v3.75a3.379 3.379 0 0 1-3.375 3.375A3.379 3.379 0 0 1 1.75 7.75V4c0-.488.314-.9.75-1.056v.681c0 .207.168.375.375.375.603 0 1.5-.4 1.5-1.5S3.478 1 2.875 1a.375.375 0 0 0-.375.375v.788C1.644 2.337 1 3.094 1 4v3.75a4.13 4.13 0 0 0 3.75 4.106v5.269c0 1.034.84 1.875 1.875 1.875A1.878 1.878 0 0 0 8.5 17.125v-3.75a1.5 1.5 0 0 1 3 0v2.25c0 1.034.84 1.875 1.875 1.875a1.878 1.878 0 0 0 1.875-1.875v-4.913c.855-.174 1.5-.931 1.5-1.837A1.877 1.877 0 0 0 14.875 7z" fill="#5B6B7D" fillRule="nonzero" stroke="#5B6B7D" strokeWidth=".5"/><path d="M-2-1h22v22H-2z"/></g></svg>,
  },
  {
    category: 'heart_rate',
    color: '#fe7345',
    icon: <svg height="19" width="20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M-1-2h22v22H-1z"/><g fill="#5B6B7D" fillRule="nonzero"><path d="M13.202 14.272a1.245 1.245 0 0 1-1.119.686c-.625-.026-1.06-.409-1.201-.898l-.784-2.72-.646 1.28c-.197.393-.587.667-1.106.686-.425 0-.81-.191-1.052-.552l-.686-1.021-.19.252c-.236.31-.609.495-1.001.406H3.218c1.3 2.313 4.46 5.165 6.551 6.54a.427.427 0 0 0 .486-.017c1.83-1.395 4.826-4.331 6.14-6.523h-2.29l-.903 1.881zM1.77 9.913h3.022l.875-1.068c.232-.31.61-.496 1.011-.496.425.013.805.218 1.029.552l.471.701L9.3 7.382c.213-.422.641-.685 1.119-.685.627.027 1.061.412 1.201.898l.805 2.796c.233-.247.558-.39.91-.478h4.61c.584-.852 1.223-2.77 1.223-4.395 0-3.564-2.345-5.43-4.661-5.43-1.72 0-3.614 1.04-4.506 3.322C9.12 1.165 7.274.14 5.6.14 2.47.14.833 3.093.833 6.008c0 1.525.481 3.214.936 3.905z"/><path d="M19.583 10.74h-6.25a.414.414 0 0 0-.372.227l-.771 1.53-1.372-4.763c-.048-.166-.191-.293-.419-.3a.408.408 0 0 0-.355.229l-1.762 3.493-1.268-1.885a.427.427 0 0 0-.348-.184.428.428 0 0 0-.331.164L5.208 10.74H.417a.415.415 0 0 0-.417.413c0 .228.187.413.417.413h5c.13 0 .255-.062.331-.164l.899-1.186 1.339 1.992c.093.139.223.188.387.183a.411.411 0 0 0 .332-.228l1.604-3.18 1.373 4.762a.42.42 0 0 0 .773.071l1.136-2.25h5.992c.23 0 .417-.185.417-.413a.416.416 0 0 0-.417-.413z"/></g></g></svg>,
  },
  {
    category: 'social_media',
    color: '#0f5fbc',
    icon: <svg height="13" width="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M-2-4h21.12v21.12H-2z"/><g fill="#5B6B7D" fillRule="nonzero"><path d="M13.427 7.017A2.63 2.63 0 0 0 14.48 4.92a2.643 2.643 0 0 0-2.64-2.64c-.734 0-1.397.302-1.877.787a4.263 4.263 0 0 1-.442 3.093c.183.335.432.63.737.86a4.052 4.052 0 0 0-.658.375 6.952 6.952 0 0 1 2.091 3.684h3.669a.44.44 0 0 0 .44-.44 3.961 3.961 0 0 0-2.373-3.622z"/><path d="M7.47 7.054A3.508 3.508 0 0 0 9.2 4.04 3.524 3.524 0 0 0 5.68.52a3.524 3.524 0 0 0-3.52 3.52c0 1.285.699 2.4 1.73 3.014C1.898 7.887.4 10.028.4 12.4c0 .243.197.44.44.44h9.68a.44.44 0 0 0 .44-.44c0-2.372-1.499-4.513-3.49-5.346z"/></g></g></svg>,
  },
  {
    category: 'medication',
    color: '#20ceff',
    icon: <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="M13.97 1.349A4.595 4.595 0 0 0 10.697 0c-1.239 0-2.4.48-3.27 1.349L4.584 4.193l-3.231 3.23a4.63 4.63 0 0 0 0 6.542 4.61 4.61 0 0 0 3.271 1.353 4.61 4.61 0 0 0 3.27-1.353l3.134-3.134 2.94-2.94a4.631 4.631 0 0 0 .001-6.542zM7.426 13.498a3.969 3.969 0 0 1-5.607 0 3.968 3.968 0 0 1 0-5.607l2.803-2.804 5.607 5.608-2.803 2.803zm5.14-10.28a.332.332 0 0 1-.466 0 2.314 2.314 0 0 0-3.272 0L7.43 4.62a.33.33 0 0 1-.468 0 .331.331 0 0 1 0-.468L8.362 2.75a2.978 2.978 0 0 1 4.206 0 .331.331 0 0 1 0 .468z" fill="#5B6B7D" fillRule="nonzero"/><path d="M-3-3h22v22H-3z"/></g></svg>,
  },
  {
    category: 'other_exercise',
    color: '#606a7b',
    icon: <svg height="13" width="22" xmlns="http://www.w3.org/2000/svg"><mask fill="#fff" id="a"><path d="M0 0h22v22H0z" fillRule="evenodd"/></mask><g fill="none" fillRule="evenodd" mask="url(#a)" transform="translate(0 -5)"><g fill="#5b6b7d" fillRule="nonzero"><path d="M3.208 7.75h-.916c-.758 0-1.375.617-1.375 1.375v1.833H.458a.46.46 0 0 0 0 .917h.459v1.833c0 .758.617 1.375 1.375 1.375h.916a.46.46 0 0 0 .459-.458V8.208a.46.46 0 0 0-.459-.458zM16.042 5h-1.834c-.758 0-1.375.617-1.375 1.375v5.042H9.167V6.375C9.167 5.617 8.549 5 7.792 5H5.958c-.758 0-1.375.617-1.375 1.375v10.083c0 .758.617 1.375 1.375 1.375h1.834c.757 0 1.375-.617 1.375-1.375v-4.125h3.666v4.125c0 .758.617 1.375 1.375 1.375h1.834c.757 0 1.375-.617 1.375-1.375V6.375c0-.758-.618-1.375-1.375-1.375zM21.542 10.958h-.459V9.125c0-.758-.617-1.375-1.375-1.375h-.916a.46.46 0 0 0-.459.458v6.417c0 .253.206.458.459.458h.916c.758 0 1.375-.617 1.375-1.375v-1.833h.459a.46.46 0 0 0 0-.917z"/></g></g></svg>,
  },
  {
    category: 'exercise',
    color: "#017A47",
    icon: <svg fill="none" height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M7.25013 11.7471H2.35761C2.25277 11.7471 2.16699 11.8486 2.16699 11.9728V13.5526C2.16699 13.6768 2.25277 13.7783 2.35761 13.7783L18.7506 13.7783L18.7506 13.7783H23.6431C23.7479 13.7783 23.8337 13.6767 23.8337 13.5526V11.9727C23.8337 11.8486 23.7479 11.747 23.6431 11.747L7.25011 11.747L7.25013 11.7471Z" fill="#5B6B7D" fill-rule="evenodd"/><path d="M5.17665 4.33301H7.58406C8.57832 4.33301 9.38962 5.1431 9.38962 6.13857V19.3793C9.38962 20.3748 8.57832 21.1849 7.58406 21.1849H5.17665C4.18119 21.1849 3.37109 20.3748 3.37109 19.3793V13.9626V12.7589V6.13857C3.37109 5.1431 4.18119 4.33301 5.17665 4.33301Z" fill="#5B6B7D"/><path d="M18.4169 4.33301H20.8243C21.8186 4.33301 22.6299 5.1431 22.6299 6.13857V19.3793C22.6299 20.3748 21.8186 21.1849 20.8243 21.1849H18.4169C17.4214 21.1849 16.6113 20.3748 16.6113 19.3793V13.9626V12.7589V6.13857C16.6113 5.1431 17.4214 4.33301 18.4169 4.33301Z" fill="#5B6B7D"/></svg>
  }
]);

export const getStylesByCategory = (category) => {
  const styles = categoryStyles.find((style) => style.category === category);

  if (!styles) {
    return {
      color: "#606a7b",
    };
  }

  return styles;
};
