import { rem } from "polished";
import styled from "styled-components";

const FooterMenu = styled.nav`
  a {
    color: ${(props) => props.theme.palette.base.medium};
    line-height: ${18 / 12};
    text-decoration: none;
    transition: color 0.35s ease;

    &:hover {
      color: ${(props) => props.theme.palette.base.dark};
    }
  }
`;

FooterMenu.Heading = styled.h4`
  color: ${(props) => props.theme.palette.base.dark};
  font-size: ${rem(16)};
  font-family: PublicSans-SemiBold;
  margin-bottom: ${rem(16)};
`;

FooterMenu.List = styled.ul`
  font-size: ${rem(14)};
  margin-bottom: ${rem(24)};
  padding-left: 0;
  list-style: none;
`;

FooterMenu.ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: ${rem(8)};
  }
`;

export default FooterMenu;
