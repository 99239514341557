import get from "lodash/get";
import { rem } from "polished";
import styled from "styled-components";

const getColor = (color) => (props) =>
  get(props, `theme.palette.${color}`, "#eb3f54");

const Checkbox = styled.div`
  position: relative;
`;

Checkbox.Label = styled.label`
  color: ${getColor("base.medium")};
  cursor: pointer;
  display: block;
  flex: 1;
  font-size: ${rem(14)};
  line-height: ${18 / 14};
  min-height: ${rem(28)};
  padding-left: ${rem(42)};
  padding-top: ${(props) => (props.offset ? rem(6) : 0)};

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
  }

  &::before {
    border: 1px solid ${getColor("base.light")};
    height: ${rem(26)};
    left: 0;
    top: 0;
    width: ${rem(26)};
  }

  &::after {
    height: ${rem(12)};
    left: ${rem(8)};
    top: ${rem(8)};
    width: ${rem(12)};
  }
`;

Checkbox.Input = styled.input`
  display: none;

  &:checked {
    + ${Checkbox.Label} {
      &::after {
        background-color: ${getColor("primary.dark")};
      }
    }
  }

  &:active {
    + ${Checkbox.Label} {
      &::after {
        background-color: ${getColor("base.light")};
      }
    }
  }
`;

export default Checkbox;
