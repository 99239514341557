import { Link } from "react-router-dom";

import { Menu } from "../ReactComponents/Components";
import EventTracker from "../Tracker/EventTracker";
import { ReactComponent as FAQIcon } from "./FAQ.svg";
import HeaderMenuButton from "./HeaderMenuButton";
import HeaderMenuList from "./HeaderMenuList";
import { ReactComponent as PrivacyIcon } from "./Privacy.svg";
import { ReactComponent as TermsIcon } from "./Terms.svg";

const UnauthenticatedHeaderMenu = () => {
  return (
    <Menu>
      <HeaderMenuButton />
      <HeaderMenuList>
        <EventTracker action="Hamburger menu" category="Header" />
        <Menu.Link
          as={Link}
          key="signup"
          to={`/signup/${window.location.search}`}
        >
          Sign up
        </Menu.Link>
        <Menu.Link as={Link} key="login" to="/login">
          Login
        </Menu.Link>
        <hr />
        <Menu.Link as="a" href="https://help.evidation.com" target="_blank">
          <FAQIcon />
          Help Center
        </Menu.Link>
        <Menu.Link as="a" href={`${window.env.WEBSITE_URL}/terms`}>
          <TermsIcon />
          Terms of Use
        </Menu.Link>
        <Menu.Link as="a" href={`${window.env.WEBSITE_URL}/privacy`}>
          <PrivacyIcon />
          Privacy Notice
        </Menu.Link>
        <Menu.Link
          as="a"
          href="https://evidation.com/consumer-health-data-privacy-notice"
        >
          <PrivacyIcon />
          Consumer Health Data Privacy Notice
        </Menu.Link>
      </HeaderMenuList>
    </Menu>
  );
};

export default UnauthenticatedHeaderMenu;
