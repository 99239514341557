import { decodeSearch } from "../utils/queryString";
import {
  DataType,
  transformRequestValues,
  transformResponseValues,
} from "../utils/transformValues";
import dataService, { buildRequest } from "./dataService";

const handleUserResponseV7 = (response) => {
  return {
    data: {
      ...transformResponseValues(response.data.data.attributes, DataType.USER),
    },
    included: response.data.included,
    meta: response.data.meta,
  };
};

const userService = {
  deactivate: (reason) =>
    buildRequest(() => dataService.deactivate({ deactivate_reason: reason })),

  find: (values) =>
    buildRequest(() => dataService.findUser(values), {
      transformResponse: (response) => {
        if (response.status === 404) {
          return;
        } else if (response.status === 200) {
          return transformResponseValues(response.data, DataType.USER);
        }
      },
      validResponseStatus: [200, 404],
    }),
  get: () =>
    buildRequest(dataService.getUser, {
      transformResponse: (response) => handleUserResponseV7(response),
    }),
  login: (values) => {
    const user = transformRequestValues(values, DataType.USER);
    return buildRequest(() => dataService.login(user), {
      transformResponse: (response) =>
        transformResponseValues(response.data, DataType.USER),
    });
  },
  logout: () => buildRequest(dataService.logout),
  signup: (values) => {
    const user = transformRequestValues(values, DataType.USER);
    const payload = {
      third_party_auth: user.third_party_auth,
      user: { ...user, ...decodeSearch(window.location.search) },
    };
    return buildRequest(() => dataService.signup(payload), {
      transformResponse: (response) =>
        transformResponseValues(response.data, DataType.USER),
    });
  },
  reset: (values) => buildRequest(() => dataService.resetUserPassword(values)),
  update: (values) => {
    const user = transformRequestValues(values, DataType.USER);
    return buildRequest(() => dataService.updateUser({ user }), {
      transformResponse: (response) => handleUserResponseV7(response),
    });
  },
  updateAccountPassword: (values) => {
    return buildRequest(() => dataService.changeAccountPassword(values));
  },
  updateEmail: (values) => {
    const user = transformRequestValues(values, DataType.USER);
    return buildRequest(() => dataService.updateUserV7({ user }), {
      transformResponse: (response) =>
        transformResponseValues(response.data, DataType.USER),
    });
  },
  updatePassword: (values) => {
    const password = transformRequestValues(values, DataType.PASSWORD);
    return buildRequest(() => dataService.updateUserPassword(password));
  },
  validatePassword: async (values) => {
    try {
      await userService.login(values);
    } catch (error) {
      throw new Error("Error: The current password you entered is incorrect.");
    }
  },
  exportData: () => dataService.exportData(),
};

export default userService;
