import PropTypes from "prop-types";

import CardMedia from "./CardMedia";
import CardMediaBadge from "./CardMediaBadge";

const CardMediaComponent = ({ badge, height, image }) => (
  <CardMedia height={height} image={image}>
    {badge && <CardMediaBadge>{badge}</CardMediaBadge>}
  </CardMedia>
);

CardMediaComponent.propTypes = {
  height: PropTypes.number,
  image: PropTypes.string,
};

CardMediaComponent.defaultProps = {
  height: 140,
};

export { CardMediaBadge };

export default CardMediaComponent;
