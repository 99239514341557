import { isArray, isEmpty, isError, isFunction } from "lodash";

export const FetchStatus = Object.freeze({
  IDLE: "IDLE",
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR",
});

/**
 *
 * @param {*} initialState
 */
export const getInitialState = (initialState) => ({
  error: null,
  status: FetchStatus.IDLE,
  updatedAt: null,
  ...initialState,
});

/**
 *
 */
export const getInitialPaginatedState = () =>
  getInitialState({ keys: [], pageInfo: { page: 0, hasNextPage: true } });

/**
 *
 * @param {object} state global redux state
 * @param {object} action redux action
 * @param {function} defaultFunction
 */
export const getView = (state, action, defaultFunction) => {
  const nextState = state.views[action.payload.name];

  if (!nextState) {
    if (isFunction(defaultFunction)) {
      return defaultFunction();
    }

    console.error(`Unable to find view: ${action.payload.name}`);
    return {};
  }

  return nextState;
};

/**
 *
 * @param {*} state
 */
export const fetchRequest = (state) => {
  state.error = null;
  state.status = FetchStatus.LOADING;
};

/**
 *
 * @param {*} state
 */
export const fetchResponse = (state) => {
  state.status = FetchStatus.LOADED;
  state.updatedAt = new Date().toString();
};

/**
 *
 * @param {*} state
 */
export const fetchError = (state, action) => {
  state.error = action.payload ? action.payload.error : null;
  state.status = FetchStatus.ERROR;
};

export const defaultActions = {
  fetchRequest,
  fetchResponse,
  fetchError,
};

/**
 *
 * @param {object} view
 */
export const isIdle = (view) => view.status === FetchStatus.IDLE;

/**
 *
 * @param {object} view
 */
export const isLoading = (view) => view.status === FetchStatus.LOADING;

/**
 *
 * @param {object} view
 */
export const isLoaded = (view) => view.status === FetchStatus.LOADED;

/**
 *
 * @param {object} view
 */
export const getPage = (view) => {
  if (!view || !view.pageInfo || !view.pageInfo.page) {
    return 1;
  }

  return view.pageInfo.page;
};

/**
 *
 * @param {object} entities an array of entities
 * @param {function} transform a function to transform an entity
 */
export const transformEntities = (entities, transform) => {
  if (!isArray(entities)) {
    console.warn("The data provided is not an array.");
    return [];
  }

  return entities.map(transform);
};

/**
 * Return entities mapped by a provided key
 * @param {array} entities
 * @param {string} key
 * @return {object}
 */
export const mapEntitiesByKey = (entities, key = "id") =>
  entities.reduce(
    (accumulator, entity) => ({
      ...accumulator,
      [entity[key]]: entity,
    }),
    {}
  );

/**
 *
 * @param {object} fetchState
 * @return {boolean}
 */
export const shouldFetch = (fetchState) => {
  if (fetchState.inFlight) {
    return false;
  }

  if (fetchState.isComplete) {
    if (
      (isEmpty(fetchState.data) && isEmpty(fetchState.entities)) ||
      isError(fetchState.error)
    ) {
      return true;
    }

    return false;
  }

  return true;
};

/**
 *
 * @param {object} fetchState
 * @return {boolean}
 */
export const shouldFetchNext = (fetchState) => {
  if (
    !fetchState.pageInfo ||
    !fetchState.pageInfo.currentPage ||
    !fetchState.pageInfo.hasNextPage ||
    fetchState.inFlight
  ) {
    return false;
  }

  return true;
};
