import PropTypes from "prop-types";
import React from "react";

import NavigationBar from "./NavigationBar";

/**
 * @render react
 * @name NavigationBar
 * @description Achievement NavigationBar
 * @example
 * <NavigationBar>
 *   <NavigationBar.Link active href="#">Activity</NavigationBar.Link>
 *   <NavigationBar.Link href="#">Apps</NavigationBar.Link>
 *   <NavigationBar.Link href="#">Studies</NavigationBar.Link>
 *   <NavigationBar.Link href="#">Account</NavigationBar.Link>
 * </NavigationBar>
 */

const NavigationBarComponent = ({ children }) => (
  <NavigationBar>
    <NavigationBar.List>
      {React.Children.map(children, (child) => (
        <NavigationBar.ListItem>{child}</NavigationBar.ListItem>
      ))}
    </NavigationBar.List>
  </NavigationBar>
);

NavigationBarComponent.Link = NavigationBar.Link;

NavigationBarComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export { NavigationBar };

export default NavigationBarComponent;
