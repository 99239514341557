import React from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { AuthState, useAuthState } from "@achievement/portal-client";

import routes from "../../pages/routes";
import { useRedirect } from "../../utils/hooks";

const SEARCH_PARAM_REDIRECT = "redirect";
const SEARCH_PARAM_SUCCESS = "success";
const SEARCH_PARAM_MESSAGE = "message";

const UnauthenticatedGate: React.FunctionComponent<any> = ({ children }) => {
  const { redirect, setRedirect } = useRedirect();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { authState } = useAuthState();

  React.useEffect(() => {
    // save redirect if the user is unauthenticated
    if (authState === AuthState.UNAUTHENTICATED) {
      const redirect = searchParams.get(SEARCH_PARAM_REDIRECT);

      if (redirect) {
        setRedirect(redirect);
      }
    }
  }, [authState, redirect, searchParams, setRedirect]);

  React.useEffect(
    () => {
      if (authState === AuthState.AUTHENTICATED) {
        const success = searchParams.get(SEARCH_PARAM_SUCCESS) ?? "";
        const message = searchParams.get(SEARCH_PARAM_MESSAGE) ?? "";
        navigate(routes.onboarding, {
          state: {
            action: "authenticated",
            redirect,
            notificationSearch:
              success && message
                ? createSearchParams({ success, message }).toString()
                : undefined,
          },
        });
      }
    },
    /* eslint-disable */
    []
  );

  return children;
};

export default UnauthenticatedGate;
