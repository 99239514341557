import { rem } from "polished";
import styled from "styled-components";

const Copyright = styled.div`
  font-size: ${rem(12)};
  margin-top: ${rem(40)};
  text-align: center;
`;

export default Copyright;
