import PropTypes from "prop-types";
import React from "react";

import Error from "../Error";

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError = (error) => {
    return { hasError: true, error };
  };

  componentDidCatch = (error, info) => {
    // TODO(markgaucher): implement error reporting service
    this.setState({
      error,
      info,
    });
  };

  render() {
    if (this.state.hasError) {
      return <Error />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

export default ErrorBoundary;
