import styled from "styled-components";

const PointsBadge = styled.div`
  align-items: center;
  display: flex;
`;

PointsBadge.Content = styled.div`
  alignitems: "center";
  background-color: ${(props) => props.color ?? "#32C58A"};
  border-color: #ffffffbf;
  border-radius: 23px;
  border-width: 2px;
  display: flex;
  flex-direction: "row";
  height: 30px;
  justify-content: "center";
  padding: 0px 16px;
`;

PointsBadge.Text = styled.div`
  color: #ffffff;
  font-size: 14px;
  font-family: PublicSans-SemiBold;
  line-height: 30px;
  text-align: center;
`;

export default PointsBadge;
