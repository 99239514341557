import PropTypes from "prop-types";
import React from "react";

import Grid from "../Grid";
import Space from "../Space";
import Copyright from "./Copyright";
import Footer from "./Footer";
import FooterMenu from "./FooterMenu";
import Logo from "./Logo";

/**
 * @render react
 * @name Footer
 * @description Achievement Footer Component
 * @example
* <Footer navigation={{
  About: [
    {
      children: 'Our Story',
      href: '#',
    },
    {
      children: 'Company',
      href: '#',
    },
    {
      children: 'Jobs',
      href: '#',
    },
    {
      children: 'Blog',
      href: '#',
    },
  ],
  Support: [
    {
      children: 'FAQ',
      href: '#',
    },
    {
      children: 'Help Center',
      href: '#',
    },
    {
      children: 'Terms of Use',
      href: '#',
    },
    {
      children: 'Privacy Policy',
      href: '#',
    },
  ],
  Contact: [
    {
      children: 'Email Us',
      href: '#',
    },
    {
      children: 'Company',
      href: '#',
    },
  ],
  Social: [
    {
      children: 'Facebook',
      href: '#',
    },
    {
      children: 'Instagram',
      href: '#',
    },
    {
      children: 'Twitter',
      href: '#',
    },
  ],
* }} />
 */

const FooterComponent = ({
  copyright,
  description,
  logo,
  navigation,
  renderItem,
}) => {
  return (
    <Footer>
      <Grid>
        <Grid.Flex
          flexWrap="wrap"
          justifyContent="space-between"
          px={[3, 3, 3, 0]}
        >
          <Grid.Box
            mb={[4, 4, 4, 0]}
            pr={[0, 0, 0, 3]}
            width={[1, 1 / 2, 4.5 / 10, 1 / 2]}
          >
            {logo && (
              <Space component="div" mb={3}>
                {logo}
              </Space>
            )}
            <Footer.Description>{description}</Footer.Description>
          </Grid.Box>
          <Grid.Box width={[1, 1, 5.5 / 10, 1 / 2]}>
            <Grid.Flex flexWrap="wrap">
              {Object.keys(navigation).map((key) => (
                <Grid.Box
                  key={key}
                  mr={0}
                  pl={[0, 0, 3, 3]}
                  width={[1 / 2, 1 / 2, 1 / 3, 1 / 3]}
                >
                  <FooterMenu key={key}>
                    <FooterMenu.Heading>{key}</FooterMenu.Heading>
                    <FooterMenu.List>
                      {navigation[key].map((item) => (
                        <FooterMenu.ListItem key={item.children}>
                          {renderItem(item)}
                        </FooterMenu.ListItem>
                      ))}
                    </FooterMenu.List>
                  </FooterMenu>
                </Grid.Box>
              ))}
            </Grid.Flex>
          </Grid.Box>
        </Grid.Flex>
      </Grid>
      <Copyright>{copyright}</Copyright>
    </Footer>
  );
};

FooterComponent.propTypes = {
  copyright: PropTypes.string,
  description: PropTypes.node,
  logo: PropTypes.element,
  navigation: PropTypes.object,
  renderItem: PropTypes.func,
};

FooterComponent.defaultProps = {
  copyright: `© 2019 Evidation Health, all rights reserved.`,
  description: (
    <React.Fragment>
      Achievement is a product of{" "}
      <a href="https://www.evidation.com">Evidation Health.</a> We reward you
      for health actions. For more information go to{" "}
      <a href="https://www.myachievement.com">www.myachievement.com</a>.
    </React.Fragment>
  ),
  logo: <Logo />,
  navigation: {},
  renderItem: ({ children, ...props }) => <a {...props}>{children}</a>,
};

export { Copyright, Footer, FooterMenu, Logo };

export default FooterComponent;
