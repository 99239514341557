/**
 * Returns a formatted weight with a suffix
 * @param {number} value The weight value
 * @param {string} suffix A value to be appended to the weight
 * @return {string}
 */
export const toWeight = (value, suffix = "lbs") =>
  value ? `${value.trim()} ${suffix}` : "";

/**
 * Returns a weight value as a number
 * @param {string} value The weight value
 * @return {number}
 */
export const fromWeight = (value) => value.split(" ")[0];
