import get from "lodash/get";
import { rem } from "polished";
import styled from "styled-components";

const getColor = (color) => (props) =>
  get(props, `theme.palette.${color}`, "#eb3f54");

const theme = {
  height: rem(60),
};

const Header = styled.header`
  background-color: ${getColor("base.lightest")};
  border-bottom: 1px solid ${getColor("base.lighter")};
  box-shadow: 0 1px 0 0 ${getColor("base.light")};
  height: ${theme.height};

  svg {
    display: block;
  }
`;

export default Header;
