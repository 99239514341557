import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import FieldError from "../FieldError";
import { Input } from "../Input";
import Label from "../Label";
import { fromWeight, toWeight } from "./utils";

class Weight extends React.PureComponent {
  handleBlur = (event) => {
    this.props.form.setFieldValue(
      this.props.field.name,
      toWeight(event.target.value)
    );
    this.props.field.onBlur(event);
  };

  handleChange = (event) => {
    this.props.form.setFieldValue(this.props.field.name, event.target.value);
    this.props.field.onChange(event);
  };

  handleFocus = (event) => {
    this.props.form.setFieldValue(
      this.props.field.name,
      fromWeight(event.target.value)
    );
  };

  render() {
    const {
      field: { name, value, ...field },
      form: { errors = {}, touched = {} },
      label,
      ...props
    } = this.props;

    const hasError = touched[name] && !isEmpty(errors[name]);

    return (
      <React.Fragment>
        {hasError && <FieldError>{errors[name]}</FieldError>}
        <Input
          {...field}
          {...props}
          hasError={hasError}
          name={name}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          value={value}
        />
        <Label hasError={hasError}>{label}</Label>
      </React.Fragment>
    );
  }
}

Weight.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
};

export { fromWeight, toWeight };

export default Weight;
