import { get } from "lodash";
import { rem } from "polished";
import styled, { css } from "styled-components";

const PointHubButton = styled.button`
  border: 1px solid ${(props) => get(props, "theme.palette.base.light")};
  border-radius: 4px;
  color: ${(props) => get(props, "theme.palette.base.darker")};
  height: 100%;
  line-height: 1;
  position: relative;
  width: 100%;

  ${(props) =>
    props.active &&
    css`
      background-color: ${get(props, "theme.palette.primary.dark")};
      border-color: ${get(props, "theme.palette.primary.dark")};
      color: ${get(props, "theme.palette.base.lightest")};
    `}
`;

PointHubButton.Label = styled.span`
  display: block;
  font-size: ${rem(11)};
  left: ${rem(8)};
  position: absolute;
  top: ${rem(8)};
`;

PointHubButton.Date = styled.span`
  display: block;
  font-size: ${rem(10)};
  padding-top: 1px;
  position: absolute;
  right: ${rem(8)};
  top: ${rem(8)};
`;

PointHubButton.Value = styled.span`
  bottom: ${rem(8)};
  display: block;
  font-size: ${rem(24)};
  font-family: PublicSans-SemiBold;
  left: ${rem(8)};
  position: absolute;
`;

export default PointHubButton;
