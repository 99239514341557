export const buttonTheme = {
  LIGHT: {
    backgroundColor: "#e8e8e8",
    color: "#2a3f53",
  },
  DARK: {
    backgroundColor: "#5b6b7d",
    color: "#fff",
  },
  PRIMARY: {
    backgroundColor: "#0f5fbc",
    color: "#fff",
  },
  SECONDARY: {
    backgroundColor: "#37d596",
    color: "#fff",
  },
  NONE: {
    backgroundColor: "transparent",
    color: "#5b6b7d",
  },
};

const ButtonColor = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
  NONE: "NONE",
};

export default ButtonColor;
