import { Menu, MenuItem, MenuLink } from "./menu-button/src";
import MenuButton from "./MenuButton";
import MenuList from "./MenuList";

export { Menu, MenuButton, MenuItem, MenuLink, MenuList };

Menu.Button = MenuButton;
Menu.Item = MenuItem;
Menu.Link = MenuLink;
Menu.List = MenuList;

export default Menu;
