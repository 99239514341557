import PropTypes from "prop-types";

import Checkbox from "./Checkbox";

/**
 * @render react
 * @name Checkbox
 * @description Achievement Checkbox Component
 * @example
 * <Checkbox>
 *   <Checkbox.Input name="checkbox" id="checkbox" type="checkbox" />
 *   <Checkbox.Label htmlFor="checkbox">Lorem ipsum dolar sit amet</Checkbox.Label>
 * </Checkbox>
 */

const CheckboxComponent = ({ children, field, form, offset, ...props }) => (
  <Checkbox>
    <Checkbox.Input {...field} {...props} type="checkbox" />
    <Checkbox.Label htmlFor={props.id} offset={offset === true ? 1 : 0}>
      {children}
    </Checkbox.Label>
  </Checkbox>
);

CheckboxComponent.propTypes = {
  offset: PropTypes.bool,
};

CheckboxComponent.defaultProps = {
  offset: false,
};

export { Checkbox };

export default CheckboxComponent;
