import { deserialise } from "kitsu-core";
import { includes } from "lodash";
import { useCallback, useEffect, useReducer, useState } from "react";

import apiService, { unauthenticatedPortalService } from "./apiService";
import eventService from "./eventService";

/**
 * Handler for v7 JSON API responses
 * @param request
 */
const handleResponse = async (request) => {
  const response = await request();
  const { data, errors } = response;

  if (response.status !== 200 && errors && errors.length > 0) {
    throw new Error(errors[0]);
  }

  return deserialise(data);
};

const dataService = {
  login: (payload) => apiService.post("v7/auth/login", payload),
  logout: () => apiService.post("v7/auth/logout"),
  signup: (payload) => unauthenticatedPortalService.post("v7/auth", payload),
  resetUserPassword: (payload) =>
    unauthenticatedPortalService.post("v6/account/password/reset", payload),
  updateUserPassword: (payload) =>
    unauthenticatedPortalService.post("v6/account/password/update", payload),
  changeAccountPassword: (payload) =>
    apiService.post("v7/auth/change_password", payload),
  deactivate: (payload) => apiService.post("v4/user/deactivate", payload),

  getConfig: () =>
    handleResponse(() => unauthenticatedPortalService.get("v7/config")),

  getContent: (identifier) =>
    handleResponse(() => apiService.get(`v7/content/${identifier}`)),

  getUser: () => apiService.get("v7/profile"),
  findUser: (params) =>
    unauthenticatedPortalService.get("v6/auth/identify", { params }),
  updateUser: (payload) => apiService.put("v7/profile", payload),
  updateUserV7: (payload) => apiService.put("v7/profile", payload),

  startCCPA: (payload) =>
    apiService.post("v6/user/start_ccpa_process", payload),

  preferences: () => apiService.get("v6/preferences"),
  updatePreferences: (payload) => apiService.put("v6/preferences", payload),
  updateSubscription: (payload) =>
    unauthenticatedPortalService.put("v4/user/email_subscription", payload),

  disconnectApplication: (id) =>
    apiService.get(`v6/applications/${id}/disconnect`),

  getEventAggregate: (params) =>
    apiService.get("v7/events/aggregate", { params }),

  getEvents: async (params) => {
    const response = await apiService.get("v6/events", { params });
    return eventService.transformMany(response.data);
  },

  getPreferences: () => handleResponse(() => apiService.get("v7/preferences")),
  updateBasePreferences: (payload) =>
    handleResponse(() => apiService.put("v7/preferences", payload)),
  updateProgramPreferences: (id, payload) =>
    handleResponse(() =>
      apiService.patch(`v7/programs/${id}/preferences`, payload)
    ),

  getProgram: (id) => handleResponse(() => apiService.get(`v7/programs/${id}`)),

  getPrograms: () => handleResponse(() => apiService.get("v7/programs")),

  programEnrollment: (id) =>
    handleResponse(() => apiService.put(`v7/programs/${id}/enroll`)),

  requestDiaryReport: (id) =>
    handleResponse(() => apiService.post(`v7/programs/${id}/reports/send`)),

  getOutcomeDiary: (id) =>
    handleResponse(() => apiService.get(`v7/outcome_diaries/${id}`)),

  completeOutcomeDiary: (id, payload) =>
    handleResponse(() =>
      apiService.put(`v7/outcome_diaries/outcomes/${id}`, { data: payload })
    ),

  getOutcomeDiaryInsight: (id) =>
    handleResponse(() =>
      apiService.get(`v7/outcome_diaries/outcomes/${id}/insights`)
    ),

  downloadInsight: (id) => apiService.get(`v7/insights/${id}`),

  getOffer: (identifier, kind) =>
    handleResponse(() =>
      apiService.get(`v7/offers/${identifier}`, { params: { kind } })
    ),
  getOfferList: () => handleResponse(() => apiService.get(`v7/offers`)),
  completeOffer: (identifier, params) =>
    apiService.get(`v6/offers/${identifier}/complete`, { params }),
  completeOfferV7: (identifier, payload) =>
    handleResponse(() =>
      apiService.post(`v7/offers/${identifier}/complete`, payload)
    ),
  dismissOffer: (identifier, kind) =>
    handleResponse(() =>
      apiService.post(`v7/offers/${identifier}/dismiss`, { kind: kind })
    ),
  impressOffer: (identifier, kind, params) =>
    handleResponse(() =>
      apiService.post(`v7/offers/${identifier}/impress`, { kind, ...params })
    ),
  inviteCode: (payload) =>
    handleResponse(() => apiService.post(`v7/offers/invite`, payload)),
  rateOffer: (identifier, params) =>
    apiService.get(`v6/offers/${identifier}/rate`, { params }),
  redeemReward: (payload) => apiService.post("v6/reward/redeem", payload),
  getCharityList: (params) =>
    apiService.get("v6/reward/charities/search", { params }),

  getTerms: () =>
    unauthenticatedPortalService.get("v4/legal_documents/terms_of_service"),
  getPrivacyPolicy: () =>
    unauthenticatedPortalService.get("v4/legal_documents/privacy_policy"),
  exportData: () => apiService.get("v4/events/download"),
  getCCPADownload: ({ requestId, env }) =>
    apiService.get(`v6/user/ccpa_downloads/${requestId}`, { params: { env } }),
};

const getResponseError = (response) => {
  if (!response || !response.data) {
    return "The value provided is not a response.";
  }

  const data = response.data;

  if (Array.isArray(data) && data.length) {
    if (data[0].message) {
      return data[0].message;
    }
    if (data[0].error) {
      return data[0].error;
    }
  }

  if (data.message) {
    return data.message;
  }

  // handle jsonapi error responses
  if (Array.isArray(data.errors) && data.errors.length) {
    return data.errors[0].detail;
  }

  return "An unknown error has occurred.";
};

const getError = (error) => {
  if (!error || !error.response || !error.response.data) {
    return error;
  }

  const data = error.response.data;

  if (data.message) {
    return data.message;
  }

  if (Array.isArray(data) && data.length) {
    if (data[0].message) {
      return data[0].message;
    }

    if (data[0].error) {
      return data[0].error;
    }
  }

  return error;
};

const defaultRequestOptions = {
  fetchOnLoad: true,
  transformResponse: (response) => response.data,
  validResponseStatus: [200, 204],
};

export const buildRequest = async (dataServiceFunc, requestOptions = {}) => {
  try {
    const { transformResponse, validResponseStatus } = {
      ...defaultRequestOptions,
      ...requestOptions,
    };

    const response = await dataServiceFunc();
    if (!includes(validResponseStatus, response.status)) {
      throw new Error(getResponseError(response));
    }

    if (!transformResponse || typeof transformResponse !== "function") {
      return;
    }

    return transformResponse(response);
  } catch (error) {
    throw new Error(getError(error));
  }
};

const actionTypes = {
  FAILURE: "FAILURE",
  REQUEST: "REQUEST",
  SUCCESS: "SUCCESS",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FAILURE:
      return {
        error: action.error,
        isLoading: false,
        isComplete: true,
      };
    case actionTypes.REQUEST:
      return {
        error: null,
        isLoading: true,
        isComplete: false,
        updatedDate: new Date(),
      };
    case actionTypes.SUCCESS:
      return {
        data: action.data,
        isLoading: false,
        isComplete: true,
      };
    default:
      return {
        error: null,
        isLoading: false,
        isComplete: false,
      };
  }
};

export const useFetch = (
  request,
  {
    defaultParams = {},
    fetchOnLoad = true,
    transformResponse = (response) => response.data,
    validResponseStatus = [200, 204, 304],
    onSuccess = null,
  }
) => {
  const [active, setActive] = useState(true);
  const [state, dispatch] = useReducer(reducer, {
    error: null,
    isLoading: fetchOnLoad,
    isComplete: false,
  });

  const handleFetch = useCallback(async (params) => {
    dispatch({ type: actionTypes.REQUEST });

    try {
      const response = await request(params);

      if (!includes(validResponseStatus, response.status)) {
        throw new Error(getResponseError(response));
      }

      const data = await transformResponse(response);

      if (active) {
        dispatch({ type: actionTypes.SUCCESS, data });
        onSuccess?.(data);
      }
    } catch (error) {
      if (active) {
        dispatch({ type: actionTypes.FAILURE, error });
      }
    }
  }, []);

  useEffect(() => {
    if (fetchOnLoad) {
      handleFetch(defaultParams);
    }

    return () => {
      setActive(false);
    };
  }, []);

  return [
    {
      data: state.data,
      error: state.error,
      isLoading: state.isLoading,
      isComplete: state.isComplete,
      updatedDate: state.updatedDate,
    },
    handleFetch,
  ];
};

export default dataService;
