import types from "./types";

const entitiesEventAggregateReducer = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_EVENT_AGGREGATE_REQUEST:
      if (action.payload && action.payload.period) {
        return {
          ...state,
          [action.payload.period]: null,
        };
      }
      return state;
    case types.FETCH_EVENT_AGGREGATE_RESPONSE:
      if (action.payload && action.payload.data) {
        return {
          ...state,
          ...action.payload.data.attributes,
        };
      }

      return state;
    case types.FETCH_EVENT_AGGREGATE_ERROR:
      return {
        ...state,
        day: { error: action.error },
        week: { error: action.error },
        month: { error: action.error },
      };
    default:
      return state;
  }
};

export default entitiesEventAggregateReducer;
