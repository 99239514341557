import React from "react";

import Grid from "../Grid";
import Panel from "../Panel";
import ApplicationPanel from "./ApplicationPanel";

const ApplicationPanelErrorState = () => (
  <Panel p={0} style={{ height: "100%" }}>
    <Grid>
      <Grid.Flex flexWrap="wrap" p={4}>
        <Grid.Box flex="0 0 auto" mr={2} mt={1}>
          <svg
            height="48"
            viewBox="0 0 90 90"
            width="48"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none" fillRule="evenodd">
              <circle cx="45" cy="45" fill="#FE7345" r="45" />
              <path
                d="M40.398 52.937l-.716-36.062h10.023l-.716 36.062h-8.591zM45 70.313a5.625 5.625 0 1 0 0-11.25 5.625 5.625 0 0 0 0 11.25z"
                fill="#FFF"
                fillRule="nonzero"
              />
            </g>
          </svg>
        </Grid.Box>
        <Grid.Box pr={1} width={4 / 5}>
          <ApplicationPanel.Name>Oh no...</ApplicationPanel.Name>
          <ApplicationPanel.Description>
            An error has occurred and we are working to fix the problem!
          </ApplicationPanel.Description>
        </Grid.Box>
      </Grid.Flex>
    </Grid>
  </Panel>
);

export default ApplicationPanelErrorState;
