import "react-app-polyfill/ie11";
import "core-js/es";

import { evidationTheme, NativeBaseProvider } from "@evidation-shared/eve";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "@achievement/portal-client";

import App from "./App";
import { getAuthConfig } from "./config/amplify";
import configureStore from "./modules";
import { initializeTracking } from "./services/trackingService";

const authConfig = getAuthConfig();

const store = configureStore();
initializeTracking();

ReactDOM.render(
  <NativeBaseProvider theme={evidationTheme}>
    <Provider store={store}>
      <AuthProvider config={authConfig}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </Provider>
  </NativeBaseProvider>,
  document.getElementById("root")
);
