import { UserProfile } from "@achievement/portal-client";
import { toPhoneNumber } from "./number";

import { DataType, transformResponseValues } from "./transformValues";

const toFeet = (value: number) => Math.floor(value * 0.03280841666);
const toInches = (value: number) => (Math.round(value * 0.393701 * 2) / 2) % 12;

export const formatHeight = (value: number) =>
  `${toFeet(value)}'${toInches(value)}"`;

/**
 * Format the user profile phone number.
 */
export const formatPhoneNumber = (phoneNumber?: string): string | undefined => {
  if (!phoneNumber) {
    return undefined;
  }
  return toPhoneNumber(phoneNumber, { obfuscated: true });
};

export const getPoints = (user: any) => {
  if (!user || !user.points) {
    return 0;
  }

  return user.points.toLocaleString();
};

export const canRedeem = (user: any) => {
  if (!user || !user.goal || !user.points) {
    return false;
  }

  return user.points >= user.goal;
};

/**
 * Returns a boolean for whether or not a users data should be obfuscated
 *
 * @param user UserContext.user
 */
export const isUserOTPExpired = (user: any) =>
  !user.otpVerificationExpiresAt ||
  new Date() >= new Date(user.otpVerificationExpiresAt);

/**
 * Formats the response from the verifyOTP endpoint into for expected by the UserContext.updateUser function
 *
 * @param user_included current UserContext.user_included
 * @param profile UserProfile response from portal-client
 */
export const transformVerifyOTPResponse = (
  user_included: any[],
  profile: UserProfile
) => {
  const {
    birthDate,
    phoneNumber,
    selfIdentifiedRaceOrEthnicity,
    otpVerificationExpiresAt,
    zipCode,
  } = transformResponseValues(profile, DataType.USER);
  const included = user_included.map((ethnicity) => ({
    ...ethnicity,
    attributes: {
      ...ethnicity.attributes,
      selected:
        profile.races_and_ethnicities?.data?.find(
          (profile_ethnicity) =>
            profile_ethnicity.id === ethnicity.id &&
            ethnicity.type === "races_and_ethnicity"
        )?.selected || false,
    },
  }));
  return {
    data: {
      birthDate,
      phoneNumber,
      selfIdentifiedRaceOrEthnicity,
      otpVerificationExpiresAt,
      zipCode,
    },
    included: included,
  };
};
