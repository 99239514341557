import queryString from "query-string";
import React, { useContext } from "react";

import { Tooltip } from "../ReactComponents/Core";

import { NavigationBar } from "../ReactComponents/Components";

import UserContext from "../../context/UserContext";
import { usePrograms, HEART_HEALTH_IDENTIFIER } from "../../modules/programs";
import { ReactComponent as AccountIcon } from "../HeaderMenu/Account.svg";
import { ReactComponent as ActivityIcon } from "../HeaderMenu/Activity.svg";
import { ReactComponent as ApplicationIcon } from "../HeaderMenu/Application.svg";
import { ReactComponent as HPAIcon } from "../HeaderMenu/HeartHealthDiary.svg";
import Match from "../Match";
import ReachLink from "../ReachLink";

const AuthenticatedNavigationBar = () => {
  const { user } = useContext(UserContext);
  const { data } = usePrograms({ enabled: user?.accepted_tos !== null });

  const { action } = queryString.parse(window.location.search);
  const programs = data?.data;

  const heartHealthProgram = programs?.find((program) => {
    return program.identifier === HEART_HEALTH_IDENTIFIER;
  });

  const [tabs, setTabs] = React.useState([]);

  React.useEffect(() => {
    const defaultTabs = [
      <Match path="/member/activity/*">
        {({ match }) => (
          <NavigationBar.Link
            active={match ? "true" : undefined}
            as={ReachLink}
            data-testid="navigation-bar--activity"
            to="/member/activity"
          >
            <ActivityIcon />
            Activity
          </NavigationBar.Link>
        )}
      </Match>,
      <Match path="/member/applications/*">
        {({ match }) => (
          <NavigationBar.Link
            active={match ? "true" : undefined}
            as={ReachLink}
            data-testid="navigation-bar--apps"
            to="/member/applications"
          >
            <ApplicationIcon />
            Apps
          </NavigationBar.Link>
        )}
      </Match>,
      <Match path="/member/account/*">
        {({ match }) => (
          <NavigationBar.Link
            active={match ? "true" : undefined}
            as={ReachLink}
            data-testid="navigation-bar--account"
            to="/member/account"
          >
            <AccountIcon />
            Account
          </NavigationBar.Link>
        )}
      </Match>,
    ];
    if (heartHealthProgram) {
      const newTabs = [
        ...defaultTabs.slice(0, 1),
        <Match path="/member/programs/:identifier/*">
          {({ match }) => (
            <div style={{ position: "relative" }}>
              <NavigationBar.Link
                active={
                  match && match.params.identifier === HEART_HEALTH_IDENTIFIER
                    ? "true"
                    : undefined
                }
                as={ReachLink}
                data-testid="navigation-bar--hpa"
                to={`/member/programs/${HEART_HEALTH_IDENTIFIER}`}
              >
                <HPAIcon />
                Heart Health Diary
              </NavigationBar.Link>
              {action &&
                action === "tooltip" &&
                heartHealthProgram.tab_tooltip_title && (
                  <div style={{ textTransform: "none" }}>
                    <Tooltip
                      anchor="center"
                      dismissable
                      initialVisible
                      overlay
                      position="bottom"
                      text={heartHealthProgram.tab_tooltip_body}
                      title={heartHealthProgram.tab_tooltip_title}
                      width={350}
                    />
                  </div>
                )}
            </div>
          )}
        </Match>,
        ...defaultTabs.slice(1),
      ];
      setTabs(newTabs);
    } else {
      setTabs(defaultTabs);
    }
  }, [programs, action]);

  return <NavigationBar>{tabs}</NavigationBar>;
};

export default AuthenticatedNavigationBar;
