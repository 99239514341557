import { rem } from "polished";
import styled from "styled-components";

import Panel, { PanelColor } from "../Panel";

const EventPanel = styled(Panel).attrs({
  color: PanelColor.WHITE,
  p: 4,
})`
  font-size: ${rem(14)};
  padding: ${rem(16)};

  &:not(:last-child) {
    margin-bottom: ${rem(8)};
  }
`;

EventPanel.Icon = styled.img.attrs({
  "data-testid": "event-icon",
})`
  display: block;
  height: auto;
  width: ${rem(40)};
`;

EventPanel.Description = styled.div.attrs({
  "data-testid": "event-description",
})`
  color: #2a3f53;
  font-family: PublicSans-SemiBold;
`;

EventPanel.Meta = styled.div.attrs({
  "data-testid": "event-meta",
})`
  font-size: ${rem(12)};
`;

EventPanel.Reward = styled.div.attrs({
  "data-testid": "event-reward",
})`
  border: 1px solid #f8f8f8;
  border-radius: ${rem(3)};
  font-family: PublicSans-SemiBold;
  padding: ${rem(4)} ${rem(8)};
  white-space: nowrap;
`;

export default EventPanel;
