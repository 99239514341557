import { get } from "lodash";
import { rem } from "polished";
import styled, { css } from "styled-components";

import Arrow from "./Arrow.svg";

const Select = styled.select`
  background-color: transparent;
  background-image: url("${Arrow}");
  background-position: center right ${rem(-6)};
  background-repeat: no-repeat;
  border-bottom: 2px solid ${(props) => get(props, "theme.select.borderColor")};
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-radius: 0;
  color: ${(props) =>
    get(
      props,
      props.value !== "" ? "theme.select.color" : "theme.input.borderColor"
    )};
  font-size: ${rem(20)};
  line-height: 1.5;
  padding-bottom: ${rem(10)};
  padding-left: 0;
  padding-right: ${rem(24)};
  padding-top: ${rem(10)};
  width: 100%;

  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  option {
    color: ${(props) => get(props, "theme.select.color")};
  }

  ${(props) => css`
    &::placeholder {
      color: ${get(props, "theme.select.borderColor")};
    }
  `};
`;

export default Select;
