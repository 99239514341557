const routes = {
  login: "/login",
  logout: "/logout",
  member: "/member/activity",
  onboarding: "/onboarding",
  onboardingApplications: "/onboarding/applications",
  onboardingApplicationConnect: (applicationIdentifier = "") => {
    return `/onboarding/applications/${applicationIdentifier}/connect`;
  },
  onboardingComplete: "/onboarding/complete",
  onboardingProgramCelebration: ({ programIdentifier = "" }) => {
    return `/onboarding/programs/${programIdentifier}/celebration`;
  },
  onboardingProgramSummary: ({
    offerIdentifier = "",
    programIdentifier = ":programIdentifier",
  }) => {
    const route = `/onboarding/programs/${programIdentifier}/summary`;

    if (!!offerIdentifier) {
      return route + `?offerIdentifier=${offerIdentifier}`;
    }

    return route;
  },
  onboardingProgramTerms: ({
    offerIdentifier = "",
    programIdentifier = ":programIdentifier",
  }) => {
    const route = `/onboarding/programs/${programIdentifier}/terms`;

    if (!!offerIdentifier) {
      return route + `?offerIdentifier=${offerIdentifier}`;
    }

    return route;
  },
  onboardingSignup: "/onboarding/signup",
  onboardingSuccess: "/onboarding/success",
  signup: "/signup",

  /* Misc Links */
  appStore: "https://apps.apple.com/us/app/evidation-reward-health/id793039965",
  playStore:
    "https://play.google.com/store/apps/details?id=com.achievemint.android",
  pointsExpirationFAQ:
    "https://help.evidation.com/hc/en-us/articles/7957584154003-Can-my-Evidation-points-expire-",
};

export default routes;
