import React from "react";
import { useLocation } from "react-router";

import sessionStorageService from "../../services/sessionStorageService";

const useRedirect = () => {
  const location = useLocation();
  const [redirect, setRedirect] = React.useState<string | undefined>();

  const setSessionRedirect = (updatedRedirect: string) => {
    setRedirect(updatedRedirect);
    sessionStorageService.setRedirect(updatedRedirect);
  };

  // set default redirect on mount
  React.useEffect(
    () => {
      /* @ts-ignore */
      if (location.state?.redirect) {
        /* @ts-ignore */
        const updatedRedirect = location.state?.redirect;
        setSessionRedirect(updatedRedirect);
      } else {
        const sessionRedirect = sessionStorageService.getRedirect();
        if (sessionRedirect) {
          setRedirect(sessionRedirect);
        }
      }
    },
    /* eslint-disable */
    []
  );

  return {
    redirect,
    resetRedirect: () => {
      setRedirect(undefined);
      sessionStorageService.removeRedirect();
    },
    setRedirect: setSessionRedirect,
  };
};

export default useRedirect;
