import { Box, Text } from "@evidation-shared/eve";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

import PointHub from "../../blocks/header/PointHub";
import trackingService, { Events } from "../../services/trackingService";
import { canRedeem } from "../../utils/user";
import PointsExpirationInlineDialog from "../PointsExpirationInlineDialog";
import {
  Breakpoint,
  Button,
  ButtonColor,
  ButtonSize,
  Grid,
  MediaQuery,
  Menu,
  PointBar,
  Space,
  Typography,
} from "../ReactComponents/Components";
import EventTracker from "../Tracker/EventTracker";
import AccountMenuButton from "./AccountMenuButton";
import AccountMenuList from "./AccountMenuList";

const AccountMenu = ({ user }) => {
  const navigate = useNavigate();

  return (
    <Menu>
      {({ close }) => (
        <React.Fragment>
          <AccountMenuButton user={user} />
          <AccountMenuList>
            <EventTracker action="View points dropdown" category="Account" />
            <Menu.Item onSelect={() => {}} style={{ height: 0, padding: 0 }} />
            <PointBar max={user?.goal ?? 0} value={user?.points ?? 0} />
            <MediaQuery maxWidth={Breakpoint.SMALL}>
              {canRedeem(user) && (
                <Space mt={2}>
                  <Button
                    color={ButtonColor.SECONDARY}
                    data-testid="redeem--menu-button"
                    onClick={() => {
                      close();
                      navigate("/member/reward");
                    }}
                    uppercase
                    width={100}
                  >
                    Redeem
                  </Button>
                </Space>
              )}
            </MediaQuery>
            {user &&
            (user.points > 0 ||
              user.pointsQualificationStatus === "disqualified") ? (
              <Box mt={4}>
                <PointsExpirationInlineDialog
                  lastExpiredAt={user.lastExpiredAt}
                  nextExpiration={user.nextPointsExpiration}
                  status={user.pointsQualificationStatus}
                />
              </Box>
            ) : (
              <div />
            )}
            <Box ml={4} mt={4}>
              <Text
                fontSize={21}
                fontWeight="bold"
              >
                Health, powered by you
              </Text>
            </Box>
            <PointHub />
            <Space as="div" mb={3} mt={3}>
              <hr />
            </Space>
            <Grid.Flex justifyContent="space-between">
              <Grid.Box width={2 / 5}>
                <Typography
                  color="#061c31"
                  mb={0}
                  style={{ lineHeight: 1.15, whiteSpace: "normal" }}
                  variant="link"
                >
                  Refer friends to earn more points!
                </Typography>
              </Grid.Box>
              <Grid.Box flex={0}>
                <Button
                  color={ButtonColor.DARK}
                  data-testid="refer--menu-buton"
                  onClick={() => {
                    close();
                    navigate("/member/account/refer");
                    trackingService.trackEvent(Events.REFER_FRIENDS, {
                      refer_source: "points_hub",
                    });
                  }}
                  size={ButtonSize.SMALL}
                  uppercase
                >
                  Refer
                </Button>
              </Grid.Box>
            </Grid.Flex>
          </AccountMenuList>
        </React.Fragment>
      )}
    </Menu>
  );
};

AccountMenu.propTypes = {
  user: PropTypes.shape({
    goal: PropTypes.number.isRequired,
    points: PropTypes.number.isRequired,
  }).isRequired,
};

export default AccountMenu;
