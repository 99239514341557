import { get } from "lodash";

/**
 *
 * @params {object} state global redux state
 * @return {string} an event aggregate key
 */
export const selectEventAggregate = (params) => (state) => {
  return get(state, "eventAggregate", {
    day: null,
    month: null,
    week: null,
  })[params.period];
};

export default {
  selectEventAggregate,
};
