import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React from "react";

import FieldError from "../FieldError";
import Label from "../Label";
import Search from "./Search";

/**
 * @render react
 * @name Search
 * @description Achievement Search Component
 * @example
 * <Search
      field={{
        name: 'fieldName',
        value: 'user@domain.com',
      }}
      form={{
        touched: {
          fieldName: true,
        },
        errors: {},
      }}
      name="search"
      placeholder="Enter search term..."
    />
 */

const SearchComponent = ({
  field,
  form: { errors, touched },
  label,
  placeholder,
  ...props
}) => {
  const hasError = touched[field.name] && !isEmpty(errors[field.name]);

  return (
    <React.Fragment>
      {hasError && <FieldError>{errors[field.name]}</FieldError>}
      <Search
        {...field}
        {...props}
        hasError={hasError}
        placeholder={placeholder}
      />
      {label && (
        <Label hasError={hasError} htmlFor={field.name}>
          {label}
        </Label>
      )}
    </React.Fragment>
  );
};

SearchComponent.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  placeholder: PropTypes.string,
};

export default Search;
