import { HStack, IconButton } from "@evidation-shared/eve";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React from "react";

import { ReactComponent as HideView } from "../../../../assets/icons/HideView.svg";
import { ReactComponent as View } from "../../../../assets/icons/View.svg";
import FieldError from "../FieldError";
import Label from "../Label";
import Input, { IconWrapper } from "./Input";

/**
 * @render react
 * @name Input
 * @description Achievement Input Component
 * @example
 * <Input
      field={{
        name: 'fieldName',
        value: 'user@domain.com',
      }}
      form={{
        touched: {
          fieldName: true,
        },
        errors: {},
      }}
      label="E-mail address"
      name="field"
      placeholder="user@domain.com"
    />
 */

const InputComponent = ({
  field,
  form: { errors, touched },
  label,
  placeholder,
  type,
  ...props
}) => {
  const [updatedType, setUpdatedType] = React.useState(type);

  const renderInputRightElement = () => {
    // if props.type is not a password hide element
    if (type !== "password") {
      return null;
    }

    return (
      <IconWrapper hasError={hasError}>
        <IconButton
          icon={
            updatedType === "password" ? (
              <View height={25} width={25} />
            ) : (
              <HideView height={25} width={25} />
            )
          }
          mx={2}
          onPress={() =>
            setUpdatedType(updatedType === "password" ? "text" : "password")
          }
          p={0}
          variant="unstyled"
        />
      </IconWrapper>
    );
  };
  const hasError = touched[field.name] && !isEmpty(errors[field.name]);

  return (
    <React.Fragment>
      {hasError && <FieldError>{errors[field.name]}</FieldError>}
      <HStack>
        <Input
          {...field}
          {...props}
          hasError={hasError}
          placeholder={placeholder}
          type={updatedType}
        />
        {renderInputRightElement()}
      </HStack>

      <Label hasError={hasError} htmlFor={field.name}>
        {label}
      </Label>
    </React.Fragment>
  );
};

InputComponent.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  placeholder: PropTypes.string,
};

export { Input };

export default InputComponent;
