import { snakeCase } from "lodash";

/**
 * Transforms request params from the client to the server
 * @param {object} params
 */
export const transformParams = (params) =>
  Object.keys(params).reduce(
    (accumulator, key) => ({
      ...accumulator,
      [snakeCase(key)]: params[key],
    }),
    {}
  );
