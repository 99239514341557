import { get } from "lodash";
import { rem } from "polished";
import styled from "styled-components";

import Star from "./Star.svg";

const PointBar = styled.div`
  align-items: center;
  background-color: ${(props) => get(props, "theme.palette.base.lighter")};
  border-radius: ${rem(5)};
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.07);
  display: flex;
  font-size: ${rem(16)};
  height: ${rem(36)};
  overflow: hidden;
  position: relative;
  width: 100%;
`;

PointBar.Value = styled.div`
  color: ${(props) => get(props, "theme.palette.base.medium")};
  left: ${rem(8)};
  padding-top: 1px;
  position: absolute;
`;

PointBar.Maximum = styled.div`
  background-image: url("${Star}");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: ${rem(14)};
  color: ${(props) => get(props, "theme.palette.base.dark")};
  padding-top: 1px;
  padding-right: ${rem(14 + 4)};
  position: absolute;
  right: ${rem(8)};
`;

PointBar.Bar = styled.div`
  background: ${(props) => get(props, "theme.palette.secondary.lighter")};
  height: 100%;
  transition: 0.4s width linear;
  width: ${(props) => props.width}%;
`;

export default PointBar;
