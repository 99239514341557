import { InlineDialog } from "@evidation-shared/eve";
import React from "react";

import routes from "../../pages/routes";
import { PointsExpirationInlineDialogProps } from "./PointsExpirationInlineDialog.types";
import {
  getPointsExpiration,
  getQualificationDescription,
} from "./PointsExpirationInlineDialog.utils";

const PointsExpirationInlineDialog: React.FunctionComponent<
  PointsExpirationInlineDialogProps
> = ({ lastExpiredAt, nextExpiration, status }) => {
  const expirationDate = getPointsExpiration(
    status,
    nextExpiration,
    lastExpiredAt
  );
  return (
    <InlineDialog
      buttons={[
        {
          children: "Learn More",
          key: "PointsExpirationButton",
          onPress: () => {
            window.open(routes.pointsExpirationFAQ, "_blank");
          },
          testID: "PointsExpirationInlineDialog--Button-LearnMore",
          variant: "outline",
        },
      ]}
      description={getQualificationDescription(status, expirationDate)}
      title={status === "disqualified" ? "Account Inactive" : undefined}
      variant={status === "disqualified" ? "error" : "info"}
    />
  );
};

export default PointsExpirationInlineDialog;
