import PropTypes from "prop-types";
import React from "react";

import { Auth, getAccessToken, Hub, useServer } from "@achievement/portal-client";

import trackingService from "../services/trackingService";
import userService from "../services/userService";

const UserContext = React.createContext();
const UserConsumer = UserContext.Consumer;

class UserProvider extends React.PureComponent {
  state = {
    isAuthenticated: false,
  };
  handleAuth = async ({ payload: { event, data: user } }) => {
    if (event === "signIn") {
      const accessToken = await getAccessToken();

      if (!accessToken) {
        this.setState({ isAuthenticated: false });
      } else {
        this.load();
      }
    }
  };

  componentDidMount = () => {
    // load the user if authenticated
    Auth.currentSession().then(() => this.load());

    Hub.listen("auth", this.handleAuth);
  };

  componentWillUnmount = () => {
    clearInterval(this.timeout);

    Hub.remove("auth", this.handleAuth);
  };

  load = async () => {
    try {
      const user = await userService.get();
      this.handleUpdate(user);
    } catch (error) {
      this.handleReset();
    }
  };

  logout = async () => {
    this.handleReset();

    try {
      await userService.logout();
      await Auth.signOut();
    } catch (error) {
      console.log(error);
    }
  };

  handleReset = () => {
    this.setState({
      isAuthenticated: false,
    });
  };

  handleUpdate = (user) => {
    const { data, included, meta } = user;
    trackingService.setConfig({
      user_id: data.identifier,
    });

    trackingService.setUserProperties({
      email: null,
      name: null,
      phone_number: null,
    });

    if (meta?.csrf) {
      localStorage.setItem("csrf", meta?.csrf);
    }

    this.setState({
      isAuthenticated: true,
      user: {
        ...this.state.user,
        ...data,
      },
      user_included: included || this.state.user_included || [],
    });
  };

  render() {
    return (
      <UserContext.Provider
        value={{
          load: this.load,
          logout: this.logout,
          reset: this.handleReset,
          updateUser: this.handleUpdate,
          user: this.state.user,
          user_included: this.state.user_included || [],
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

UserProvider.propTypes = {
  children: PropTypes.node,
};

export { UserConsumer, UserProvider };

export default UserContext;
