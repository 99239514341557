export const panelTheme = {
  LIGHT: {
    backgroundColor: "#f8f8f8",
    color: "#2a3f53",
  },
  WHITE: {
    backgroundColor: "#fff",
    color: "#5b6b7d",
  },
  NONE: {
    color: "#5b6b7d",
  },
};

const PanelColor = {
  LIGHT: "LIGHT",
  WHITE: "WHITE",
  NONE: "NONE",
};

export default PanelColor;
