import { isInteger } from "lodash";
import PropTypes from "prop-types";

import { ActivityIndicator, Space } from "../ReactComponents/Components";
import PointHubButton from "./PointHubButton";

const PointHubButtonComponent = ({
  active,
  date,
  label,
  loading,
  onClick,
  value,
}) => (
  <PointHubButton active={active} onClick={onClick}>
    <PointHubButton.Label>{label}</PointHubButton.Label>
    {!loading && <PointHubButton.Date>{date}</PointHubButton.Date>}
    <PointHubButton.Value>
      {loading ? (
        <Space component="div" mb={1}>
          <ActivityIndicator
            backgroundColor="transparent"
            color={active ? "#fff" : "#061c31"}
            size={20}
          />
        </Space>
      ) : (
        isInteger(value) && value.toLocaleString()
      )}
    </PointHubButton.Value>
  </PointHubButton>
);

PointHubButtonComponent.propTypes = {
  active: PropTypes.bool,
  date: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.number,
};

PointHubButtonComponent.defaultProps = {
  active: false,
  loading: false,
};

export { PointHubButton };

export default PointHubButtonComponent;
