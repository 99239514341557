import PropTypes from "prop-types";

import Grid from "../Grid";
import Panel, { PanelColor } from "../Panel";
import Typography from "../Typography";
import ArrowRight from "./ArrowRight";

const CharityPanelComponent = ({ caption, image, onClick, name }) => (
  <Panel
    as="button"
    color={PanelColor.WHITE}
    onClick={onClick}
    p={4}
    type="button"
  >
    <Grid.Flex alignItems="center">
      {image && (
        <Grid.Box flex="0 0 auto" mr={3}>
          <img alt="" src={image} width="72" />
        </Grid.Box>
      )}
      <Grid.Box flex={1}>
        <Typography as="h4" color="base.darker" mb={0} textAlign="left">
          {name}
        </Typography>
        {caption && (
          <Typography mb={0} mt={1} textAlign="left" variant="caption">
            {caption}
          </Typography>
        )}
      </Grid.Box>
      <Grid.Box flex="0 0 auto" ml={3}>
        <ArrowRight height={12} />
      </Grid.Box>
    </Grid.Flex>
  </Panel>
);

CharityPanelComponent.propTypes = {
  caption: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CharityPanelComponent;
