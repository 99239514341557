import { get, isUndefined } from "lodash";
import PropTypes from "prop-types";
import ReactResponsiveMediaQuery from "react-responsive";
import { withTheme } from "styled-components";
import toPx from "to-px";

export const getBreakpoint = (size) => (props) =>
  get(props, `theme.breakpoints[${size}]`, "32em");

const Breakpoint = {
  SMALL: 0,
  MEDIUM: 1,
  LARGE: 2,
  LARGER: 3,
};

const MediaQuery = withTheme(({ maxWidth, minWidth, ...props }) => {
  return (
    <ReactResponsiveMediaQuery
      maxWidth={
        !isUndefined(maxWidth) ? toPx(getBreakpoint(maxWidth)(props)) : 99999
      }
      minWidth={
        !isUndefined(minWidth) ? toPx(getBreakpoint(minWidth)(props)) + 1 : 0
      }
      {...props}
    />
  );
});

MediaQuery.defaultProps = {
  theme: {
    breakpoints: ["32em", "48em", "64em", "78em"],
  },
};

const WidthPropTypes = PropTypes.oneOf(Object.values(Breakpoint));

MediaQuery.propTypes = {
  theme: PropTypes.object.isRequired,
  minWidth: WidthPropTypes,
  maxWidth: WidthPropTypes,
};

export { Breakpoint, MediaQuery };

export default MediaQuery;
