import { throttle } from "lodash";
import { useEffect, useState } from "react";

const useInfiniteScroll = () => {
  const [shouldFetch, setShouldFetch] = useState(true);

  const handleScroll = throttle(() => {
    if (
      document.documentElement.offsetHeight ===
      window.innerHeight + window.pageYOffset
    ) {
      setShouldFetch(true);
    }
  }, 500);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return [shouldFetch, setShouldFetch];
};

export default useInfiniteScroll;
