import { get } from "lodash";
import { rem } from "polished";
import styled from "styled-components";

const ApplicationPanel = {
  Icon: styled.img.attrs({
    "data-testid": "application-icon",
  })`
    border-radius: ${rem(10)};
    display: block;
    height: auto;
    max-width: 100%;
  `,

  Name: styled.h4.attrs({
    "data-testid": "application-name",
  })`
    align-items: center;
    display: flex;
    line-height: ${rem(24)};
    margin-bottom: 0;
  `,

  Description: styled.p.attrs({
    "data-testid": "application-description",
  })`
    font-size: ${rem(14)};
    line-height: ${rem(18)};
    margin-bottom: 0;
  `,

  Status: styled.div.attrs({
    "data-testid": "application-status",
  })`
    color: ${(props) => get(props, "theme.palette.secondary.medium")};
    font-size: ${rem(12)};
    line-height: ${rem(18)};
    margin-top: ${rem(2)};
    &::before {
      background-color: ${(props) =>
        get(props, "theme.palette.secondary.medium")};
      border-radius: 50%;
      content: "";
      display: inline-flex;
      height: ${rem(8)};
      margin-right: ${rem(6)};
      width: ${rem(8)};
    }
  `,
};

export default ApplicationPanel;
