import dataService from "../../services/dataService";
import { transformParams } from "./transform";
import types from "./types";

export const fetchRequest = (payload) => ({
  payload,
  type: types.FETCH_EVENT_AGGREGATE_REQUEST,
});

export const fetchResponse = (payload) => ({
  payload,
  type: types.FETCH_EVENT_AGGREGATE_RESPONSE,
});

export const fetchError = (error) => ({
  error,
  type: types.FETCH_EVENT_AGGREGATE_ERROR,
});

export const fetch = (params) => async (dispatch) => {
  try {
    dispatch(fetchRequest(params.period));
    const response = await dataService.getEventAggregate(
      transformParams(params)
    );

    dispatch(fetchResponse(response.data));
  } catch (error) {
    dispatch(fetchError(error));
  }
};

export default {
  fetch,
};
