import {
  addYears,
  getDate,
  getDaysInMonth,
  getMonth,
  getYear,
  isDate,
} from "date-fns";
import { isEmpty, times } from "lodash";
import React from "react";

import FieldError from "../FieldError";
import Label from "../Label";
import DateSelect from "./DateSelect";

/**
 * @render react
 * @name Select
 * @description Achievement Date Select Component
 * @example
 * <DateSelect
 *   field={{
 *     name: 'fieldName',
 *     value: new Date(),
 *   }}
 *   form={{
 *     touched: {
 *       fieldName: true,
 *     },
 *     errors: {},
 *   }}
 *   label="Date of birth"
 *   name="fieldName"
 * />
 */

const OFFSET = getYear(addYears(new Date(), -100));

const initialState = {
  day: "",
  month: "",
  year: "",
};

class DateSelectComponent extends React.PureComponent {
  state = {
    hasFocus: false,
    ...initialState,
  };

  setComponentState = () => {
    if (this.props.field.value && isDate(this.props.field.value)) {
      this.setState({
        day: getDate(this.props.field.value),
        month: getMonth(this.props.field.value),
        year: getYear(this.props.field.value),
      });
    }
  };

  componentDidMount = this.setComponentState;

  componentDidUpdate = () => {
    if (
      this.state.day !== getDate(this.props.field.value) ||
      this.state.month !== getMonth(this.props.field.value) ||
      this.state.year !== getYear(this.props.field.value)
    ) {
      this.setComponentState();
    }
  };

  handleBlur = (event) => {
    this.setState({ hasFocus: false });
    this.props.field.onBlur(event);
  };

  handleChange = (key, value) => {
    this.setState((state) => {
      const updatedState = { ...state, [key]: value };
      if (this.hasCompleteValue(updatedState)) {
        this.setFormValue(updatedState);
      }
      return updatedState;
    });
  };

  handleChangeMonth = (event) => {
    this.handleChange("month", event.target.value);

    const days = getDaysInMonth(new Date(this.state.year, event.target.value));
    if (this.state.day > days) {
      this.handleChange("day", days);
    }
  };

  handleChangeDay = (event) => {
    this.handleChange("day", event.target.value);
  };

  handleChangeYear = (event) => {
    this.handleChange("year", event.target.value);

    const days = getDaysInMonth(new Date(event.target.value, this.state.month));
    if (this.state.day > days) {
      this.handleChange("day", days);
    }
  };

  handleFocus = () => this.setState({ hasFocus: true });

  setFormValue = (state) => {
    this.props.form.setFieldValue(
      this.props.field.name,
      new Date(state.year, state.month, state.day)
    );
  };

  hasCompleteValue = (state) =>
    state.day !== initialState.day &&
    state.month !== initialState.month &&
    state.year !== initialState.year;

  render() {
    const {
      children,
      field: { name, value },
      form: { errors = {}, touched = {} },
      label,
      ...props
    } = this.props;

    const hasError = touched[name] && !isEmpty(errors[name]);

    return (
      <React.Fragment>
        {!this.state.hasFocus && hasError && (
          <FieldError>{errors[name]}</FieldError>
        )}
        <DateSelect>
          <DateSelect.Select
            {...props}
            hasError={hasError}
            id={`${name}.month`}
            name={`${name}.month`}
            onBlur={this.handleBlur}
            onChange={this.handleChangeMonth}
            onFocus={this.handleFocus}
            value={this.state.month}
          >
            <option disabled hidden value="">
              Month
            </option>
            <option value={0}>January</option>
            <option value={1}>February</option>
            <option value={2}>March</option>
            <option value={3}>April</option>
            <option value={4}>May</option>
            <option value={5}>June</option>
            <option value={6}>July</option>
            <option value={7}>August</option>
            <option value={8}>September</option>
            <option value={9}>October</option>
            <option value={10}>November</option>
            <option value={11}>December</option>
          </DateSelect.Select>
          <DateSelect.Select
            {...props}
            hasError={hasError}
            id={`${name}.day`}
            name={`${name}.day`}
            onBlur={this.handleBlur}
            onChange={this.handleChangeDay}
            onFocus={this.handleFocus}
            value={this.state.day}
          >
            <option disabled hidden value="">
              Day
            </option>
            {times(getDaysInMonth(value) || 31, (day) => (
              <option key={day} value={day + 1}>
                {day + 1}
              </option>
            ))}
          </DateSelect.Select>
          <DateSelect.Select
            {...props}
            hasError={hasError}
            id={`${name}.year`}
            name={`${name}.year`}
            onBlur={this.handleBlur}
            onChange={this.handleChangeYear}
            onFocus={this.handleFocus}
            value={this.state.year}
          >
            <option disabled hidden value="">
              Year
            </option>
            {times(100, (index) => (
              <option key={index} value={index + (OFFSET + 1)}>
                {index + (OFFSET + 1)}
              </option>
            ))}
          </DateSelect.Select>
        </DateSelect>
        <Label hasError={hasError}>{label}</Label>
      </React.Fragment>
    );
  }
}

DateSelectComponent.propTypes = {};

export { DateSelect };

export default DateSelectComponent;
