import get from "lodash/get";
import { rem } from "polished";
import styled from "styled-components";

const getColor = (color) => (props) =>
  get(props, `theme.palette.${color}`, "#eb3f54");

const theme = {
  borderWidth: "4px",
  fontSize: rem(14),
  height: rem(60),
};

const Navigation = styled.nav`
  font-size: ${theme.fontSize};
  text-transform: uppercase;
`;

Navigation.List = styled.ul`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
`;

Navigation.ListItem = styled.li``;

Navigation.Link = styled.a`
  align-items: center;
  border-bottom: ${theme.borderWidth} solid
    ${(props) => (props.active ? getColor("primary.dark") : "transparent")};
  color: ${getColor("base.medium")};
  display: flex;
  font-family: PublicSans-SemiBold;
  height: ${theme.height};
  justify-content: center;
  padding-left: ${rem(24)};
  padding-right: ${rem(24)};
  padding-top: ${theme.borderWidth};
  text-decoration: none;
  transition: 0.35s ease color;

  -webkit-font-smoothing: antialiased;

  &:hover {
    color: ${getColor("primary.dark")};
  }
`;

export default Navigation;
