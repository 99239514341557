const SEARCH_PARAM_INVITE_CODE = "invite_code";
const SEARCH_PARAM_REDIRECT = "redirect";

const sessionStorageService = {
  getRedirect: () => {
    return window.sessionStorage.getItem(SEARCH_PARAM_REDIRECT);
  },

  removeRedirect: () => {
    return window.sessionStorage.removeItem(SEARCH_PARAM_REDIRECT);
  },

  setRedirect: (redirect: string) => {
    return window.sessionStorage.setItem(SEARCH_PARAM_REDIRECT, redirect);
  },

  getInviteCode: () => {
    return window.sessionStorage.getItem(SEARCH_PARAM_INVITE_CODE);
  },

  removeInviteCode: () => {
    return window.sessionStorage.removeItem(SEARCH_PARAM_INVITE_CODE);
  },

  setInviteCode: (inviteCode: string) => {
    return window.sessionStorage.setItem(SEARCH_PARAM_INVITE_CODE, inviteCode);
  },
};

export default sessionStorageService;
