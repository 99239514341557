import { get } from "lodash";
import { rem } from "polished";
import styled from "styled-components";

import { Menu } from "../ReactComponents/Components";

const HeaderMenuList = styled(Menu.List)`
  &[data-reach-menu-list] {
    width: 360px;
    padding-top: ${rem(16)};
    position: absolute;
    right: 0;
    z-index: 101;

    a[data-reach-menu-item] {
      margin-top: ${rem(-8)};
    }

    hr {
      margin-bottom: ${rem(16)};
    }

    svg {
      height: ${rem(20)};
      stroke: ${(props) => get(props, "theme.palette.base.medium")};
      width: ${rem(20)};
    }
  }
`;

export default HeaderMenuList;
