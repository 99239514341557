import PropTypes from "prop-types";

import ErrorBoundary from "../ErrorBoundary";
import Grid from "../Grid";
import SkeletonContent from "../SkeletonContent";
import Space from "../Space";
import EventPanel from "./EventPanel";
import EventPanelErrorState from "./EventPanelErrorState";

const EventPanelComponent = ({ event, loading }) => {
  if (loading) {
    return (
      <ErrorBoundary component={<EventPanelErrorState />}>
        <EventPanel component="article">
          <Grid.Flex alignItems="center">
            <Grid.Box flex="0 0 auto" mr={2}>
              <SkeletonContent.Avatar size={40} />
            </Grid.Box>
            <Grid.Box flex="1" ml={1}>
              <Space mb={1}>
                <SkeletonContent.Block height={21} />
              </Space>
              <SkeletonContent.Block height={18} />
            </Grid.Box>
            <Grid.Box flex="0 0 auto" ml={5}>
              <SkeletonContent.Block height={31} width={75} />
            </Grid.Box>
          </Grid.Flex>
        </EventPanel>
      </ErrorBoundary>
    );
  }
  return (
    <ErrorBoundary component={<EventPanelErrorState />}>
      <EventPanel component="article">
        <Grid.Flex alignItems="center">
          <Grid.Box flex="0 0 auto" mr={2}>
            <EventPanel.Icon alt="" src={event.icon} />
          </Grid.Box>
          <Grid.Box flex="1" ml={1}>
            <EventPanel.Description>{event.description}</EventPanel.Description>
            <EventPanel.Meta as={!event.createdAt}>
              {event.createdAt}
              {event.name && ` • ${event.name}`}
            </EventPanel.Meta>
          </Grid.Box>
          {isFinite(event.reward) && (
            <Grid.Box flex="0 0 auto" ml={5}>
              <EventPanel.Reward>
                {event.reward > 0 && "+"}
                {event.reward.toLocaleString()}
              </EventPanel.Reward>
            </Grid.Box>
          )}
        </Grid.Flex>
      </EventPanel>
    </ErrorBoundary>
  );
};

EventPanelComponent.propTypes = {
  event: PropTypes.shape({
    createdAt: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.string,
    name: PropTypes.string,
    reward: PropTypes.number,
  }),
  loading: PropTypes.bool,
};

EventPanelComponent.defaultProps = {
  isConnected: false,
  loading: false,
};

export default EventPanelComponent;
