import PropTypes from "prop-types";
import React from "react";

import PieChart from "./PieChart";

const PieChartComponent = ({ size, value }) => {
  return (
    <PieChart height={size} viewBox="0 0 32 32" width={size}>
      <PieChart.Slice
        cx="16"
        cy="16"
        fill="#f8f8f8"
        r="16"
        stroke="#e3e3e3"
        strokeWidth="1"
      />
      <PieChart.Slice
        cx="16"
        cy="16"
        fill="transparent"
        r="16"
        stroke="#c6f0e0"
        strokeDasharray={`${value} 100`}
        strokeWidth="32"
      />
      <PieChart.Slice
        cx="16"
        cy="16"
        fill="transparent"
        r="16"
        stroke="#37d596"
        strokeDasharray={`${value} 100`}
        strokeWidth="1"
      />
    </PieChart>
  );
};

PieChartComponent.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.number,
};

PieChartComponent.defaultProps = {
  size: "100%",
  value: 0,
};

export { PieChart };

export default PieChartComponent;
