import { useLocation, useMatch, useNavigate } from "react-router-dom";

function Match({ path, children }) {
  let match = useMatch(path);
  let location = useLocation();
  let navigate = useNavigate();
  return children({ match, location, navigate });
}

export default Match;
