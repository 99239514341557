import { To, useNavigate } from "react-router";

import routes from "../../pages/routes";

const useNavigateRedirect = (redirect?: string) => {
  const navigate = useNavigate();

  return (to?: To, state?: object) => {
    const options = { replace: true, state };

    if (to) {
      navigate(to, options);
    } else if (redirect) {
      navigate(redirect, options);
    } else {
      navigate(routes.member, options);
    }
  };
};

export default useNavigateRedirect;
