import { useQuery } from "react-query";

import dataService from "../../services/dataService";
export const usePrograms = (options) => {
  return useQuery("programs", dataService.getPrograms, {
    refetchOnMount: false,
    retry: false,
    staleTime: 1 * 60 * 1000,
    structuralSharing: false,
    ...options,
  });
};

export default usePrograms;
