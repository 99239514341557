import { rem } from "polished";
import styled from "styled-components";

const Controls = styled.div`
  display: flex;
  height: 100%;
`;

Controls.Content = styled.div`
  flex: 1;
  margin-left: ${rem(8)};
  margin-right: ${rem(8)};
`;

Controls.Control = styled.button`
  align-self: center;
  background-image: url(${(props) => props.icon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto ${rem(8)};
  height: ${rem(24)};
  margin-left: ${rem(-8)};
  margin-right: ${rem(-8)};
  width: ${rem(24)};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
`;

export default Controls;
