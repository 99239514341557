import { rem } from "polished";
import PropTypes from "prop-types";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  Button,
  ButtonColor,
  ButtonSize,
  Grid,
} from "../ReactComponents/Components";
import { PromotionStyles } from "./styles";

const SignupButton = styled(Button)`
  border-radius: ${rem(60)};
  align-self: center;
`;

const PromotionBanner = ({
  color,
  description,
  location,
  logo,
  partnerLogo,
  title,
}) => {
  const navigate = useNavigate();
  const queryParams = encodeURI(window.location.search);
  return (
    <PromotionStyles.Container color={color}>
      <Grid>
        <Grid.Flex flexWrap="wrap" px={[3, 3, 3, 0]} py={4}>
          <Grid.Box pl={[0, 5, 5, 4]} width={[1, 1, 1, 2 / 10]}>
            <PromotionStyles.Title>{title}</PromotionStyles.Title>
            <Grid.Box width={[0, 0, 0, "100px"]}>{partnerLogo}</Grid.Box>
          </Grid.Box>
          <Grid.Box px={[0, 5]} width={[1, 1, 1, 5 / 10]}>
            <PromotionStyles.Description>
              {description}
              For more information, please see our{" "}
              <Link
                href={`${window.env.WEBSITE_URL}/members/terms${queryParams}`}
                isExternal
              >
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link
                href={`${window.env.WEBSITE_URL}/members/privacy${queryParams}`}
                isExternal
              >
                Privacy Notice
              </Link>{" "}
              below.
            </PromotionStyles.Description>
            <Grid.Flex>
              <SignupButton
                color={ButtonColor.PRIMARY}
                onClick={() => navigate(`/signup${location.search}`)}
                size={ButtonSize.MEDIUM}
                uppercase
              >
                Sign up
              </SignupButton>
              <Grid.Box ml="auto" mr="0" width={["100px", "100px", "100px", 0]}>
                {partnerLogo}
              </Grid.Box>
            </Grid.Flex>
          </Grid.Box>
          <Grid.Box
            pl={0}
            style={{ overflow: "hidden" }}
            width={[0, 0, 0, 3 / 10]}
          >
            {logo}
          </Grid.Box>
        </Grid.Flex>
      </Grid>
    </PromotionStyles.Container>
  );
};

PromotionBanner.propTypes = {
  description: PropTypes.node,
  color: PropTypes.string,
  logo: PropTypes.element,
  partnerLogo: PropTypes.element,
  title: PropTypes.node,
};

export default PromotionBanner;
