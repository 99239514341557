import get from "lodash/get";
import { rem } from "polished";
import styled from "styled-components";

const CopyLink = styled.div`
  align-items: center;
  border: 1px solid ${(props) => get(props, "theme.palette.base.light", "red")};
  border-radius: ${rem(4)};
  display: flex;
  max-width: 100%;
  padding: ${rem(12)};
  position: relative;
`;

CopyLink.Value = styled.div`
  flex: 1;
  overflow: hidden;
  padding-left: ${rem(8)};
  padding-right: ${rem(20)};
  padding-top: ${rem(1)};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default CopyLink;
