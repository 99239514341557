import React from "react";
import { Text, View } from "react-native";

import { HTML } from "../HTML";
import quickTipStyles, { quickTipBaseStyle } from "./styles";
import { QuickTipProps } from "./types";

const QuickTip: React.FunctionComponent<QuickTipProps> = ({
  content,
  title = "Quick Tip",
}) => {
  return (
    <View style={quickTipStyles.container}>
      <Text style={quickTipStyles.title}>{title}</Text>
      <HTML
        baseStyle={quickTipBaseStyle}
        source={{
          html: content,
        }}
      />
    </View>
  );
};

export default QuickTip;
