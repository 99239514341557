import { format, parse } from "date-fns";
import { get, isArray, isEmpty, isInteger, isUndefined } from "lodash";

import {
  toNumericPhoneNumber,
  toPhoneNumber,
} from "../components/ReactComponents/Components";

const DataType = {
  APPLICATION: [
    { client: "connectionInstructions", server: "connection_instructions" },
    { client: "description", server: "description" },
    { client: "icon", server: "icon" },
    { client: "id", server: "id" },
    { client: "name", server: "name" },
    {
      client: "shortDescription",
      clientFunc: (value) => value.join(", "),
      server: "activity_types",
    },
    {
      client: "status",
      clientFunc: (value) => (value ? "Online" : "Offline"),
      server: "connected",
    },
    {
      client: "updatedAt",
      clientFunc: (value) => get(value, "last_synced"),
      server: "status",
    },
  ],
  CHARITY: [
    { client: "caption", server: "caption" },
    { client: "description", server: "description" },
    { client: "id", server: "benevity_id" },
    { client: "image", server: "logo_url" },
    { client: "name", server: "name" },
  ],
  EVENT: [
    { client: "id", server: "id" },
    {
      client: "application",
      clientFunc: (value) => ({ name: value.name }),
      server: "application",
    },
    { client: "createdAt", server: "timestamp" },
    { client: "description", server: "description" },
    {
      client: "icon",
      clientFunc: (value, otherValues) => {
        return get(otherValues, "application.icon_url", value);
      },
      server: "icon",
    },
    { client: "points", server: "points" },
    { client: "updatedAt", server: "updated_at" },
  ],
  FACEBOOK: [
    {
      client: "thirdPartyAuth",
      clientFunc: (value, otherValues) => {
        return {
          accessToken: value,
          type: "FacebookAuthentication",
          uid: otherValues.id,
        };
      },
      server: "accessToken",
    },
    { client: "email", server: "email" },
    { client: "firstName", server: "first_name" },
    { client: "gender", server: "gender" },
    { client: "lastName", server: "last_name" },
  ],
  OFFER: [
    { client: "action", server: "card_action_description" },
    {
      client: "body",
      clientFunc: (value, otherValues) => {
        if (
          otherValues.one_click_content &&
          otherValues.one_click_content.card_body
        ) {
          return otherValues.one_click_content.card_body;
        }

        return value;
      },
      server: "card_body",
    },
    {
      client: "complete",
      clientFunc: (value) => ({
        body: get(value, "content.text"),
        image: get(value, "content.image"),
        title: get(value, "content.heading"),
      }),
      server: "completion",
    },
    {
      client: "compensation",
      clientFunc: (value) => get(value, "label"),
      server: "compensation",
    },
    { client: "points", server: "points" },
    {
      client: "duration",
      clientFunc: (value) =>
        isInteger(value) ? Math.round(value / 60) : value,
      server: "seconds_to_complete",
    },
    { client: "expiryDate", server: "end_at" },
    { client: "identifier", server: "identifier" },
    { client: "image", server: "image_url" },
    {
      client: "kind",
      clientFunc: (value, otherValues) => {
        if (!value) {
          if (!isEmpty(otherValues.survey)) {
            return "one-click";
          } else {
            return "click-to-read";
          }
        }

        return value;
      },
      server: "kind",
    },
    { client: "programIdentifier", server: "program_identifier" },
    {
      client: "redirect",
      server: "redirect_url",
    },
    {
      client: "refer",
      clientFunc: (value) =>
        value ? transformResponseValues(value, DataType.REFER) : null,
      server: "referral",
    },
    {
      client: "sponsored",
      clientFunc: (value) => !isEmpty(value),
      server: "sponsored",
    },
    {
      client: "title",
      server: "card_header",
    },
    {
      client: "survey",
      clientFunc: (value, otherValues) => {
        if (!value) {
          return {};
        }

        return {
          question: get(otherValues, "one_click_content.question", ""),
          answers: value.answers,
        };
      },
      server: "survey",
    },
  ],
  PASSWORD: [
    { client: "token", server: "reset_password_token" },
    { client: "password1", server: "password" },
    { client: "password2", server: "password_confirmation" },
  ],
  PREFERENCES: [
    { client: "token", server: "subscription_token" },
    { client: "goal", server: "receive_reached_goal" },
    { client: "notifications", server: "receive_app_notifications" },
    { client: "programs", server: "receive_bonus_opportunities" },
    { client: "studies", server: "receive_study_invitations" },
    { client: "summary", server: "receive_weekly_summary" },
  ],
  REFER: [
    { client: "description", server: "description_html" },
    { client: "link", server: "link" },
    {
      client: "share",
      clientFunc: (value) =>
        value ? transformResponseValues(value, DataType.SHARE) : null,
      server: "share_description",
    },
  ],
  REWARD: [
    { client: "charity", server: "benevity_id" },
    { client: "confirmation", server: "confirmation_needed" },
    { client: "points", server: "points" },
  ],
  SHARE: [
    {
      client: "facebook",
      server: "medium",
    },
    { client: "twitter", server: "small" },
  ],
  SURVEY: [
    { client: "answers", server: "single_tap_answers" },
    { client: "question", server: "question" },
    { client: "hideStatistics", server: "hide_stats" },
  ],
  SURVEY_COMPLETE: [
    {
      client: "body",
      clientFunc: (value) => get(value, "content.heading"),
      server: "completion",
    },
    {
      client: "image",
      clientFunc: (value) => get(value, "content.image"),
      server: "completion",
    },
    {
      client: "result",
      clientFunc: (stats) => {
        if (!stats) {
          return null;
        }

        const count = stats.reduce((accumulator, stat) => {
          Object.values(stat).forEach((value) => {
            if (isInteger(value)) {
              accumulator += value;
            }
          });
          return accumulator;
        }, 0);

        return stats.reduce((accumulator, stat) => {
          Object.keys(stat).forEach((key) => {
            accumulator.push({
              children: key,
              value: Math.round((stat[key] / count) * 100),
            });
          });
          return accumulator;
        }, []);
      },
      server: "stats",
    },
    {
      client: "title",
      clientFunc: (value) => get(value, "content.text"),
      server: "completion",
    },
    { client: "points", server: "points" },
  ],
  USER: [
    { client: "accepted_tos", server: "accepted_tos" },
    { client: "airship_user_identifier", server: "airship_user_identifier" },
    {
      client: "birthDate",
      clientFunc: (value) => {
        if (!value) {
          return value;
        }
        return parse(value, "YYYY-MM-DD");
      },
      server: "born_at",
      serverFunc: (value) => {
        if (!value) {
          return value;
        }
        return format(value, "YYYY-MM-DD");
      },
    },
    { client: "email", server: "email" },
    { client: "email1", server: "payment_email" },
    { client: "email2", server: "payment_email" },
    { client: "firstName", server: "first_name" },
    { client: "gender", server: "gender" },
    { client: "goal", server: "goal" },
    { client: "height", server: "height" },
    { client: "id", server: "id" },
    { client: "identifier", server: "identifier" },
    { client: "lastName", server: "last_name" },
    {
      client: "lastExpiredAt",
      server: "last_expired_at",
    },
    {
      client: "nextPointsExpiration",
      server: "next_points_expiration",
    },
    { client: "obfuscatedBornAt", server: "obfuscated_born_at" },
    { client: "obfuscatedEmail", server: "obfuscated_email" },
    { client: "obfuscatedPhoneNumber", server: "obfuscated_phone_number" },
    { client: "obfuscatedPostalCode", server: "obfuscated_postal_code" },
    {
      client: "obfuscatedRacesAndEthnicity",
      server: "obfuscated_races_and_ethnicity",
    },
    {
      client: "otpVerificationExpiresAt",
      server: "otp_verification_expires_at",
    },
    { client: "password", clientFunc: (value) => null, server: "password" },
    {
      client: "phoneNumber",
      clientFunc: toPhoneNumber,
      server: "phone_number",
      serverFunc: toNumericPhoneNumber,
    },
    { client: "points", server: "points" },
    {
      client: "pointsQualificationStatus",
      server: "points_qualification_status",
    },
    { client: "provider", server: "payment_provider" },
    { client: "racesAndEthnicities", server: "races_and_ethnicities" },
    {
      client: "selfIdentifiedRaceOrEthnicity",
      server: "self_identified_race_or_ethnicity",
    },
    {
      client: "thirdParty",
      clientFunc: (value) => {
        if (isArray(value)) {
          return value.includes("FacebookAuthentication");
        }

        return false;
      },
      server: "auth_methods",
      serverFunc: () => undefined,
    },
    {
      client: "thirdPartyAuth",
      clientFunc: (value) => {
        return value
          ? {
              accessToken: value.access_token,
              type: value.type,
              uid: value.uid,
            }
          : undefined;
      },
      server: "third_party_authentication",
      serverFunc: (value) => {
        return value
          ? {
              access_token: value.token,
              type: value.type,
              uid: value.uid,
            }
          : undefined;
      },
    },
    { client: "thirdPartyLogin", server: "third_party_login" },
    {
      client: "weight",
      clientFunc: (value) =>
        value ? `${Math.round(value * 2.20462)} lbs` : undefined,
      server: "weight",
      serverFunc: (value) =>
        value ? value.split(" ")[0] / 2.20462 : undefined,
    },
    { client: "zipCode", server: "postal_code" },
  ],
};

const Platform = {
  REQUEST: ["client", "server"],
  RESPONSE: ["server", "client"],
};

const getFieldKeys = (fields, [from, to]) =>
  fields.reduce((accumulator, field) => {
    return [
      ...accumulator,
      {
        key: field[from],
        newKey: field[to],
        transform: field[`${to}Func`] || ((value) => value),
      },
    ];
  }, []);

const transformValues = (values, keys, platform) => {
  const fields = getFieldKeys(keys, platform);
  return fields.reduce((accumulator, field) => {
    if (!field) {
      return accumulator;
    }

    if (!field.newKey || !field.transform) {
      console.warn("Invalid field object provided for:", field.key);
    }

    const value = field.transform(values[field.key], values);
    if (isUndefined(value)) {
      return accumulator;
    }

    return {
      ...accumulator,
      [field.newKey]: value,
    };
  }, {});
};

export const transformRequestValues = (values, keys) =>
  transformValues(values, keys, Platform.REQUEST);
export const transformResponseValues = (values, keys) =>
  transformValues(values, keys, Platform.RESPONSE);

export { DataType, Platform };

export default transformValues;
