import { rem } from "polished";

import color from "./color";

export const brand = {
  primary: `#0f5fbc`,
};

export { default as GlobalStyle } from "./globalStyle";

const theme = {
  breakpoints: ["32em", "48em", "64em", "78em"],
  gridSize: rem(960),
  space: [
    0,
    rem(4),
    rem(8),
    rem(16),
    rem(24),
    rem(32),
    rem(64),
    rem(128),
    rem(256),
  ],
  font: {
    primarySerif: `'SourceSerifPro', serif`,
    primarySans: `'PublicSans', sans-serif`,
  },
  breakpoint: {
    small: 720,
    medium: 1070,
    large: 1280,
  },
  brand,
  palette: color,
  color: {
    error: "#eb3f54",
  },
  card: {
    backgroundColor: color.base.lightest,
    borderRadius: 5,
    padding: "1rem",
  },
  dropdown: {
    activeColor: color.base.lighter,
    backgroundColor: color.base.lightest,
    borderColor: color.base.light,
    color: color.base.medium,
    fontSize: rem(12),
  },
  input: {
    borderColor: color.base.light,
    color: color.base.dark,
  },
  label: {
    color: color.base.medium,
  },
  select: {
    borderColor: color.base.light,
    color: color.base.dark,
  },
};

export default theme;
