import { get } from "lodash";
import { rem } from "polished";
import styled, { css } from "styled-components";

import ButtonSize from "./ButtonSize";

const applySize = ({ size }) => {
  switch (size) {
    case ButtonSize.SMALLER:
      return css`
        font-size: ${rem(12)};
        padding: ${rem(7)} ${rem(6)} ${rem(6)};
      `;
    case ButtonSize.SMALL:
      return css`
        font-size: ${rem(12)};
        padding: ${rem(9)} ${rem(8)} ${rem(8)};
      `;
    case ButtonSize.MEDIUM:
      return css`
        font-size: ${rem(14)};
        padding: ${rem(13)} ${rem(19)} ${rem(12)};
      `;
    case ButtonSize.LARGE:
      return css`
        font-size: ${rem(20)};
        padding: ${rem(20)} ${rem(64)} ${rem(19)};
      `;
    default:
      return css`
        font-size: inherit;
        padding: ${rem(16)};
      `;
  }
};

const Button = styled(({ color, uppercase, width, ...props }) => (
  <button {...props} />
))`
  align-items: center;
  background-color: ${(props) => get(props, "theme.button.backgroundColor")};
  border: 0;
  border-radius: ${rem(5)};
  color: ${(props) => get(props, "theme.button.color")};
  display: flex;
  font-family: PublicSans-SemiBold;
  justify-content: center;
  letter-spacing: 0.5px;
  line-height: 1;
  text-decoration: none;
  text-transform: ${(props) => (props.uppercase ? "uppercase" : "none")};
  white-space: nowrap;
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};

  -webkit-font-smoothing: antialiased;

  ${applySize}

  svg {
    display: inline-block;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    background-color: #e3e3e3;
    color: ${(props) => get(props, "theme.palette.base.medium")};
  }
`;

Button.displayName = "Button";

export default Button;
