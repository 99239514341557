import get from "lodash/get";
import { rem } from "polished";
import styled, { css } from "styled-components";

import Status from "./Status";

const getColor = (color) => (props) =>
  get(props, `theme.palette.${color}`, "#eb3f54");

const getTheme = ({ status }) => {
  switch (status) {
    case Status.ERROR:
      return css`
        background-color: ${getColor("error")};
        color: ${getColor("base.lightest")};
      `;
    case Status.MESSAGE:
      return css`
        background-color: ${getColor("base.lighter")};
      `;
    case Status.NOTICE:
      return css`
        background-color: ${getColor("base.medium")};
        color: ${getColor("base.lightest")};
      `;
    case Status.SUCCESS:
      return css`
        background-color: ${getColor("success")};
        color: ${getColor("base.lightest")};
      `;
    case Status.ANNOUNCEMENT:
      return css`
        background-color: ${getColor("announcement")};
        color: ${getColor("base.lightest")};
      `;
    default:
      return css`
        background-color: ${getColor("base.lightest")};
      `;
  }
};

const Notification = styled.div.attrs({
  role: "alert",
})`
  font-size: ${rem(12)};
  padding-bottom: ${rem(16)};
  padding-top: ${rem(16)};
  width: 100%;

  ${getTheme};
`;

export default Notification;
