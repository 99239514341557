const cardCategories = Object.freeze({
  offers: "Cards",
  walking: "Walking",
  running: "Running",
  biking: "Biking",
  swimming: "Swimming",
  sleeping: "Sleeping",
  blood_glucose: "Blood Glucose",
  study_contributions: "Study Contributions",
  meditation: "Meditation",
  food_and_water: "Food & Water",
  health_metrics: "Health Metrics",
  heart_rate: "Heart Rate",
  social_media: "Social Media",
  medication: "Medication",
  other_exercise: "Other",
});

/**
 * Generate a default card based on a provided category
 * @param {string} category
 */
const generateDefaultCard = ({ category, title }) => ({
  category,
  percentage: 0,
  points: 0,
  title,
});

/**
 * Returns an array of cards, filling empty spots with default cards
 * @param {array} cards
 * @param {number} maximumLength
 */
export const getDefaultCards = (cards, maximumLength) => {
  if (cards.length >= maximumLength) {
    return [];
  }

  const activeCardCategories = cards.map(({ category }) => category);
  const inactiveCardCategories = Object.keys(cardCategories).filter(
    (category) => !activeCardCategories.includes(category)
  );

  const emptyCount = maximumLength - activeCardCategories.length;
  const defaultCardCategories = inactiveCardCategories.slice(0, emptyCount);

  return defaultCardCategories.map((category) =>
    generateDefaultCard({ category, title: cardCategories[category] })
  );
};
