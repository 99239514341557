import { get } from "lodash";
import { rem } from "polished";
import styled from "styled-components";

const Label = styled.label`
  color: ${(props) => get(props, "theme.label.color")};
  display: block;
  font-size: ${rem(14)};
  line-height: 1.5;
  margin-top: ${rem(2)};

  > a {
    display: block;
    white-space: nowrap;
  }
`;

export default Label;
