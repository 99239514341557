import { PointsQualificationStatus } from "@achievement/portal-client";

import { formatDateWithTimezone } from "../../utils/date";

/**
 * Generate a point status description based on the status and past or future expirationd date.
 * @param status Point status
 * @param expirationDate The given expirationDate (past or future)
 * @returns The qualification description
 */
export const getQualificationDescription = (
  status: PointsQualificationStatus,
  expirationDate?: string
) => {
  if (status === "disqualified" && expirationDate) {
    return `You're no longer earning points as of ${expirationDate} because your account is inactive. Complete a card on the homescreen to reactivate your account and start earning points again.`;
  } else if (status === "pending" && expirationDate) {
    return `Your points are set to expire on ${expirationDate}. To remain active and keep your points from expiring, be sure to complete at least one card in a 12-month period.`;
  } else {
    return "Great job keeping your account active! Keep completing cards so your points never expire.";
  }
};

/**
 * Format the user points expiration based on points_qualification_status
 * @param next_points_expiration The user next_points_expiration field
 */
export const getPointsExpiration = (
  status: PointsQualificationStatus,
  nextExpiration?: string,
  lastExpiredAt?: string
): string | undefined => {
  const expirationDate =
    status === "disqualified" ? lastExpiredAt : nextExpiration;
  if (!expirationDate) {
    return undefined;
  }
  return formatDateWithTimezone(expirationDate);
};
