import React from "react";
import { useLocation } from "react-router-dom";

import { getUTMParams } from "../../utils/queryString";
import PromotionBanner from "../PromotionBanner";
import { ReactComponent as Icon } from "./fibroid.svg";

const utmParams = {
  utm_source: "fibroidfoundation",
  utm_campaign: "fibroidfoundation",
};
const paths = ["/", "/about", "/research"];

const FibroidBanner = () => {
  const location = useLocation();
  const searchParams = getUTMParams(location.search);
  const showBanner = Object.keys(utmParams).every(
    (key) => searchParams[key] === utmParams[key]
  );

  if (showBanner && paths.includes(location.pathname)) {
    return (
      <PromotionBanner
        color="#fff0f0"
        description="After signing up for Evidation, you’ll receive opportunities to participate in projects including ones between Evidation and the Fibroid Foundation, other research opportunities, and relevant programs related to your health. We'll provide you with more details if you choose to participate in these initiatives. "
        location={location}
        logo={<Icon />}
        partnerLogo={
          <img
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/images/partnerships/Logo_Fibroid.png`}
            srcSet={`${process.env.PUBLIC_URL}/assets/images/partnerships/Logo_Fibroid@2x.png 2x, ${process.env.PUBLIC_URL}/assets/images/partnerships/Logo_Fibroid@3x.png 3x}`}
            width="100px"
          />
        }
        title="Evidation Collaborates with Fibroid Foundation"
      />
    );
  }
  return null;
};

export default FibroidBanner;
