import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import FieldError from "../FieldError";
import { Input } from "../Input";
import Label from "../Label";
import { toNumericPhoneNumber, toPhoneNumber } from "./utils";

class PhoneNumber extends React.PureComponent {
  handleChange = (event) => {
    this.props.form.setFieldValue(
      this.props.field.name,
      toPhoneNumber(event.target.value)
    );
  };

  render() {
    const {
      field: { name, value, ...field },
      form: { errors = {}, touched = {} },
      label,
      ...props
    } = this.props;

    const hasError = touched[name] && !isEmpty(errors[name]);

    return (
      <React.Fragment>
        {hasError && <FieldError>{errors[name]}</FieldError>}
        <Input
          {...field}
          {...props}
          hasError={hasError}
          name={name}
          onChange={this.handleChange}
          value={value}
        />
        <Label hasError={hasError}>{label}</Label>
      </React.Fragment>
    );
  }
}

PhoneNumber.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
};

export const PHONE_NUMBER = /^\(\d{3}\)\s\d{3}-\d{4}$/;

export { toNumericPhoneNumber, toPhoneNumber };

export default PhoneNumber;
