import { distanceInWordsStrict } from "date-fns";
import PropTypes from "prop-types";
import React from "react";

import Card, { CardContent, CardFooter, CardMeta } from "../Card";
import CardActionBar from "../CardActionBar";
import CardActionResult from "../CardActionResult";
import CardMedia from "../CardMedia";
import Grid from "../Grid";
import { PointsBadgeContainer } from "../OfferCard/OfferCard";
import PointsBadge from "../PointsBadge";
import SkeletonContent from "../SkeletonContent";
import Space from "../Space";

/**
 * @render react
 * @name OfferCard
 * @description Achievement Offer Card
 * @example
 * <OfferCard />
 */

const OneClickOfferComponent = ({
  actions,
  loading,
  offer,
  dropdownMenu,
  result
}) => {
  if (loading) {
    return (
      <Card height="100%">
        <SkeletonContent.Block height={140} />
        <CardContent>
          <Grid.Flex>
            <Grid.Box flex={1}>
              <CardContent.Title>
                <SkeletonContent.Line />
              </CardContent.Title>
            </Grid.Box>
            <Grid.Box flex="0 0 auto" ml={4} />
          </Grid.Flex>
          <Space component="div" mr={5}>
            <SkeletonContent.Block height={72} />
            <Space mt={3} />
            <Space mt={5}>
              <Grid.Box mb={3} />
            </Space>
          </Space>
        </CardContent>
      </Card>
    );
  }
  return (
    <Card height="100%">
      {offer.image && (
        <CardMedia badge={offer.sponsored && "Sponsored"} image={offer.image} />
      )}
      <CardContent>
        {offer.compensation && (
          <PointsBadgeContainer>
            <PointsBadge>{offer.compensation}</PointsBadge>
          </PointsBadgeContainer>
        )}
        <Grid.Flex>
          <Grid.Box flex={1}>
            <CardContent.Title>{offer.title}</CardContent.Title>
          </Grid.Box>
          {dropdownMenu && (
            <Grid.Box flex="0 0 auto" ml={4}>
              {dropdownMenu}
            </Grid.Box>
          )}
        </Grid.Flex>
        <Space component="div" mr={5}>
          <CardContent.Body dangerouslySetInnerHTML={{ __html: offer.body }} />
          {offer.question && (
            <Space mt={3}>
              <CardContent.Body>
                <strong>{offer.question}</strong>
              </CardContent.Body>
            </Space>
          )}
          <Space mt={5}>
            <CardMeta>
              {offer.expiryDate &&
                `Expires in ${distanceInWordsStrict(
                  offer.expiryDate,
                  new Date()
                )}`}
            </CardMeta>
          </Space>
        </Space>
      </CardContent>
      {actions && (
        <Grid.Box flex="0 0 auto">
          <CardActionBar actions={actions} />
        </Grid.Box>
      )}
      {result && (
        <Grid.Box flex="0 0 auto">
          <CardFooter>
            <CardActionResult result={result} />
          </CardFooter>
        </Grid.Box>
      )}
    </Card>
  );
};

OneClickOfferComponent.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })
  ),
  offer: PropTypes.shape({
    body: PropTypes.string.isRequired,
    compensation: PropTypes.string,
    expiryDate: PropTypes.string,
    image: PropTypes.string,
    question: PropTypes.string,
    sponsored: PropTypes.bool,
    title: PropTypes.string.isRequired
  }),
  dropdownMenu: PropTypes.node,
  loading: PropTypes.bool,
  result: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  )
};

OneClickOfferComponent.defaultProps = {
  loading: false
};

export default OneClickOfferComponent;
