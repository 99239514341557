import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";

import { Colors } from "../constants";
import DismissIcon from "./DismissIcon";
import { tooltipStyles } from "./styles";
import { TooltipLayout, TooltipProps, TooltipStyles } from "./types";
import {
  getArrowStyles,
  getContainerPosition,
  getTooltipPosition,
} from "./utils";

const Tooltip: React.FunctionComponent<TooltipProps> = ({
  children,
  anchor = "center",
  arrowWidth = 12,
  dismissable = false,
  initialVisible = true,
  onVisibleChange,
  overlay = false,
  parentLayout,
  position = "top",
  styles,
  text,
  title,
  width = 100,
}) => {
  const [containerLayout, setContainerLayout] = React.useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [tooltipLayout, setTooltipLayout] = React.useState<TooltipLayout>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    pageX: 0,
    pageY: 0,
  });
  let tooltipRef = React.useRef<View>(null);
  const [visible, setVisible] = React.useState(initialVisible);

  const handlePress = () => {
    setVisible(!visible);
    onVisibleChange?.(!visible);
  };

  React.useEffect(() => {
    if (parentLayout) {
      setContainerLayout(parentLayout);
      setVisible(true);
      onVisibleChange?.(true);
    }
  }, [parentLayout, onVisibleChange]);

  const dynamicStyles = StyleSheet.create<TooltipStyles>({
    content: getTooltipPosition(
      anchor,
      position,
      containerLayout,
      tooltipLayout,
      arrowWidth,
      width
    ),
    container: getContainerPosition(position, anchor),
    arrow: getArrowStyles(anchor, position, containerLayout, arrowWidth),
    backgroundArrow: getArrowStyles(
      anchor,
      position,
      containerLayout,
      arrowWidth + 2,
      Colors.GRAY_ALPHA,
      2
    ),
  });
  const isHidden = !visible || tooltipLayout.width === 0;

  return (
    <React.Fragment>
      {overlay && visible && <View style={tooltipStyles.overlay} />}
      <View
        style={[
          tooltipStyles.container,
          { transform: [{ translateY: (-containerLayout?.width ?? 0) / 2 }] },
          dynamicStyles.container,
        ]}
      >
        <View
          style={[
            tooltipStyles.arrow,
            dynamicStyles.backgroundArrow,
            styles?.arrow,
            isHidden && tooltipStyles.invisible,
          ]}
        />
        <View
          style={[
            tooltipStyles.arrow,
            dynamicStyles.arrow,
            styles?.arrow,
            isHidden && tooltipStyles.invisible,
          ]}
        />
        <View
          onLayout={({ nativeEvent }) => {
            tooltipRef.current?.measureInWindow((pageX, pageY) => {
              setTooltipLayout({
                ...nativeEvent.layout,
                pageX,
                pageY,
              });
            });
          }}
          ref={tooltipRef}
          style={[
            tooltipStyles.content,
            dynamicStyles.content,
            styles?.content,
            isHidden && tooltipStyles.invisible,
          ]}
        >
          {dismissable && (
            <Pressable
              onPress={handlePress}
              style={[tooltipStyles.dismiss, styles?.dismiss]}
            >
              <DismissIcon />
            </Pressable>
          )}
          <Text style={[tooltipStyles.title, styles?.title]}>{title}</Text>
          <Text style={[tooltipStyles.text, styles?.text]}>{text}</Text>
        </View>
        {!parentLayout && (
          <Pressable
            onLayout={({ nativeEvent }) => {
              setContainerLayout(nativeEvent.layout);
            }}
            onPress={handlePress}
          >
            {children}
          </Pressable>
        )}
      </View>
    </React.Fragment>
  );
};

export default Tooltip;
