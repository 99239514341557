import { Dimensions, LayoutRectangle, ViewStyle } from "react-native";

import { Colors } from "../constants";
import { TooltipAnchor, TooltipLayout, TooltipPosition } from "./types";

/**
 * The amount of padding to add between a Tooltip and the edge of the screen
 *  if the Tooltip goes off screen and is re-positioned.
 */
const OFFSCREEN_PADDING = 16;

/**
 * Get the ViewStyle positioning for the tooltip View container.
 * @param anchor The tooltip's anchored position relative to the parent.
 * @param position The TooltipPosition (top, bottom)
 * @param parentLayout The layout of the parent container that the tooltip is surrounding.
 * @param tooltipLayout The measured layout of the tooltip itself.
 * @param arrowWidth The size of the tooltip arrow (default 12)
 * @returns A ViewStyle for the top and left values for the tooltip container.
 */
export const getTooltipPosition = (
  anchor: TooltipAnchor,
  position: TooltipPosition,
  parentLayout: LayoutRectangle,
  tooltipLayout: TooltipLayout,
  arrowWidth: number,
  contentWidth: number
): ViewStyle => {
  const { width: windowWidth } = Dimensions.get("window");
  let left, top;
  switch (position) {
    case "top":
      top = parentLayout.y - tooltipLayout.height - arrowWidth;
      break;
    case "bottom":
      top = parentLayout.y + parentLayout.height + arrowWidth;
      break;
  }
  left = getTooltipAnchorPosition(anchor, parentLayout, tooltipLayout);
  if (left + tooltipLayout.width > windowWidth) {
    left = -(tooltipLayout.width - windowWidth + OFFSCREEN_PADDING);
  }
  return { top, left, minWidth: contentWidth };
};

/**
 * Get the tooltip position anchored parallel to the parent.
 * @param anchor The position that the arrow is anchored to along the parent.
 * @param parentLayout The layout of the parent container that the tooltip is surrounding.
 * @param tooltipLayout The measured layout of the tooltip itself.
 * @returns The numerical position of the tooltip container positioned along the parent container.
 */
export const getTooltipAnchorPosition = (
  anchor: TooltipAnchor,
  parentLayout: LayoutRectangle,
  tooltipLayout: TooltipLayout
): number => {
  switch (anchor) {
    case "left":
      return parentLayout.x - 15;
    case "right":
      return parentLayout.x + parentLayout.width - tooltipLayout.width + 15;
    case "center":
    default:
      return parentLayout.x - (tooltipLayout.width - parentLayout.width) / 2;
  }
};

/**
 * Get the ViewStyle positioning for the tooltip's arrow View.
 * @param position The TooltipPosition (top, bottom)
 * @param parentLayout The layout of the parent container that the tooltip is surrounding.
 * @param arrowColor The color of the arrow.
 * @param arrowWidth  The size of the tooltip arrow (default 12).
 * @param offset An additional offset to be applied to the style.
 * @returns A ViewStyle for the top and left values for the tooltip arrow.
 */
export const getArrowStyles = (
  anchor: TooltipAnchor,
  position: TooltipPosition,
  parentLayout: LayoutRectangle,
  arrowWidth: number,
  arrowColor: string = Colors.WHITE,
  offset = 0
): ViewStyle => {
  const left = getArrowAnchorPosition(anchor, parentLayout, arrowWidth, offset);
  switch (position) {
    case "top":
      return {
        borderWidth: arrowWidth,
        borderTopColor: arrowColor,
        top: parentLayout.y - arrowWidth - 1 + offset,
        left,
      };
    case "bottom":
      return {
        borderWidth: arrowWidth,
        borderBottomColor: arrowColor,
        top: parentLayout.y + parentLayout.height - arrowWidth + 1 - offset,
        left,
      };
  }
};

/**
 * Get the numerical position of the arrow anchored to the parent layout.
 * @param anchor The position that the arrow is anchored to along the parent.
 * @param parentLayout The layout of the parent container that the tooltip is surrounding.
 * @param arrowWidth  The size of the tooltip arrow (default 12).
 * @param offset An additional offset to be applied to the style.
 * @returns The numerical value of the anchored arrow position.
 */
export const getArrowAnchorPosition = (
  anchor: TooltipAnchor,
  parentLayout: LayoutRectangle,
  arrowWidth: number,
  offset = 0
): number => {
  switch (anchor) {
    case "left":
      return parentLayout.x + parentLayout.width / 5 - offset;
    case "right":
      return (
        parentLayout.x +
        parentLayout.width -
        parentLayout.width / 5 -
        arrowWidth * 2 +
        offset
      );
    case "center":
    default:
      return parentLayout.x + parentLayout.width / 2 - arrowWidth;
  }
};

export const getContainerHorizontalPosition = (
  anchor: TooltipAnchor
): string => {
  switch (anchor) {
    case "left":
      return "10%";
    case "right":
      return "90%";
    case "center":
    default:
      return "50%";
  }
};

export const getContainerPosition = (
  position: TooltipPosition,
  anchor: TooltipAnchor
): ViewStyle => {
  switch (position) {
    case "top":
      return {
        top: 0,
        left: getContainerHorizontalPosition(anchor),
      };
    case "bottom":
      return {
        top: "100%",
        left: getContainerHorizontalPosition(anchor),
      };
  }
};
