import PropTypes from "prop-types";
import { display, fontSize, fontWeight, space, textAlign } from "styled-system";

import Typography from "./Typography";

const variant = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "p",
  "caption",
  "link",
  "subtitle",
  "title",
];

const TypographyComponent = (props) => {
  return <Typography {...props} />;
};

TypographyComponent.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  color: PropTypes.string,
  truncate: PropTypes.bool,
  variant: PropTypes.oneOf(variant),
  ...display.propTypes,
  ...fontSize.propTypes,
  ...fontWeight.propTypes,
  ...space.propTypes,
  ...textAlign.propTypes,
};

Typography.defaultProps = {
  as: "p",
  truncate: false,
};

export { Typography };

export default TypographyComponent;
