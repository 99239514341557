import { get } from "lodash";
import { rem } from "polished";
import styled from "styled-components";

import { Breakpoint, Menu, getBreakpoint } from "../ReactComponents/Components";

const AccountMenuList = styled(Menu.List)`
  &[data-reach-menu-list] {
    padding: ${rem(16)};
    position: relative;
    z-index: 101;

    @media screen and (max-width: ${getBreakpoint(Breakpoint.SMALL)}) {
      border-left-width: 0;
      border-right-width: 0;
      border-top-color: ${(props) => get(props, "theme.palette.base.lighter")};
      box-shadow: none;
      width: 100%;
    }

    @media screen and (min-width: ${getBreakpoint(Breakpoint.SMALL)}) {
      padding: ${rem(24)};
      width: ${rem(384)};
    }
  }
`;

export default AccountMenuList;
