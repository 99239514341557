import { rem } from "polished";
import styled from "styled-components";

import Align from "./Align";
import Direction from "./Direction";

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || Direction.ROW};
  justify-content: ${(props) => props.align || Align.LEFT};

  > :not(:last-child) {
    margin-right: ${rem(8)};
  }
`;

export default ButtonGroup;
