import React from "react";

import Toggle from "./Toggle";

const ToggleComponent = ({ children, field, form, id, ...props }) => (
  <Toggle>
    <Toggle.Input
      {...field}
      {...props}
      checked={field.value}
      id={id}
      type="checkbox"
    />
    <Toggle.Label htmlFor={id}>{field.value ? "ON" : "OFF"}</Toggle.Label>
  </Toggle>
);

export default ToggleComponent;
