import { get } from "lodash";

import { mapEntitiesByKey, transformEntities } from "../modules/utils";

const eventService = {
  transformOne: (event) => ({
    application: get(event, "application.name"),
    createdAt: event.timestamp,
    category: event.activity_category,
    description: event.short_description,
    icon: get(event, "application.icon_url", event.icon),
    id: event.id,
    offer: event.offer_identifier,
    reward: event.points || 0,
    updatedAt: event.updated_at,
  }),

  transformMany: (events) => {
    const transformedEvents = transformEntities(
      events,
      eventService.transformOne
    );

    return mapEntitiesByKey(transformedEvents);
  },
};

export default eventService;
