import { getAccessToken } from "@achievement/portal-client";

export const getAuthConfig = () => ({
  authenticationFlowType: "USER_PASSWORD_AUTH",
  oauth: {
    domain: window.env.REACT_APP_COGNITO_DOMAIN,
    scope: [
      "phone",
      "email",
      "profile",
      "openid",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: `${window.env.REACT_APP_WEB_URL}/onboarding`,
    redirectSignOut: `${window.env.REACT_APP_WEB_URL}/logout`,
    responseType: "code",
  },
  region: window.env.REACT_APP_COGNITO_REGION,
  userPoolId: window.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: window.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
});

export { getAccessToken };
