import React from "react";
import { Animated, Text, View } from "react-native";

import { notificationStyles } from "./styles";
import { NotificationProps } from "./types";

const Notification: React.FunctionComponent<NotificationProps> = ({
  animationDuration = 500,
  duration = 5000,
  icon,
  children,
}) => {
  const opacity = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    Animated.stagger(duration, [
      Animated.timing(opacity, {
        duration: animationDuration,
        toValue: 1,
        useNativeDriver: true,
      }),
      Animated.timing(opacity, {
        duration: animationDuration,
        toValue: 0,
        useNativeDriver: true,
      }),
    ]).start();
  }, [animationDuration, duration, opacity]);

  return (
    <Animated.View style={{ opacity }}>
      <View style={notificationStyles.content}>
        {icon && <View style={notificationStyles.icon}>{icon}</View>}
        <Text style={notificationStyles.text}>{children}</Text>
      </View>
    </Animated.View>
  );
};

export default Notification;
