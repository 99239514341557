import { AuthState, useAuthState } from "@achievement/portal-client";

import AuthenticatedHeaderMenu from "./AuthenticatedHeaderMenu";
import UnauthenticatedHeaderMenu from "./UnauthenticatedHeaderMenu";

const HeaderMenu = () => {
  const { authState } = useAuthState();

  return authState === AuthState.AUTHENTICATED ? (
    <AuthenticatedHeaderMenu />
  ) : (
    <UnauthenticatedHeaderMenu />
  );
};

export default HeaderMenu;
