import { get } from "lodash";
import { rem } from "polished";
import styled from "styled-components";

const FieldError = styled.div`
  color: ${(props) => get(props, "theme.color.error")};
  font-size: ${rem(12)};
`;

export default FieldError;
