import { rem } from "polished";
import styled from "styled-components";

export const CardActionResult = styled.div`
  align-items: center;
  display: flex;
  text-align: center;
`;

CardActionResult.Statistic = styled.div`
  flex: 1;
  font-size: ${rem(14)};
  text-transform: uppercase;
`;

CardActionResult.Chart = styled.div`
  svg {
    max-height: ${rem(56)};
    max-width: ${rem(56)};
  }
`;

export default CardActionResult;
