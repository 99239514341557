import isEmpty from "lodash/isEmpty";
import React from "react";

import FieldError from "../FieldError";
import Label from "../Label";
import Select from "./Select";

/**
 * @render react
 * @name Select
 * @description Achievement Select Component
 * @example
 * <Select
      field={{
        name: 'fieldName',
        value: 'cyan',
      }}
      form={{
        touched: {
          fieldName: true,
        },
        errors: {},
      }}
      label="Color"
      name="fieldName"
   >
     <option />
     <option value="cyan">Cyan</option>
     <option value="magenta">Magenta</option>
     <option value="yellow">Yellow</option>
     <option value="black">Black</option>
   </Select>
 */

const SelectComponent = ({
  children,
  field,
  form: { errors, touched },
  label,
  placeholder,
  ...props
}) => {
  const hasError = touched[field.name] && !isEmpty(errors[field.name]);

  return (
    <React.Fragment>
      {hasError && <FieldError>{errors[field.name]}</FieldError>}
      <Select
        {...field}
        {...props}
        hasError={hasError}
        placeholder={placeholder}
      >
        {children}
      </Select>
      <Label hasError={hasError} htmlFor={field.name}>
        {label}
      </Label>
    </React.Fragment>
  );
};

export { Select };

export default SelectComponent;
