import Notification from "./Notification";

/**
 * @render react
 * @name Notification
 * @description Achievement Notification Component
 * @example
 * <Notification>
 *   Lorem ipsum dolar sit amet!
 * </Notification>
 */

export { default as Status } from "./Status";

export default Notification;
