import { useQuery } from "react-query";

import dataService from "../../services/dataService";

export const useProgram = (id) => {
  return useQuery(["program", id], () => dataService.getProgram(id), {
    refetchOnMount: false,
    retry: false,
    staleTime: 5 * 60 * 1000,
  });
};

export default useProgram;
