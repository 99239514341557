import { rem } from "polished";
import styled from "styled-components";

import { Select } from "../Select";

const DateSelect = styled.div`
  display: flex;
  flex-direction: row;
`;

DateSelect.Select = styled(Select)`
  &:not(:first-child) {
    padding-left: ${rem(24)};
  }

  &:nth-child(1) {
    flex: 5;
  }

  &:nth-child(2) {
    flex: 2;
  }

  &:nth-child(3) {
    flex: 3;
  }
`;

export default DateSelect;
