import { get } from "lodash";
import styled from "styled-components";
import { space } from "styled-system";

const Panel = styled.div`
  background-color: ${(props) =>
    get(props, "theme.panel.backgroundColor", "red")};
  color: ${(props) => get(props, "theme.panel.color", "red")};
  width: 100%;
  ${space};

  > :last-child {
    margin-bottom: 0;
  }
`;

export default Panel;
