import { StyleSheet } from "react-native";

import { CardVariantStyles, CardStyles } from "./types";

const cardStyles = StyleSheet.create<CardStyles>({
  card: {
    backgroundColor: "#FFFFFF",
    borderRadius: 30,
    overflow: "hidden",
    shadowColor: "#000000",
    shadowOffset: {
      height: 3,
      width: 0,
    },
    shadowOpacity: 0.0487359,
    shadowRadius: 3,
  },
});

const cardVariantStyles: CardVariantStyles = {
  rounded: {
    card: {
      borderRadius: 30,
    },
  },
  squared: {
    card: {
      borderRadius: 4,
    },
  },
};

export { cardStyles, cardVariantStyles };

export default cardStyles;
