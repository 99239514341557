import { Platform, StyleSheet } from "react-native";

import { Colors, ZIndex } from "../constants";
import { TooltipStyles } from "./types";

const tooltipStyles = StyleSheet.create<TooltipStyles>({
  arrow: {
    borderColor: "transparent",
    borderStyle: "solid",
    elevation: ZIndex.TOOLTIP_ARROW,
    height: 0,
    position: "absolute",
    width: 0,
    zIndex: ZIndex.TOOLTIP_ARROW,
  },
  backgroundArrow: {
    elevation: ZIndex.TOOLTIP_ARROW_BACKGROUND,
    zIndex: ZIndex.TOOLTIP_ARROW_BACKGROUND,
  },
  dismiss: {
    position: "absolute",
    height: 24,
    right: 5,
    top: 5,
    width: 24,
    ...Platform.select({
      web: {
        cursor: "pointer",
      },
    }),
  },
  container: {
    position: "absolute",
    top: "100%",
    left: "50%",
  },
  content: {
    backgroundColor: Colors.WHITE,
    borderColor: Colors.GRAY_ALPHA,
    borderRadius: 8,
    borderWidth: 1,
    position: "absolute",
    elevation: ZIndex.TOOLTIP_CONTAINER,
    padding: 14,
    maxWidth: 300,
    shadowColor: Colors.BLACK,
    shadowOffset: {
      height: 3,
      width: 0,
    },
    shadowOpacity: 0.05,
    shadowRadius: 6,
    zIndex: ZIndex.TOOLTIP_CONTAINER,
  },
  //@ts-ignore https://necolas.github.io/react-native-web/docs/platform/
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#000000",
    opacity: 0.15,
    ...Platform.select({
      web: {
        position: "fixed",
      },
      native: {
        position: "absolute",
      },
    }),
  },
  invisible: {
    elevation: ZIndex.TOOLTIP_HIDDEN,
    opacity: 0,
    zIndex: ZIndex.TOOLTIP_HIDDEN,
  },
  text: {
    color: Colors.GRAY_DARK,
    fontFamily: "PublicSans-Regular",
    fontSize: 16,
    lineHeight: 24,
    textAlign: "center",
  },
  title: {
    color: Colors.BLUE_DARK,
    fontFamily: "PublicSans-Regular",
    fontSize: 16,
    fontWeight: "700",
    lineHeight: 24,
    textAlign: "center",
  },
});

export { tooltipStyles };

export default tooltipStyles;
