import PropTypes from "prop-types";
import React from "react";

import RadialProgressBar from "./RadialProgressBar";

const RadialProgressBarComponent = ({ color, size, strokeWidth, value }) => (
  <RadialProgressBar>
    <RadialProgressBar.Circle height={size} viewBox="0 0 32 32" width={size}>
      <RadialProgressBar.Progress
        cx="16"
        cy="16"
        fill="transparent"
        r={16 - strokeWidth / 2}
        stroke="#e3e3e3"
        strokeWidth={strokeWidth}
      />
      <RadialProgressBar.Progress
        cx="16"
        cy="16"
        fill="transparent"
        r={16 - strokeWidth / 2}
        stroke={color}
        strokeDasharray={`${value} 100`}
        strokeWidth={strokeWidth}
      />
    </RadialProgressBar.Circle>
  </RadialProgressBar>
);

RadialProgressBarComponent.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  strokeWidth: PropTypes.number,
  value: PropTypes.number,
};

RadialProgressBarComponent.defaultProps = {
  color: "#37d596",
  size: "100%",
  strokeWidth: 3,
  value: 0,
};

export { RadialProgressBar };

export default RadialProgressBarComponent;
