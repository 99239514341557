import { get } from "lodash";
import { rem } from "polished";
import styled from "styled-components";

import { MenuList } from "./menu-button/src";

const StyledMenuList = styled(MenuList)`
  &[data-reach-menu-list] {
    background-color: ${(props) => get(props, "theme.palette.base.lightest")};
    border: 1px solid ${(props) => get(props, "theme.palette.base.light")};
    box-shadow: 0 12px 17px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    font-family: PublicSans-Regular, Helvetica, Arial, Roboto, sans-serif;
    font-size: ${rem(12)};
    padding: ${rem(8)} ${rem(32)};
    width: ${rem(224)};
  }

  hr {
    background-color: ${(props) => get(props, "theme.palette.base.light")};
    border: 0;
    height: 1px;
    margin-bottom: ${rem(8)};
    margin-top: ${rem(8)};
  }

  &:focus {
    outline: 0;
  }

  a[data-reach-menu-item],
  button[data-reach-menu-item] {
    border-color: red;
    align-items: center;
    color: ${(props) => get(props, "theme.palette.base.medium")};
    cursor: pointer;
    display: flex;
    font-family: PublicSans-SemiBold;
    line-height: 1.5;
    margin-left: ${rem(-32)};
    margin-right: ${rem(-32)};
    padding: ${rem(14)} ${rem(32)};
    text-decoration: none;
    text-transform: uppercase;
    width: calc(100% + ${rem(64)});

    svg {
      fill: ${(props) => get(props, "theme.palette.base.medium")};
      height: ${rem(20)};
      margin-right: ${rem(14)};
      width: ${rem(20)};
    }

    &:focus,
    &:hover {
      background-color: ${(props) => get(props, "theme.palette.base.lighter")};
    }

    &:focus {
      outline: 0;
    }

    &:not(:first-of-type) {
      border: 1px solid red;
      margin-top: ${rem(-8)};
    }
  }
`;

export default StyledMenuList;
