import PropTypes from "prop-types";

import Card from "./Card";

/**
 * @render react
 * @name Card
 * @description Achievement Card Component
 * @example
 * <Card>
 *   <h4>Lorem ipsum</h4>
 *   <p>Lorem ipsum dolar sit amet</p>
 * </Card>
 */

const CardComponent = ({ children, component, height, width }) => (
  <Card as={component} height={height} width={width}>
    {children}
  </Card>
);

CardComponent.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.oneOf(["article", "div"]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.number,
};

CardComponent.defaultProps = {
  component: "div",
  height: "auto",
  width: 375,
};

export { default as CardContent } from "./CardContent";
export { default as CardFooter } from "./CardFooter";
export { default as CardMeta } from "./CardMeta";
export { default as CardErrorState } from "./CardErrorState";

export default CardComponent;
