import React from "react";
import { Pressable, Text } from "react-native";

import {
  buttonSizeStyles,
  buttonStyles,
  buttonVariantStyles,
  disabledButtonStyle,
} from "./styles";
import { ButtonProps } from "./types";

const Button: React.FunctionComponent<ButtonProps> = ({
  children,
  disabled = false,
  onPress,
  size = "m",
  styles,
  variant = "primary",
  uppercase,
}) => {
  return (
    <Pressable
      disabled={disabled}
      onPress={onPress}
      style={[
        buttonStyles.container,
        buttonSizeStyles[size]?.container,
        buttonVariantStyles[variant]?.container,
        styles?.container,
        disabled && disabledButtonStyle.container,
      ]}
    >
      <Text
        style={[
          buttonStyles.text,
          buttonSizeStyles[size]?.text,
          buttonVariantStyles[variant]?.text,
          uppercase && buttonStyles.uppercase,
          styles?.text,
          disabled && disabledButtonStyle.text,
        ]}
      >
        {children}
      </Text>
    </Pressable>
  );
};

export default Button;
